import React, { useState } from "react";
import { uuid } from "utils/uuid";
import { MultiSelect } from "primereact/multiselect";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import Button from "components/Button";
import moment from "moment";
import FeatherIcon from "feather-icons-react";
import { useDispatch } from "react-redux";
import { actions as playersAction } from "../../redux/Player/action";
import { useSelector } from "react-redux";

function Filter({
  selectedStatus,
  setSelectedStatus,
  open,
  setOpen,
  status,
  clubs,
  positions,
  setPlayers,
  setSelectedClubs,
  selectedClubs,
  handleChangePosition,
  selectedPositions,
  selectedFilter,
  setSelectedFilter,
  setOperation,
  operation,
  dynamicFilterUI,
  setDynamicFilterUI,
  setDate,
  date,
}) {
  const dispatch = useDispatch();
  // const { brandTheme } = useSelector((state) => state.brand);
  const { user } = useSelector((state) => state.user);

  const brandTheme = JSON.parse(localStorage.getItem("brandTheme"));

  // const [dynamicFilterUI, setDynamicFilterUI] = useState([
  //   "aa8bae6a-b686-7be4-7558-65fe9174d006",
  // ]);

  const [openDateRangePicker, setOpenDateRangePicker] = useState({
    id: 0,
    open: false,
  });

  // const [date, setDate] = useState([
  //   {
  //     startDate: new Date(),
  //     endDate: new Date(),
  //     key: "selection",
  //   },
  // ]);

  // const [date, setDate] = useState([
  //   {
  //     id: "aa8bae6a-b686-7be4-7558-65fe9174d006",
  //     date: [
  //       {
  //         startDate: new Date(),
  //         endDate: new Date(),
  //         key: "selection",
  //       },
  //     ],
  //   },
  // ]);

  // eslint-disable-next-line
  const [radioOperationChecked, setRadioOperationChecked] = useState({
    name: "",
    checked: false,
  });

  let statusFilter = {
    status: [],
  };

  const setFilterData = (arr, id, key, value) => {
    let statusFilter = arr?.map((data) => {
      if (data?.id === id) {
        data[key] = value;
        return data;
      }

      return data;
    });

    return statusFilter;
  };

  const statusFilterElement = (i, index) => {
    // statusFilter = selectedStatus?.find((statusData) => {
    //   return statusData?.id === i;
    // });
    statusFilter = selectedFilter?.find((statusData) => {
      return statusData?.id === i;
    });
    const dateRanges = date?.find((data) => {
      return data.id === i;
    });

    return (
      <div key={i} className="filterElementUIDiv">
        <div
          key={i}
          className={`d-flex align-items-center statusFilterUI my-2`}
        >
          {/* {!openDateRangePicker?.open && (
          <div className="btn-group radioChecked me-2">
            <input
              type="radio"
              className="btn-check"
              name="optionAndOR"
              id="optionAndOR1"
              autoComplete="off"
              value={radioOperationChecked.checked}
              // onChange={
              //   (e) => {
              //     //   let statusFilter = selectedFilter?.map((statusData) => {
              //     //     if (statusData?.id === i) {
              //     //       statusData.operation = "and";
              //     //       return statusData;
              //     //     }

              //     //     return statusData;
              //     //   });
              //     let statusFilter = setFilterData(
              //       selectedFilter,
              //       i,
              //       "operation",
              //       "and"
              //     );
              //     setSelectedFilter([...statusFilter]);
              //   }
              //   // setRadioOperationChecked({ checked: e.target.value })
              // }
              // checked={statusFilter?.operation === "and" ? true : false}
            />
            <label
              className="btn btn-outline-secondary py-1"
              htmlFor="optionAndOR1"
            >
              And
            </label>

            <input
              type="radio"
              className="btn-check"
              name="optionAndOR"
              id="optionAndOR2"
              autoComplete="off"
              value={radioOperationChecked.checked}
              // onChange={
              //   (e) => {
              //     //   let statusFilter = selectedFilter?.map((statusData) => {
              //     //     if (statusData?.id === i) {
              //     //       statusData.operation = "or";
              //     //       return statusData;
              //     //     }

              //     //     return statusData;
              //     //   });
              //     let statusFilter = setFilterData(
              //       selectedFilter,
              //       i,
              //       "operation",
              //       "or"
              //     );
              //     setSelectedFilter([...statusFilter]);
              //   }
              //   // setRadioOperationChecked({ checked: e.target.value })
              // }
              // checked={statusFilter?.operation === "or" ? true : false}
            />
            <label
              className="btn btn-outline-secondary py-1"
              htmlFor="optionAndOR2"
            >
              Or
            </label>
          </div>
        )} */}

          <div className={`d-flex align-items-center p-2 statusDiv`}>
            {/* {!openDateRangePicker?.open && ( */}
            <div className="multiselectStatus">
              {/* <p className="mb-2">Select Status</p> */}
              <MultiSelect
                value={statusFilter?.status}
                options={status}
                // onChange={(e) => {
                //   let statusFilter = selectedStatus?.map((statusData) => {
                //     if (statusData?.id === i) {
                //       statusData.status = e.target.value;
                //       return statusData;
                //     }

                //     return statusData;
                //   });
                //   setSelectedStatus([...statusFilter]);
                //   // setPlayers([]);
                // }}
                onChange={(e) => {
                  //   let statusFilter = selectedFilter?.map((statusData) => {
                  //     if (statusData?.id === i) {
                  //       statusData.status = e.target.value;
                  //       return statusData;
                  //     }

                  //     return statusData;
                  //   });
                  let statusFilter = setFilterData(
                    selectedFilter,
                    i,
                    "status",
                    e.target.value
                  );
                  setSelectedFilter([...statusFilter]);
                  // setPlayers([]);
                }}
                optionLabel="name"
                placeholder="Select Status"
                // filter
                className="multiselect-custom"
                maxSelectedLabels={0}
                selectedItemsLabel={"{0} items selected"}
              />
            </div>
            {/* )} */}

            <div>
              {/* {!openDateRangePicker?.open && ( */}
              <div className="dropdownFilter dropdownDateRangerPicker pb-0 poppins">
                <div
                  className="d-flex align-items-center filterDiv p-1 px-3 shadow-sm ms-2 bg-white"
                  onClick={() =>
                    setOpenDateRangePicker({
                      ...openDateRangePicker,
                      id: i,
                      open: true,
                    })
                  }
                >
                  <p className="text-secondary filterText me-2">
                    {/* {date[0].selected
                      ? moment(date[0].startDate).format("DD.MM.YYYY") +
                        " - " +
                        moment(date[0].endDate).format("DD.MM.YYYY")
                      : "Select Date"} */}
                    {statusFilter?.startDate
                      ? moment(statusFilter?.startDate).format("DD.MM.YYYY") +
                        " - " +
                        moment(statusFilter?.endDate).format("DD.MM.YYYY")
                      : "Select Date"}
                  </p>
                  <div>
                    <FeatherIcon
                      icon="calendar"
                      size={16}
                      className="text-secondary d-flex"
                      // onClick={handleClick}
                    />
                  </div>
                </div>
                {/* // )} */}
                {openDateRangePicker.id === i && (
                  <div className="dropdown-content poppins">
                    <div className="dateRangePicker">
                      <DateRange
                        showMonthAndYearPickers={true}
                        editableDateInputs={true}
                        //   onChange={(item) => {
                        //     setDate([{ ...item.selection }]);
                        //     let statusFilter = selectedStatus?.map((statusData) => {
                        //       if (statusData?.id === i) {
                        //         statusData.startDate = item.selection.startDate;
                        //         statusData.endDate = item.selection.endDate;
                        //         return statusData;
                        //       }

                        //       return statusData;
                        //     });
                        //     setSelectedStatus([...statusFilter]);
                        //   }}
                        onChange={(item) => {
                          let dateFilter = date?.map((statusData) => {
                            if (statusData?.id === i) {
                              statusData.date[0].startDate =
                                item.selection.startDate;
                              statusData.date[0].endDate =
                                item.selection.endDate;
                              return statusData;
                            }

                            return statusData;
                          });
                          setDate([...dateFilter]);
                          // setDate([{ ...item.selection }]);
                          let statusFilter = selectedFilter?.map(
                            (statusData) => {
                              if (statusData?.id === i) {
                                statusData.startDate = item.selection.startDate;
                                statusData.endDate = item.selection.endDate;
                                return statusData;
                              }

                              return statusData;
                            }
                          );
                          setSelectedFilter([...statusFilter]);
                        }}
                        moveRangeOnFirstSelection={false}
                        ranges={dateRanges?.date}
                        rangeColors={["#006b00"]}
                      />

                      <Button
                        label="Apply"
                        className="btn greenBg d-block mt-3 ms-auto"
                        handleClick={() => {
                          setOpenDateRangePicker(false);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* {!openDateRangePicker?.open && ( */}
          <div className="statusDiv p-1 ms-lg-2 ms-0 d-flex align-items-center">
            <div
              className="py-1 ms-1 multiselectStatus"
              id="multiSelectedPositionsDiv"
            >
              {positions && (
                <MultiSelect
                  value={statusFilter?.positions}
                  options={positions}
                  onChange={(e) => {
                    // let statusFilter = selectedFilter?.map((statusData) => {
                    //   if (statusData?.id === i) {
                    //     statusData.positions = e.target.value;
                    //     return statusData;
                    //   }

                    //   return statusData;
                    // });
                    let statusFilter = setFilterData(
                      selectedFilter,
                      i,
                      "positions",
                      e.target.value
                    );
                    setSelectedFilter([...statusFilter]);
                    // setPlayers([]);
                  }}
                  optionLabel="name"
                  placeholder="Select Position"
                  filter
                  className="multiselect-custom "
                  maxSelectedLabels={0}
                  selectedItemsLabel={"{0} items selected"}
                />
              )}
            </div>
            {
              <div
                className="p-1 ms-1 multiselectStatus"
                id="multiSelectedClubDiv"
              >
                {clubs && (
                  <MultiSelect
                    value={statusFilter?.clubs}
                    options={clubs}
                    onChange={(e) => {
                      //   let statusFilter = selectedFilter?.map((statusData) => {
                      //     if (statusData?.id === i) {
                      //       statusData.clubs = e.target.value;
                      //       return statusData;
                      //     }

                      //     return statusData;
                      //   });
                      let statusFilter = setFilterData(
                        selectedFilter,
                        i,
                        "clubs",
                        e.target.value
                      );
                      setSelectedFilter([...statusFilter]);
                      //   setPlayers([]);
                    }}
                    optionLabel="name"
                    placeholder="Select Club"
                    filter
                    className="multiselect-custom"
                    maxSelectedLabels={0}
                    selectedIt1emsLabel={"{0} items selected"}
                  />
                )}
              </div>
            }
          </div>
          {/* )} */}

          {/* {!openDateRangePicker?.open && ( */}
          <div className="ms-2 deleteFilterElement mt-lg-0 mt-1">
            <FeatherIcon
              icon="trash-2"
              size={18}
              className={`text-secondary ${
                dynamicFilterUI.length <= 1 && index === 0 ? "disabled" : ""
              }`}
              //   onClick={() => {
              //     const index = dynamicFilterUI?.indexOf(i);
              //     if (index > -1) {
              //       dynamicFilterUI?.splice(index, 1);
              //     }

              //     setDynamicFilterUI([...dynamicFilterUI]);
              //     let statusFilter = selectedStatus?.filter((statusData) => {
              //       return statusData?.id !== i;
              //     });
              //     setSelectedStatus([...statusFilter]);
              //   }}
              onClick={() => {
                if (dynamicFilterUI.length > 1) {
                  const index = dynamicFilterUI?.indexOf(i);
                  if (index > -1) {
                    dynamicFilterUI?.splice(index, 1);
                  }

                  setDynamicFilterUI([...dynamicFilterUI]);
                  let statusFilter = selectedFilter?.filter((statusData) => {
                    return statusData?.id !== i;
                  });
                  setSelectedFilter([...statusFilter]);

                  let dateRanges = date?.filter((data) => {
                    return data?.id !== i;
                  });
                  setDate([...dateRanges]);
                }
              }}
            />
          </div>
          {/* )} */}
        </div>
        <div className="removeFilterElement d-none">
          <FeatherIcon
            icon="x"
            size={18}
            className={`text-secondary ${
              dynamicFilterUI.length <= 1 && index === 0 ? "disabled" : ""
            }`}
            onClick={() => {
              if (dynamicFilterUI.length > 1) {
                const index = dynamicFilterUI?.indexOf(i);
                if (index > -1) {
                  dynamicFilterUI?.splice(index, 1);
                }

                setDynamicFilterUI([...dynamicFilterUI]);
                let statusFilter = selectedFilter?.filter((statusData) => {
                  return statusData?.id !== i;
                });
                setSelectedFilter([...statusFilter]);

                let dateRanges = date?.filter((data) => {
                  return data?.id !== i;
                });
                setDate([...dateRanges]);
              }
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      <div>
        <p
          className="text-secondary mb-2 font-light poppins"
          style={{ fontSize: "17px" }}
        >
          Filter by Players Status, Position, and Club wise
        </p>
      </div>

      {/* {!openDateRangePicker?.open && ( */}
      <div className="btn-group radioChecked me-2">
        <input
          type="radio"
          className="btn-check"
          name="optionAndOR"
          id="optionAndOR1"
          autoComplete="off"
          value={radioOperationChecked.checked}
          onChange={(e) => {
            setOperation("and");
          }}
          checked={operation === "and" ? true : false}
        />
        <label
          className="btn btn-outline-secondary py-1"
          htmlFor="optionAndOR1"
        >
          And
        </label>

        <input
          type="radio"
          className="btn-check"
          name="optionAndOR"
          id="optionAndOR2"
          autoComplete="off"
          value={radioOperationChecked.checked}
          onChange={
            (e) => {
              setOperation("or");
            }
            // setRadioOperationChecked({ checked: e.target.value })
          }
          checked={operation === "or" ? true : false}
        />
        <label
          className="btn btn-outline-secondary py-1"
          htmlFor="optionAndOR2"
        >
          Or
        </label>
      </div>
      {/* )} */}

      {dynamicFilterUI.length > 0 &&
        dynamicFilterUI?.map((i, index) => {
          return statusFilterElement(i, index);
        })}

      {/* {!openDateRangePicker?.open && ( */}
      <div className="d-flex justify-content-between mt-4">
        <Button
          label="+ Add Group"
          className="btn btn-outline-secondary btn-sm mt-2 ms-2"
          handleClick={() => {
            let id = uuid();
            setDynamicFilterUI([...dynamicFilterUI, id]);

            setSelectedFilter([
              ...selectedFilter,
              {
                // id: dynamicFilterUI.length + 1,
                id: id,
                status: [],
                startDate: null,
                endDate: null,
                clubs: [],
                positions: [],
              },
            ]);
            setDate([
              ...date,
              {
                id: id,
                date: [
                  {
                    startDate: new Date(),
                    endDate: new Date(),
                    key: "selection",
                  },
                ],
              },
            ]);
          }}
        />
        <div>
          <Button
            label="Cancel"
            className="btn btn-outline-secondary btn-sm mt-2 ms-2"
            handleClick={() => {
              setOpen(!open);
              // eslint-disable-next-line
              const data = selectedFilter.filter((data) => {
                if (
                  data?.status?.length > 0 ||
                  data?.clubs.length > 0 ||
                  data?.positions?.length > 0
                ) {
                  return data;
                }
              });
              setSelectedFilter([
                {
                  id: "aa8bae6a-b686-7be4-7558-65fe9174d006",
                  status: [],
                  startDate: null,
                  endDate: null,
                  clubs: [],
                  positions: [],
                },
              ]);
              setDynamicFilterUI(["aa8bae6a-b686-7be4-7558-65fe9174d006"]);
              setDate([
                {
                  id: "aa8bae6a-b686-7be4-7558-65fe9174d006",
                  date: [
                    {
                      startDate: new Date(),
                      endDate: new Date(),
                      key: "selection",
                    },
                  ],
                },
              ]);
              if (data.length > 0) {
                dispatch(
                  playersAction.getAllPlayers({
                    keyword: "",
                    skip: 0,
                    body: {
                      brandId:
                        user?.designation === "Routes Super Admin" ||
                        user?.role === "clubAdmin"
                          ? null
                          : brandTheme?.id,
                    },
                  })
                );
                setPlayers([]);
              }
            }}
          />
          <Button
            label="Apply"
            className="btn greenBg btn-sm mt-2 ms-2"
            handleClick={() => {
              setOpen(!open);
              // eslint-disable-next-line
              const data = selectedFilter.filter((data) => {
                if (
                  data?.status?.length > 0 ||
                  data?.clubs.length > 0 ||
                  data?.positions?.length > 0
                ) {
                  return data;
                }
              });
              if (data.length > 0) {
                setPlayers([]);
                dispatch(
                  playersAction.getAllPlayers({
                    keyword: "",
                    skip: 0,
                    body: {
                      brandId:
                        user?.designation === "Routes Super Admin" ||
                        user?.role === "clubAdmin"
                          ? null
                          : brandTheme?.id,
                      filter: {
                        filterData: selectedFilter,
                        operation: operation,
                      },
                    },
                  })
                );
              }
            }}
          />
        </div>
      </div>
      {/* )} */}
    </div>
  );
}

export default Filter;
