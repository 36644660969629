import { all, fork, put, takeLatest } from "redux-saga/effects";
import { getQuery, postQuery, putQuery, deleteQuery } from "utils/api";
import { Toast } from "utils/toast";
import { actions } from "./action";
// import loaderAction from "redux/Loader/action";
import { actions as loaderAction } from "redux/Loader/action";

export function* createPlayers() {
  yield takeLatest(
    actions.CREATE_PLAYERS_REQUEST,
    function* ({ data, userType, navigate }) {
      try {
        const response = yield postQuery(`registrationViaControlCentral`, data);
        if (!response.data.error) {
          yield put({
            type: actions.CREATE_PLAYERS_SUCCESS,
            payload: response.data.result,
          });
          // if (response.data.result.existUsers.length > 0) {
          //   let userExists = "";
          //   for (const item of response.data.result.existUsers) {
          //     userExists += item.user + "\n";
          //   }
          //   // Toast("error", "Below Players are already exist! \n" + userExists);
          // }
          // if (response.data.result.errors.length > 0) {
          //   let userUndefined = "";
          //   for (const item of response.data.result.errors) {
          //     userUndefined += item.error + " in this " + item?.user + ".\n";
          //   }
          //   // Toast(
          //   //   "success",
          //   //   userUndefined + "\nOther Players Submitted Successfully!"
          //   // );
          // }
          yield put({
            type: loaderAction.CLOSE_LOADER,
          });
          if (
            response.data.result.errors.length <= 0 &&
            response.data.result.existUsers.length <= 0
          ) {
            Toast("success", `${userType} details submitted successfully`);
          }
        }
      } catch (e) {
        console.log(e);
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
      }
    }
  );
}

export function* getPlayers() {
  yield takeLatest(actions.GET_PLAYERS_REQUEST, function* ({ data }) {
    try {
      const response = yield getQuery(
        `users/controlCenter?clubId=${data?.club?.id}`
      );
      if (!response.data.error) {
        yield put({
          type: actions.GET_PLAYERS_SUCCESS,
          payload: response.data.result,
        });
      }
    } catch (e) {
      console.log(e);
    }
  });
}

export function* updatePlayer() {
  yield takeLatest(
    actions.UPDATE_PLAYER_REQUEST,
    function* ({ data, userType }) {
      try {
        const response = yield putQuery(`updateControlCenterUser`, data);
        if (!response.data.error) {
          yield put({
            type: actions.UPDATE_PLAYER_SUCCESS,
            payload: response.data.result,
          });

          if (data?.isArchived === true) {
            yield put({
              type: actions.REMOVE_PLAYER_SUCCESS,
              payload: response?.data?.result,
            });
          }

          if (data?.isArchived === false) {
            yield put({
              type: actions.UNREMOVE_PLAYER_SUCCESS,
              payload: response?.data?.result,
            });
          }
          yield put({
            type: loaderAction.CLOSE_LOADER,
          });

          Toast("success", `${userType} Updated Successfully!`);
        }
      } catch (e) {
        console.log(e);
        yield put({
          type: actions.CATCH_ERROR,
          payload: true,
        });
        Toast("error", e.response.data.message);
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
      }
    }
  );
}

export function* deletePlayer() {
  yield takeLatest(
    actions.DELETE_PLAYER_REQUEST,
    function* ({ data, userType, navigate }) {
      try {
        const response = yield deleteQuery(`player/remove?playerId=${data}`);

        if (!response.data.error) {
          yield put({
            type: actions.DELETE_PLAYER_SUCCESS,
            payload: data,
          });
          Toast("success", `${userType} Removed Successfully!`);
        }
      } catch (e) {
        console.log(e);
        yield put({
          type: actions.CATCH_ERROR,
          payload: true,
        });
        Toast("error", e.response.data.message);
      }
    }
  );
}

export function* getClub() {
  yield takeLatest(actions.GET_CLUB_REQUEST, function* () {
    try {
      const response = yield getQuery(`club`);

      if (!response.data.error) {
        yield put({
          type: actions.GET_CLUB_SUCCESS,
          payload: response.data.result.data,
        });
      }
    } catch (e) {
      console.log(e);
    }
  });
}

export function* getAllPlayers() {
  yield takeLatest(actions.GET_ALL_PLAYERS_REQUEST, function* ({ data }) {
    try {
      let query = `players?keyword=${data.keyword}&limit=18&skip=${data.skip}`;
      if (data.clubId) {
        query = `players?clubId=${data.clubId}&keyword=${data.keyword}&limit=18&skip=${data.skip}&category=${data.category}`;
      }
      const response = yield postQuery(query, data.body);
      if (response.data.result.user.length > 0) {
        yield put({
          type: actions.GET_ALL_PLAYERS_SUCCESS,
          payload: response.data.result.user,
        });
        yield put({
          type: actions.CATCH_ERROR,
          payload: false,
        });
      } else {
        yield put({
          type: actions.CATCH_ERROR,
          payload: true,
        });
      }
    } catch (e) {
      yield put({
        type: actions.CATCH_ERROR,
        payload: true,
      });
      // yield put({
      //   type: actions.GET_ALL_PLAYERS_SUCCESS,
      //   payload: [],
      // });
      console.log(e);
    }
  });
}

export function* getArchivedPlayers() {
  yield takeLatest(actions.GET_ARCHIVED_PLAYERS_REQUEST, function* ({ data }) {
    try {
      let query = `players?keyword=${data.keyword}&limit=18&skip=${data.skip}`;
      // if (data.clubId) {
      //   query = `players?clubId=${data.clubId}&keyword=${data.keyword}&limit=18&skip=${data.skip}`;
      // }
      const response = yield postQuery(query, data.body);
      if (response.data.result.user.length > 0) {
        yield put({
          type: actions.GET_ARCHIVED_PLAYERS_SUCCESS,
          payload: response.data.result.user,
        });
        // yield put({
        //   type: actions.CATCH_ERROR,
        //   payload: false,
        // });
      } else {
        // yield put({
        //   type: actions.CATCH_ERROR,
        //   payload: true,
        // });
      }
    } catch (e) {
      // yield put({
      //   type: actions.CATCH_ERROR,
      //   payload: true,
      // });
      // yield put({
      //   type: actions.GET_ALL_PLAYERS_SUCCESS,
      //   payload: [],
      // });
      console.log(e);
    }
  });
}

export function* getPlayerStatus() {
  yield takeLatest(actions.GET_PLAYER_STATUS_REQUEST, function* () {
    try {
      const response = yield getQuery(`player/status`);

      if (!response.data.error) {
        yield put({
          type: actions.GET_PLAYER_STATUS_SUCCESS,
          payload: response.data.result,
        });
      }
    } catch (e) {
      console.log(e);
    }
  });
}

export function* getPlayerCategory() {
  yield takeLatest(actions.GET_PLAYER_CATEGORY_REQUEST, function* () {
    try {
      const response = yield getQuery(`player/category`);

      if (!response.data.error) {
        yield put({
          type: actions.GET_PLAYER_CATEGORY_SUCCESS,
          payload: response.data.result,
        });
      }
    } catch (e) {
      console.log(e);
    }
  });
}

export function* getCoursesOfUser() {
  yield takeLatest(actions.GET_PLAYER_COURSES_REQUEST, function* ({ data }) {
    try {
      const response = yield getQuery(`user-courses?userId=${data.userId}`);

      if (!response.data.error) {
        yield put({
          type: actions.GET_PLAYER_COURSES_SUCCESS,
          payload: { userId: data.userId, courses: response.data.result },
        });
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
      }
    } catch (e) {
      console.log(e);
      yield put({
        type: loaderAction.CLOSE_LOADER,
      });
    }
  });
}

export function* createTransferableSkill() {
  yield takeLatest(
    actions.ADD_TRANSFERABLE_REQUEST,
    function* ({ data, navigate }) {
      try {
        const response = yield putQuery(`transferable-skill/add`, data);
        if (!response.data.error) {
          yield put({
            type: actions.ADD_TRANSFERABLE_SUCCESS,
            payload: response.data.result,
          });
          yield put({
            type: loaderAction.CLOSE_LOADER,
          });
          Toast("success", "Successfully Created!");
        }
      } catch (e) {
        console.log(e);
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
        Toast("error", e.response.data.message);
      }
    }
  );
}

export default function* playerSaga() {
  yield all([
    fork(getPlayers),
    fork(createPlayers),
    fork(updatePlayer),
    fork(getClub),
    fork(getAllPlayers),
    fork(getPlayerStatus),
    fork(getPlayerCategory),
    fork(getArchivedPlayers),
    fork(deletePlayer),
    fork(getCoursesOfUser),
    fork(createTransferableSkill),
  ]);
}
