import "./App.css";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Login from "pages/Login/Login";
import { AuthRoute } from "Route/AuthRoute";
import { useEffect } from "react";
import Dashboard from "pages/Dashboard/Dashboard";
import { actions } from "redux/User/action";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { PrivateRoute } from "Route/PrivateRoute";
import Players from "pages/Players/Players";
import Master from "pages/Master/Master";
import jwt_decode from "jwt-decode";
import AdminDashboard from "pages/Dashboard/AdminDashboard";
import ClubAdminDashboard from "pages/Dashboard/ClubAdminDashboard";
import Account from "pages/Account/Account";
import Archived from "pages/Archived/Archived";
import { actions as playersAction } from "redux/Player/action";
import ForgotPassword from "pages/ForgotPassword/ForgotPassword";
import ResetPassword from "pages/ForgotPassword/ResetPassword";
import Blocked from "pages/Archived/Blocked";
import Courses from "components/Courses";
import { CourseCardInfo } from "components/CourseCard";
import QualificationCard from "components/CourseCard/QualificationCard";
import Course from "pages/Course/Course";
import { actions as courseActions } from "redux/Course/action";
import AllCourses from "pages/AllCourses/AllCourses";
import EditableCourse from "pages/AllCourses/EditableCourse";
import UserProfile from "pages/UserProfile/UserProfile";
import OrganizationUserProfile from "pages/OrganizationUserProfile/OrganizationUserProfile";
import PlayerCategories from "pages/PlayerCategories/PlayerCategories";
import RegisterBrand from "pages/Brand/RegisterBrand";
import Theme from "pages/Theme/Theme";
import { useSelector } from "react-redux";

// import CourseCardInfo from "components/CourseCardInfo";
// import { Toast } from "utils/toast";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);

  let token = localStorage.getItem("TOKEN");

  let decoded;
  if (token) {
    decoded = jwt_decode(token);
  }

  const { brandTheme } = useSelector((state) => state.brand);
  const randomNumber = (min, max) => {
    return Math.floor(Math.random() * (999 - 100 + 1)) + 100;
  };

  useEffect(() => {
    if (user?.designation === "Routes Super Admin") {
      const root = document.documentElement;
      root.style.setProperty("--primary-color", "#006B01");
      root.style.setProperty("--secondary-color", "#006B01");
      root.style.setProperty("--hover-color", "#006B01");
    }

    if (brandTheme && !brandTheme?.organizationId && brandTheme?.themeColors) {
      console.log("user", user);
      localStorage.setItem("brandTheme", JSON.stringify(brandTheme));
    }

    if (
      brandTheme &&
      brandTheme?.themeColors &&
      user?.designation !== "Routes Super Admin"
    ) {
      const brandThemeLocal = JSON.parse(localStorage.getItem("brandTheme"));
      const root = document.documentElement;
      root.style.setProperty(
        "--primary-color",
        brandTheme
          ? brandTheme?.themeColors?.primary
          : brandThemeLocal?.themeColors?.primary
      );
      root.style.setProperty(
        "--secondary-color",
        brandTheme
          ? brandTheme?.themeColors?.secondary
          : brandThemeLocal?.themeColors?.secondary
      );
      root.style.setProperty(
        "--hover-color",
        brandTheme
          ? brandTheme?.themeColors?.hover
          : brandThemeLocal?.themeColors?.hover
      );
    } else {
      const root = document.documentElement;
      root.style.setProperty("--primary-color", "#006B01");
      root.style.setProperty("--secondary-color", "#006B01");
      root.style.setProperty("--hover-color", "#006B01");
    }

    if (brandTheme && brandTheme?.logos?.favicon) {
      const favicon = document.querySelector('link[rel="icon"]');

      if (favicon) {
        favicon.href = `${brandTheme?.logos?.favicon}?${randomNumber(1, 1000)}`;
      } else {
        const newFavicon = document.createElement("link");
        newFavicon.rel = "icon";
        newFavicon.href = `${brandTheme?.logos?.favicon}?${randomNumber(
          1,
          1000
        )}`;
        document.head.appendChild(newFavicon);
      }
    }

    if (brandTheme && brandTheme?.brandName) {
      document.title = `${
        brandTheme?.brandName
          ? brandTheme?.brandName + " - Control Centre"
          : "Routes - Control Centre"
      }`;
    }
  }, [brandTheme]);

  useEffect(() => {
    if (!token) return;
    if (
      (decoded?.role === "tutor" || decoded?.role === "clubAdmin") &&
      decoded?.isDelete
    ) {
      localStorage.removeItem("TOKEN");
      // eslint-disable-next-line
      token = "";
      navigate("/auth/account/blocked");
    }
    // decoded?.role === "tutor" && dispatch(actions.userDetail());
    dispatch(
      actions.userDetail(
        decoded?.role === "tutor" || decoded?.role === "clubAdmin"
          ? "tutor"
          : "admin",
        navigate
      )
    );
    dispatch(playersAction.getPlayerStatus());
    dispatch(playersAction.getPlayerCategory());
    dispatch(courseActions.getInstitutions());
    // eslint-disable-next-line
  }, [token, dispatch]);

  return (
    <>
      <ToastContainer />
      {/* <BrowserRouter> */}

      <Routes>
        <Route element={<PrivateRoute />}>
          <Route
            path="/"
            element={
              ((decoded?.role === "tutor" || decoded?.role === "clubAdmin") && (
                <Dashboard />
              )) ||
              (decoded?.role === "admin" && <AdminDashboard />)
            }
          />
          <Route
            path="/club-admin"
            element={
              decoded?.role === "clubAdmin" ? (
                <ClubAdminDashboard />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route path="/players" element={<Players />} />
          <Route
            path="/master"
            element={
              decoded?.role === "admin" ? <Master /> : <Navigate to="/" />
            }
          />
          <Route
            path="/profile"
            element={
              decoded?.role === "tutor" ||
              decoded?.role === "clubAdmin" ||
              decoded?.role === "admin" ? (
                <Account />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/register-admin"
            element={
              decoded?.designation === "SystemAdmin" ? (
                <RegisterBrand />
              ) : (
                <Navigate to="/" />
              )
            }
          />

          <Route
            path="/theme"
            element={
              // decoded?.role === "tutor" ||
              // decoded?.role === "clubAdmin" ||

              decoded?.role === "admin" ? <Theme /> : <Navigate to="/" />
            }
          />

          <Route
            path="/clubs"
            element={
              decoded?.role === "admin" ? (
                <AdminDashboard />
              ) : (
                <Navigate to="/" />
              )
            }
          />

          <Route
            path="/clubs/archived"
            element={
              decoded?.role === "admin" ? <Archived /> : <Navigate to="/" />
            }
          />

          <Route
            path="/tutors/archived"
            element={
              decoded?.role === "clubAdmin" || decoded?.role === "admin" ? (
                <Archived />
              ) : (
                <Navigate to="/" />
              )
            }
          />

          <Route
            path="/players/archived"
            element={
              decoded?.role === "admin" ? <Archived /> : <Navigate to="/" />
            }
          />

          <Route
            path="/club/info/:id"
            element={
              decoded?.role === "admin" ? (
                <ClubAdminDashboard />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/club/dashboard/:id"
            element={
              decoded?.role === "admin" ? <Dashboard /> : <Navigate to="/" />
            }
          />
          <Route
            path="/club/dashboard/:id/course/:courseId"
            element={decoded?.role === "admin" && <Dashboard />}
          />
          <Route
            path="/course/:courseId"
            element={
              decoded?.role === "tutor" ||
              (decoded?.role === "clubAdmin" && <Dashboard />)
            }
          />

          <Route
            path="/courses"
            element={decoded?.role === "admin" && <Courses />}
          />

          <Route
            path="/courses/qualification-type/:instituteTypeId"
            element={<QualificationCard playerCentre={"superCourseCenter"} />}
          />

          <Route
            path="/courses/course/:courseId"
            element={
              decoded?.role === "admin" && (
                <CourseCardInfo playerCentre={"superCourseCenter"} />
              )
            }
          />

          <Route
            path="/all-courses"
            element={decoded?.role === "admin" && <AllCourses />}
          />
          <Route
            path="/course/update/:courseId"
            element={decoded?.role === "admin" && <EditableCourse />}
          />

          <Route
            path="/courses/course/new"
            element={
              decoded?.role === "admin" && (
                <Course playerCentre={"superCourseCenter"} />
              )
            }
          />

          <Route
            path="/common-user-profile"
            element={decoded?.role === "admin" && <UserProfile />}
          />
          <Route
            path="/organisation/common-user-profile"
            element={
              decoded?.role === "clubAdmin" && <OrganizationUserProfile />
            }
          />

          {/* <Route
            path="/organisation/player-categories"
            element={decoded?.role === "clubAdmin" && <PlayerCategories />}
          /> */}
        </Route>

        {/* Without login route */}
        <Route path="/auth" element={<AuthRoute />}>
          <Route path="login" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route
            path="password-reset/:userId/:token"
            // path="password-reset"
            element={<ResetPassword />}
          />
          <Route
            path="account/blocked"
            // path="password-reset"
            element={<Blocked />}
          />
        </Route>
        <Route
          path="*"
          element={<Navigate to={token ? "/" : "/auth/login"} />}
        />
      </Routes>

      {/* </BrowserRouter> */}
    </>
  );
}

export default App;
