import React, { useContext, useEffect, useState } from "react";
import Button from "components/Button";
import Input from "components/Input";
import "./RegisterPlayer.css";
import { useDispatch, useSelector } from "react-redux";
import { actions as playersAction } from "redux/Player/action";
import { Dropdown } from "primereact/dropdown";
import Label from "components/Label";
import { Toast } from "utils/toast";
import { useParams } from "react-router-dom";
import { INSTITUTE_TYPE_ID_FOOTBALL } from "utils/constants";
import { Checkbox } from "primereact/checkbox";
import { actions as loaderActions } from "redux/Loader/action";
import { UserContext } from "components/contexts/User.context";

// const status = Status.map((status) => {
//   return { name: status.name, value: status.id };
// });
const RegisterPlayer = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { myCourses } = useSelector((state) => state.course);
  const { networkProgressDialog } = useSelector((state) => state.loader);
  const { workExperiences } = useSelector((state) => state.workExperience);
  let { clubs } = useSelector((state) => state.club);
  let clubData =
    params?.id &&
    clubs?.find((club) => {
      return params?.id === club?.id;
    });

  const { user } = useSelector((state) => state.user);
  const {
    playerStatus: playerStatuses,
    errors,
    existPlayers,
  } = useSelector((state) => state.players);
  const status =
    playerStatuses &&
    playerStatuses?.length > 0 &&
    playerStatuses?.map((status) => {
      return { name: status.name, value: status.id };
    });
  const { user: userType } = useContext(UserContext);

  const [createNewPlayer, setCreateNewPlayer] = useState(false);
  const [playerStatus, setPlayerStatus] = useState("");
  const [playerData, setPlayerData] = useState({
    name: "",
    email: "",
    date_of_birth: "",
    FAno: "",
    PFAno: "",
    NIno: "",
    status: "",
    coursesId: [],
    workExperiencesId: [],
  });

  const handleChange = (event) => {
    setPlayerData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const onSetCourse = (e) => {
    if (e.checked) {
      setPlayerData({
        ...playerData,
        coursesId: [...playerData.coursesId, e.value],
      });
    } else {
      const courseIds = playerData.coursesId.filter((course) => {
        return course !== e.value;
      });
      playerData.coursesId = courseIds;
      setPlayerData({ ...playerData });
    }
  };

  const onSetWorkExperiences = (e) => {
    if (e.checked) {
      setPlayerData({
        ...playerData,
        workExperiencesId: [...playerData.workExperiencesId, e.value],
      });
    } else {
      const workExpIds = playerData.workExperiencesId.filter((workExp) => {
        return workExp !== e.value;
      });
      playerData.workExperiencesId = workExpIds;
      setPlayerData({ ...playerData });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!playerData?.email || !playerData?.name || !playerData?.date_of_birth) {
      Toast("error", "All fields are required!");
    } else {
      playerData.club =
        clubData && params?.id
          ? {
              id: clubData?.id,
              name: clubData?.name,
              image: clubData?.image,
              instituteId: clubData?.instituteId,
              instituteTypeId: clubData?.instituteTypeId,
            }
          : user?.club;
      playerData.brandId =
        clubData && params?.id ? clubData?.brandId : user?.club?.brandId;

      // playerData.club = user?.club;
      playerData.status = playerStatus;
      dispatch(loaderActions.startLoader());
      setCreateNewPlayer(true);

      dispatch(playersAction.createPlayers([playerData], userType));
      // if (errors.length <= 0 && existPlayers.length <= 0) {
      //   setPlayerData({
      //     name: "",
      //     email: "",
      //     date_of_birth: "",
      //     FAno: "",
      //     PFAno: "",
      //     NIno: "",
      //     status: "",
      //   });
      //   setPlayerStatus("");
      // }
    }
  };

  useEffect(() => {
    if (errors.length > 0 && createNewPlayer) {
      Toast("error", errors[0].error);
    }
    if (existPlayers.length > 0 && createNewPlayer) {
      Toast("error", existPlayers[0].error);
    }
    if (errors.length <= 0 && existPlayers.length <= 0) {
      setPlayerData({
        name: "",
        email: "",
        date_of_birth: "",
        FAno: "",
        PFAno: "",
        NIno: "",
        status: "",
        coursesId: [],
        workExperiencesId: [],
      });
      setPlayerStatus("");
    }
  }, [errors, existPlayers]);

  return (
    <>
      {networkProgressDialog && (
        <>
          <div id="cover-spin" className="LoaderSpin">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </>
      )}
      <form onSubmit={handleSubmit}>
        <div id="createSection" className="">
          <Input
            name="name"
            type="text"
            className="fieldBox"
            id="name"
            value={playerData?.name}
            label="Name"
            onChange={(e) => handleChange(e)}
          />
          <Input
            name="email"
            type="email"
            className="fieldBox"
            id="email"
            value={playerData?.email}
            label="Email Address"
            onChange={(e) => handleChange(e)}
          />
          <Input
            name="date_of_birth"
            type="date"
            className="fieldBox"
            id="date_of_birth"
            value={playerData?.date_of_birth}
            label="Date of Birth"
            onChange={(e) => handleChange(e)}
          />
          {(params?.id &&
            clubData?.instituteTypeId === INSTITUTE_TYPE_ID_FOOTBALL) ||
          user?.club?.instituteTypeId === INSTITUTE_TYPE_ID_FOOTBALL ? (
            <>
              <Input
                name="FAno"
                type="text"
                className="fieldBox"
                id="FAno"
                value={playerData?.FAno}
                label="FA No."
                onChange={(e) => handleChange(e)}
              />
              <Input
                name="PFAno"
                type="text"
                className="fieldBox"
                id="PFAno"
                value={playerData?.PFAno}
                label="PFA No."
                onChange={(e) => handleChange(e)}
              />
              <Input
                name="NIno"
                type="text"
                className="fieldBox"
                id="NIno"
                value={playerData?.NIno}
                label="N.I. No."
                onChange={(e) => handleChange(e)}
              />
            </>
          ) : null}

          <div className="createField">
            <Label title="Status" />
            <Dropdown
              value={playerStatus}
              options={status}
              onChange={(e) => setPlayerStatus(e.value)}
              optionLabel="name"
              placeholder="Select a Status"
              className="w-100 dropdownStatus"
            />
          </div>
          {user?.role !== "tutor" && (
            <div className="mb-3">
              <Label title="Select courses" className="createField" />
              <div className="d-flex flex-wrap gap-4">
                {myCourses?.length > 0 &&
                  myCourses?.map((item, i) => {
                    return (
                      <div className="d-flex align-items-center">
                        <Checkbox
                          inputId={`ingredient${i}`}
                          name="course"
                          value={item.id}
                          onChange={onSetCourse}
                          checked={playerData?.coursesId?.includes(item.id)}
                        />
                        <label htmlFor={`ingredient${i}`} className="ml-2 fs-5">
                          {item.title}
                        </label>
                      </div>
                    );
                  })}
              </div>
              <div className="mt-4">
                <Label title="Select Work Experience" className="createField" />
                <div className="d-flex flex-wrap gap-4">
                  {workExperiences?.length > 0 &&
                    workExperiences?.map((item, i) => {
                      return (
                        <div className="d-flex align-items-center">
                          <Checkbox
                            inputId={`workExperience${i}`}
                            name="workExperience"
                            value={item.id}
                            onChange={onSetWorkExperiences}
                            checked={playerData?.workExperiencesId?.includes(
                              item.id
                            )}
                          />
                          <label
                            htmlFor={`workExperience${i}`}
                            className="ml-2 fs-5"
                          >
                            {item.title}
                          </label>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          )}
          <Button className="buttonClass" label="Create" />
        </div>
      </form>
    </>
  );
};

export default RegisterPlayer;
