import React, { Component } from "react";
import Button from "components/Button";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Dialog } from "primereact/dialog";
import "./CropImage.css";
export default class CropModal extends Component {
  state = {
    crop: {
      unit: "%",
      width: 30,
      aspect: 1 / 1,
      maxWidth: 100,
      maxHeight: 100,
      minWidth: 100,
      minHeight: 100,
    },
    croppedImage: null,
  };

  handleClickOpen = () => {
    this.setState({ openModal: true });
  };

  handleClose = () => {
    this.setState({ openModal: false });
  };
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  renderFooter = (name) => {
    return (
      <div>
        {/* <Button
          label="No"
          icon="pi pi-times"
          handleClick={() => this.props.setCropModal(false)}
          className="p-button-text btn btn-outline-secondary"
        /> */}
        <Button
          label="Save"
          icon="pi pi-check"
          handleClick={() => {
            this.props.setCropModal(false);
            this.props.setCroppedImage(this.state.croppedImage);
          }}
          autoFocus
          className="btn btn-success button-primary"
        />
      </div>
    );
  };

  onCropComplete = async (crop) => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImage = await this.getCroppedImg(
        this.imageRef,
        crop,
        "admin-profile-" + new Date().getTime() + ".jpg"
      );

      // this.setState({ croppedImageUrl: URL.createObjectURL(croppedImageUrl) });
      // this.props.setCroppedImageUrl(URL.createObjectURL(croppedImageUrl));
      this.setState({ croppedImage: croppedImage });
    }
  };

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // As Base64 string
    // const base64Image = canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob(async (blob) => {
        if (blob) {
          const fileType = blob.type.split("/")[1] || "png";
          const fileName =
            "tutor-profile-" + new Date().getTime() + "." + fileType;
          const file = new File([blob], fileName, {
            type: blob.type,
          });
          resolve(file);
        }
      });
    });
  }

  render() {
    const { openCropModal, setCropModal } = this.props;

    return (
      <div>
        <Dialog
          visible={openCropModal}
          onHide={() => setCropModal(false)}
          // breakpoints={{ "960px": "75vw", "640px": "100vw" }}
          // style={{ width: "50vw" }}
          footer={this.renderFooter("displayBasic")}
        >
          <ReactCrop
            src={this.props.src}
            crop={this.state.crop}
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
            style={{ width: "400px", height: "400px" }}
          />
        </Dialog>
      </div>
    );
  }
}
