import React, { useContext, useEffect, useState } from "react";
import "./PlayersCard.css";
import { useDispatch, useSelector } from "react-redux";
import Card from "components/Card";
import { actions } from "../../redux/Player/action";
import InfiniteScroll from "react-infinite-scroll-component";
import jwt_decode from "jwt-decode";
import { UserContext } from "components/contexts/User.context";

const PlayersCard = (props) => {
  const {
    page,
    selectedPositions,
    selectedClubs,
    keyword,
    players,
    setPlayers,
    operation,
    selectedFilter,
  } = props;
  const [skip, setSkip] = useState(0);
  const { brandTheme } = useSelector((state) => state.brand);
  const { user: userType } = useContext(UserContext);

  const [hasMoreItems, setHasMoreItems] = useState(true);
  const dispatch = useDispatch();

  const {
    allPlayers,
    error,
    playerStatus: playerStatuses,
    archivedPlayers,
  } = useSelector((state) => state.players);
  const { user } = useSelector((state) => state.user);
  const token = localStorage.getItem("TOKEN");
  let decoded;
  if (token) {
    decoded = jwt_decode(token);
  }
  useEffect(() => {
    if (
      selectedPositions?.length <= 0 &&
      selectedClubs?.length <= 0 &&
      keyword === "" &&
      allPlayers.length > 0 &&
      !error
    ) {
      setPlayers([...players, ...allPlayers]);
      setHasMoreItems(true);
    } else if (!error) {
      if (
        selectedPositions?.length >= 0 ||
        selectedClubs?.length >= 0 ||
        keyword !== ""
      ) {
        setPlayers([...players, ...allPlayers]);
        setHasMoreItems(true);
      } else {
        setPlayers([...allPlayers]);
        setHasMoreItems(true);
        setSkip(0);
      }
    }

    // if (archivedPlayers) {
    //   setPlayers([...allPlayers]);
    // }

    if (error) {
      setHasMoreItems(false);
      setSkip(0);
    }
    // eslint-disable-next-line
  }, [allPlayers, error]);

  const fetchMoreData = () => {
    setSkip(skip + 18);
    if (error || (allPlayers?.length < 18 && archivedPlayers.length < 1)) {
      setSkip(0);
      setHasMoreItems(false);
      return;
    } else {
      if (page === "master" || decoded?.role === "admin") {
        // eslint-disable-next-line
        const data = selectedFilter?.filter((data) => {
          if (
            data?.status?.length > 0 ||
            data?.clubs.length > 0 ||
            data?.positions?.length > 0
          ) {
            return data;
          }
        });
        dispatch(
          actions.getAllPlayers({
            keyword: keyword,
            skip: skip + 18,
            body: {
              positions: selectedPositions,
              clubId: selectedClubs,
              brandId: brandTheme?.id,
              filter: data.length > 0 && {
                filterData: selectedFilter,
                operation: operation,
              },
            },
          })
        );
      } else {
        user?.club?.id &&
          dispatch(
            actions.getAllPlayers({
              clubId: user?.club?.id,
              keyword: keyword,
              skip: skip + 18,
              body: {
                positions: selectedPositions,
                brandId: brandTheme?.id,
              },
            })
          );
      }
    }
  };

  const optionList = [
    // {
    //   title: "Club info",
    //   // navigate: "/club/info",
    //   navigate: "/club/dashboard",
    //   icon: "eye",
    // },
    // {
    //   title: "Update",
    //   // navigate: "/",
    //   icon: "edit-3",
    //   attribute: { "data-bs-toggle": "modal", "data-bs-target": "#myModal" },
    // },
    // {
    //   title: "Unarchive",
    //   icon: "archive",
    //   onClick: (id) => {
    //     dispatch(actions.updateClub({ isEnable: true, id: id }, false));
    //   },
    // },
    {
      title: "Archive",
      // navigate: "/",
      icon: "archive",
      onClick: (email) => {
        setSkip(0);
        const data = players.filter((playerData) => {
          return playerData?.email !== email;
        });
        setPlayers([...data]);
        dispatch(
          actions.updatePlayer({ isArchived: true, email: email }, userType)
        );
        // dispatch(
        //   actions.getAllPlayers({
        //     keyword: "",
        //     skip: 0,
        //     body: { positions: selectedPositions, clubId: selectedClubs },
        //   })
        // );
      },
    },
  ];

  return (
    <>
      <div className="container-fluid py-5">
        <div id="noData" className="text-center">
          {error && players.length <= 0 && "No Data Found"}
        </div>
        <div
          className="d-flex h-100 flex-wrap justify-content-center"
          id="card">
          {players && (
            <InfiniteScroll
              dataLength={players?.length}
              next={fetchMoreData}
              hasMore={hasMoreItems}
              loader={
                <div className="loader w-100" id="loader">
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </div>
              }
              className="d-flex h-100 flex-wrap justify-content-center">
              {players &&
                players?.length > 0 &&
                players?.map((player, i) => {
                  let dataStatus = player?.status?.filter((status) => {
                    return status?.current === true;
                  });
                  dataStatus =
                    playerStatuses &&
                    playerStatuses?.length > 0 &&
                    playerStatuses?.find((data) => {
                      return dataStatus[0]?.id === data?.id;
                    });
                  return (
                    <Card
                      image={player?.profileImage && player?.profileImage}
                      name={player?.name && player?.name}
                      clubName={player?.club?.name && player?.club?.name}
                      clubImage={player?.club?.image && player?.club?.image}
                      position={player?.position && player?.position}
                      playerStatus={player?.status && dataStatus}
                      key={i + 1}
                      cardType="playerCard"
                      optionList={
                        (decoded?.role === "admin" || user?.role === "admin") &&
                        optionList
                      }
                      menuIcon={true}
                      player={player}
                    />
                  );
                })}
            </InfiniteScroll>
          )}
        </div>
      </div>
    </>
  );
};

export default PlayersCard;
