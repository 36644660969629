import React, { useContext, useState } from "react";
import BoxToBox from "components/BoxToBox";
import Button from "components/Button";
import PlayerInfo from "components/PlayerInfo";
import TransferableSkills from "components/TransferableSkills";
import { useDispatch } from "react-redux";
import { actions as playersAction } from "redux/Player/action";
import QualificationGrading from "components/QualificationGrading";
import { actions as loaderActions } from "redux/Loader/action";
import { useSelector } from "react-redux";
import { UserContext } from "components/contexts/User.context";

const Player = ({ player, setPlayer }) => {
  const dispatch = useDispatch();
  const { user: userType } = useContext(UserContext);
  const [contentEditable, setContentEditable] = useState(false);
  const [editCourses, setEditCourses] = useState(false);
  const { networkProgressDialog } = useSelector((state) => state.loader);
  const { players } = useSelector((state) => state.players);
  const handleClick = () => {
    setContentEditable(true);
  };

  const handleSave = () => {
    setContentEditable(false);
    dispatch(loaderActions.startLoader());
    const playerIndex = players.findIndex((data) => data.id === player.id);
    players[playerIndex] = player;
    //console.log(player);
    dispatch(playersAction.updatePlayer(player, userType));
  };

  // useEffect(() => {
  //   setContentEditable(false);
  // }, [player]);

  return (
    <>
      <PlayerInfo
        contentEditable={contentEditable}
        player={player}
        setPlayer={setPlayer}
        setEditCourses={setEditCourses}
      />
      <TransferableSkills
        contentEditable={contentEditable}
        player={player}
        setPlayer={setPlayer}
      />
      <BoxToBox
        contentEditable={contentEditable}
        player={player}
        setPlayer={setPlayer}
      />

      <QualificationGrading
        contentEditable={contentEditable}
        player={player}
        setPlayer={setPlayer}
        editCourses={editCourses}
        setEditCourses={setEditCourses}
      />

      <div id="buttonDiv" className="buttonDiv">
        {!contentEditable && (
          <Button
            id="edit"
            label="EDIT"
            className="buttonClass floatLeft"
            handleClick={() => handleClick()}
          />
        )}
        {contentEditable && (
          <>
            <Button
              id="save"
              label="Save"
              className="buttonClass floatLeft"
              handleClick={() => handleSave()}
            />
            <Button
              id="cancel"
              label="Cancel"
              className="buttonClass floatLeft"
              handleClick={() => setContentEditable(false)}
            />
          </>
        )}
      </div>

      {networkProgressDialog && (
        <>
          <div id="cover-spin" className="LoaderSpin">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Player;
