import { all, fork, put, takeLatest } from "redux-saga/effects";
import { getQuery, postQuery, putQuery } from "utils/api";
import { Toast } from "utils/toast";
import { actions } from "./action";
import { actions as loaderAction } from "redux/Loader/action";
import { actions as brandAction } from "redux/Brand/action";
import bcrypt from "bcryptjs";

export function* userRegistration() {
  yield takeLatest(
    actions.USER_REGISTRATION_REQUEST,
    function* ({ data, navigate }) {
      try {
        const response = yield postQuery(`registration`, data);

        if (!response.data.error) {
          yield put({
            type: actions.USER_REGISTRATION_SUCCESS,
            payload: response.data.result,
          });
        }
      } catch (e) {
        console.log(e);
      }
    }
  );
}

export function* userLogin() {
  yield takeLatest(actions.USER_LOGIN_REQUEST, function* ({ data, navigate }) {
    try {
      // const salt = bcrypt.genSaltSync(10);
      // const hashedPassword = bcrypt.hashSync(data.password, salt);
      const response = yield postQuery(`tutor/login`, {
        ...data,
        password: data.password,
      });
      // const response = yield postQuery(`tutor/login`, {...data});
      if (!response.data.error) {
        yield put({
          type: actions.USER_LOGIN_SUCCESS,
          payload: response.data.result,
        });
        localStorage.setItem("TOKEN", response.data.result);
        yield put({
          type: actions.CATCH_ERROR,
          payload: "",
        });
        navigate("/");
      }
    } catch (e) {
      localStorage.removeItem("TOKEN");
      console.log(e);
      // Toast("error", e.response.data.message);
      yield put({
        type: actions.CATCH_ERROR,
        payload: e.response.data.message,
      });
    }
  });
}

export function* userDetail() {
  yield takeLatest(actions.USER_DETAIL_REQUEST, function* ({ data, navigate }) {
    try {
      const response = yield getQuery(
        data === "tutor" ? `gettutor` : "getadmin"
      );
      if (!response.data.error) {
        yield put({
          type: actions.USER_DETAIL_SUCCESS,
          payload: response.data.result,
        });
        yield put({
          type: brandAction.GET_BRAND_THEME_SUCCESS,
          payload: response.data.result.brand,

          // payload: {
          //   brand: response.data.result.brand,
          //   tab: 0,
          // },
        });
        if (
          response.data.result?.name === "" ||
          response.data.result?.designation === "" ||
          response.data.result?.email === ""
        ) {
          navigate("/profile");
          Toast("info", "Please Add Your Profile Details!");
        }
      }
    } catch (e) {
      console.log(e);
    }
  });
}

export function* userUpdate() {
  yield takeLatest(actions.USER_UPDATE_REQUEST, function* ({ data, endpoint }) {
    try {
      const response = yield putQuery(endpoint, data);
      if (!response.data.error) {
        yield put({
          type: actions.USER_UPDATE_SUCCESS,
          payload: response.data.result,
        });
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
        Toast("success", "Profile updated successfully");
      } else {
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
      }
    } catch (e) {
      console.log(e);
      yield put({
        type: loaderAction.CLOSE_LOADER,
      });
    }
  });
}

export function* updatePassword() {
  yield takeLatest(
    actions.USER_UPDATE_PASSWORD_REQUEST,
    function* ({ data, navigate }) {
      try {
        const response = yield putQuery("tutor/password", data);
        if (!response.data.error) {
          yield put({
            type: actions.USER_UPDATE_PASSWORD_SUCCESS,
            payload: response.data.result,
          });
          Toast("success", "Password updated successfully");
          yield put({
            type: actions.USER_LOGOUT_SUCCESS,
          });
          localStorage.removeItem("TOKEN");
          yield put({
            type: actions.CATCH_ERROR,
            payload: "",
          });
          yield put({
            type: loaderAction.CLOSE_LOADER,
          });
          navigate("/auth/login");
        }
      } catch (e) {
        console.log(e);
        yield put({
          type: actions.CATCH_ERROR,
          payload: e.response.data.message,
        });
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
      }
    }
  );
}

export function* userLogout() {
  yield takeLatest(actions.USER_LOGOUT_REQUEST, function* ({ navigate }) {
    try {
      yield put({
        type: actions.USER_LOGOUT_SUCCESS,
      });
      navigate("/auth/login");
    } catch (e) {
      console.log(e);
    }
  });
}

export default function* userSaga() {
  yield all([
    fork(userLogin),
    fork(userRegistration),
    fork(userLogout),
    fork(userDetail),
    fork(userUpdate),
    fork(updatePassword),
  ]);
}
