import UnitInfo from "components/UnitInfo";
import React, { useEffect, useState } from "react";
import "./CourseInfo.css";
import { useSelector } from "react-redux";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useRef } from "react";
import "./CourseInfo.css";
import { useLocation } from "react-router-dom";
import ColorPicker from "components/ColorPicker/ColorPicker";

const CourseInfo = ({
  courseInfo,
  isShow,
  setIsShow,
  setIsShowInputBox,
  isShowInputBox,
  setDuplicateShow,
  duplicateAndEdit,
  selectedOptionGradingEditable,
  selectedOptionQualificationTypeEditable,
  selectedOptionInstitutionTypeEditable,
  selectedOptionEditable,
  setLoaderSave,
  setIsCancel,
  createCourse,
  editExistingCourseFromAllCourses,
}) => {
  const courseInfoCopy = structuredClone(courseInfo);
  const location = useLocation();
  const { user } = useSelector((state) => state.user);
  const [courseUpdate, setCourseUpdate] = useState({
    title: !duplicateAndEdit && courseInfo?.title ? courseInfo?.title : "",
    icon: !duplicateAndEdit && courseInfo?.icon ? courseInfo?.icon : "",
    module: !duplicateAndEdit && courseInfo?.module,
    color: !duplicateAndEdit && courseInfo?.color ? courseInfo?.color : "",
    isPublic:
      !duplicateAndEdit && courseInfo?.isPublic ? courseInfo?.isPublic : false,
    workExperienceHours:
      !duplicateAndEdit && courseInfo?.workExperienceHours
        ? courseInfo?.workExperienceHours
        : 0,
  });

  const [duplicateUpdate, setDuplicateUpdate] = useState({
    title: duplicateAndEdit && courseInfo?.title ? courseInfo?.title : "",
    instituteId:
      duplicateAndEdit && courseInfo?.instituteId
        ? courseInfo?.instituteId
        : "",
    instituteTypeId:
      duplicateAndEdit && courseInfo?.instituteTypeId
        ? courseInfo?.instituteTypeId
        : "",
    qualificationId:
      duplicateAndEdit && courseInfo?.qualificationId
        ? courseInfo?.qualificationId
        : "",
    gradeId: duplicateAndEdit && courseInfo?.gradeId ? courseInfo?.gradeId : "",
    icon: duplicateAndEdit && courseInfo?.icon ? courseInfo?.icon : "",
    noOfModules:
      duplicateAndEdit && courseInfo?.noOfModules
        ? courseInfo?.noOfModules
        : "",
    userId: user?.id,
    module: duplicateAndEdit && courseInfo?.module,
    color: duplicateAndEdit && courseInfo?.color ? courseInfo?.color : "",
    isPublic:
      duplicateAndEdit && courseInfo?.isPublic ? courseInfo.isPublic : false,
    workExperienceHours:
      duplicateAndEdit && courseInfo?.workExperienceHours
        ? courseInfo?.workExperienceHours
        : 0,
  });

  const [imageValue, setImageValue] = useState("");
  const [add_Module, setAdd_Module] = useState([]);
  const [textColor, setTextColor] = useState("#9e9e9e");
  const [courseColor, setCourseColor] = useState("");
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);

  const onSetIsPublic = (e) => {
    setCourseUpdate((prevState) => ({
      ...prevState,
      isPublic: e.target.checked,
    }));
    setDisableSaveBtn(true);
  };

  const imgRef = useRef(null);

  const handleChangeTextColor = () => {
    setTextColor("#000");
  };

  const handleInputChange = (e) => {
    if (duplicateAndEdit === true) {
      setDuplicateUpdate((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
      setDisableSaveBtn(true);
    } else {
      setCourseUpdate((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
      setDisableSaveBtn(true);
    }
  };

  const handleImportImg = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const result = e.target.result;
      setImageValue(result);
      setCourseUpdate({ ...courseUpdate, icon: file });
      setDuplicateUpdate({ ...duplicateUpdate, icon: result });
      setDisableSaveBtn(true);
    };

    reader.readAsDataURL(file);
  };

  const UploadImg = () => {
    imgRef.current.click();
  };

  const handleAddUnit = (data) => {
    setAdd_Module([...add_Module, data]);
  };

  const handleUnitDelete = (id) => {
    const GradingIndex = add_Module?.filter((a) => a.id !== id);

    setAdd_Module(GradingIndex);

    if (duplicateAndEdit === true) {
      const deleteDuplicateUnit = duplicateUpdate?.module?.filter(
        (e) => e.id !== id
      );
      setDuplicateUpdate({
        ...duplicateUpdate,
        module: deleteDuplicateUnit,
      });
    } else {
      const deleteCourseUnit = courseUpdate?.module?.filter((e) => e.id !== id);
      setCourseUpdate({
        ...courseUpdate,
        module: deleteCourseUnit,
      });
    }
  };

  useEffect(() => {
    setCourseUpdate({
      title: courseInfo?.title ? courseInfo?.title : "",
      icon: courseInfo?.icon ? courseInfo?.icon : "",
      module: courseInfo?.module,
      color: courseInfo?.color ? courseInfo?.color : "",
      isPublic: courseInfo?.isPublic,
      workExperienceHours: courseInfo?.workExperienceHours
        ? courseInfo?.workExperienceHours
        : 0,
    });
    setCourseColor(courseInfo?.color ? courseInfo?.color : "");
    // dispatch(actions.getInstitutions({ id: course?.instituteId }));
    // dispatch(actions.getInstitutionTypes({ id: course?.instituteTypeId }));
    // dispatch(actions.getInstitutionTypes({ id: course?.instituteTypeId }));
    // dispatch(actions.getQualifications({ id: course?.qualificationId }));
    // dispatch(actions.getGrades({ id: course?.gradeId }));
  }, [courseInfo]);

  useEffect(() => {
    setDuplicateUpdate({
      title: duplicateAndEdit && courseInfo?.title ? courseInfo?.title : "",
      instituteId:
        duplicateAndEdit && courseInfo?.instituteId
          ? courseInfo?.instituteId
          : "",
      instituteTypeId:
        duplicateAndEdit && courseInfo?.instituteTypeId
          ? courseInfo?.instituteTypeId
          : "",
      qualificationId:
        duplicateAndEdit && courseInfo?.qualificationId
          ? courseInfo?.qualificationId
          : "",
      gradeId:
        duplicateAndEdit && courseInfo?.gradeId ? courseInfo?.gradeId : "",
      icon: duplicateAndEdit && courseInfo?.icon ? courseInfo?.icon : "",
      noOfModules:
        duplicateAndEdit && courseInfo?.noOfModules
          ? courseInfo?.noOfModules
          : "",
      userId: user?.id,
      module: duplicateAndEdit && courseInfo?.module,
      color: duplicateAndEdit && courseInfo?.color ? courseInfo?.color : "",
      isPublic:
        duplicateAndEdit && courseInfo?.isPublic ? courseInfo?.isPublic : false,
      workExperienceHours:
        duplicateAndEdit && courseInfo?.workExperienceHours
          ? courseInfo?.workExperienceHours
          : 0,
    });

    // eslint-disable-next-line
  }, [location?.state?.duplicateAndEdit, courseInfo]);

  useEffect(() => {
    setCourseUpdate({ ...courseUpdate, color: courseColor });
    setDuplicateUpdate({ ...duplicateUpdate, color: courseColor });
    // eslint-disable-next-line
  }, [courseColor]);

  return (
    <>
      {/* This button use for navigate to edit course  */}
      {/* {!isShowInputBox && (
        <div className="text-end">
          <Button
            className="btn btn-success text-end"
            label="Edit"
            handleClick={() => {
              navigate(
                "admin" === "admin"
                  ? `/courses/course/${course?.qualificationId}`
                  : `/course/${course?.qualificationId}`,
                {
                  state: {
                    ...location?.state,
                    // step: data?.name ? data?.name : "",
                    // step1: data?.name ? data?.name : "",
                    // playerCenter: playerCenter_,
                    instituteId: course?.instituteId,
                    instituteTypeId: course?.instituteTypeId,
                    qualificationId: course?.qualificationId,
                  },
                }
              );
            }}
          />
        </div>
      )} */}

      <div className="d-flex justify-content-between CourseInfoIcon ">
        <div className="d-flex CourseIn">
          <p className="Course_Title">Course Title</p>

          {!isShowInputBox ? (
            <>
              <p className="Title_Name">
                {/* {courseInfo?.title ? courseInfo?.title : "P3 Teamwork"} */}
                {courseUpdate?.title ? courseUpdate?.title : "P3 Teamwork"}
              </p>
            </>
          ) : (
            <>
              <input
                name="title"
                value={
                  duplicateAndEdit === true
                    ? duplicateUpdate?.title
                    : courseUpdate?.title
                }
                // value={courseUpdate?.title}
                className="CourseInput"
                style={{ color: textColor }}
                type="text"
                onChange={handleInputChange}
                onKeyDown={handleChangeTextColor}
              />
            </>
          )}
          <div className="d-flex no_of_unit">
            <p className="Unit_Title">No. of Modules</p>
            <p className="No_of_units ">
              {add_Module?.length > 0
                ? Number(add_Module?.length) + Number(courseInfo?.noOfModules)
                : courseInfo?.noOfModules
                ? courseInfo?.noOfModules
                : 0}
            </p>
          </div>
          <div className="d-flex no_of_unit">
            <p className="No_of_units ">
              {courseInfo?.isPublic === "true" ? "Public" : "Not Public"}
            </p>
          </div>
        </div>
        {isShowInputBox && (
          <>
            <div className="d-flex">
              <div className="d-flex gap-2 CourseUserIcon">
                <p className="Unit_Title">Work Experience</p>
                <input
                  name="workExperienceHours"
                  value={
                    duplicateAndEdit === true
                      ? duplicateUpdate?.workExperienceHours
                      : courseUpdate?.workExperienceHours
                  }
                  // value={courseUpdate?.title}
                  className="CourseInput"
                  style={{ color: textColor }}
                  type="number"
                  onChange={handleInputChange}
                  onKeyDown={handleChangeTextColor}
                />
              </div>
              <div className="d-flex gap-2 CourseUserIcon">
                <p className="Unit_Title">Course Tile Colour</p>
                <ColorPicker
                  courseColor={courseColor}
                  setCourseColor={setCourseColor}
                  setDisableSaveBtn={setDisableSaveBtn}
                />
              </div>
              <div d-flex CourseUserIcon>
                <span className="Unit_Title gap-2 ">Is public? </span>
                <label className="container-checkbox">
                  <input
                    type="checkbox"
                    checked={Boolean(courseUpdate?.isPublic) === true}
                    onChange={(e) => {
                      onSetIsPublic(e);
                    }}
                  />
                  <div className="checkmark"></div>
                </label>
              </div>
              <div className="d-flex CourseUserIcon" onClick={UploadImg}>
                <p className="icon ml-3 mr-2">+ icon</p>
                {imageValue || courseUpdate?.icon ? (
                  <img
                    src={imageValue ? imageValue : courseUpdate?.icon}
                    alt="test"
                    className="IconImg"
                  />
                ) : (
                  <FeatherIcon
                    className="userIcon"
                    icon="user"
                    size={18}
                    strokeWidth={2}
                  />
                )}

                <input
                  className="FileUpload"
                  ref={imgRef}
                  type="file"
                  name="uploadIcon"
                  accept="image/*"
                  // accept="image/svg+xml"
                  onChange={handleImportImg}
                />
              </div>
            </div>
          </>
        )}
      </div>

      <hr className="Line" />

      <UnitInfo
        isShowInputBox={isShowInputBox}
        setIsShowInputBox={setIsShowInputBox}
        setImageValue={setImageValue}
        module={courseInfo?.module}
        courseUpdate={courseUpdate}
        setCourseUpdate={setCourseUpdate}
        courseInfo={courseInfo}
        courseInfoCopy={courseInfoCopy}
        isShow={isShow}
        setIsShow={setIsShow}
        duplicateUpdate={duplicateUpdate}
        setDuplicateUpdate={setDuplicateUpdate}
        setDuplicateShow={setDuplicateShow}
        duplicateAndEdit={duplicateAndEdit}
        selectedOptionGradingEditable={selectedOptionGradingEditable}
        selectedOptionQualificationTypeEditable={
          selectedOptionQualificationTypeEditable
        }
        selectedOptionInstitutionTypeEditable={
          selectedOptionInstitutionTypeEditable
        }
        selectedOptionEditable={selectedOptionEditable}
        setLoaderSave={setLoaderSave}
        setIsCancel={setIsCancel}
        handleAddUnit={handleAddUnit}
        handleUnitDelete={handleUnitDelete}
        add_Module={add_Module}
        setAdd_Module={setAdd_Module}
        createCourse={createCourse ? true : false}
        disableSaveBtn={disableSaveBtn}
        setDisableSaveBtn={setDisableSaveBtn}
        editExistingCourseFromAllCourses={editExistingCourseFromAllCourses}
      />
    </>
  );
};

export default CourseInfo;
