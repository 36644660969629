import Navbar from "components/Navbar";
import {
  AddUserProfileCard,
  CreateProfileField,
  UserProfileCard,
} from "components/UserProfileCard";
import { useRef, useState } from "react";
import "./UserProfile.css";
import FeatherIcon from "feather-icons-react";
import EditProfileField from "components/UserProfileCard/EditProfileField";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "redux/Profile/action";
import { SweetAlert } from "utils/SweetAlert";
import { actions as loaderActions } from "redux/Loader/action";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import NavigationStep from "components/NavigationStep";
import { useNavigate } from "react-router-dom";

const UserProfile = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const closeRef = useRef();
  const [showProfileField, setShowProfileField] = useState(false);
  const [createNewProfile, setCreateNewProfile] = useState(false);
  const [dynamicProfileField, setDynamicProfileField] = useState(false);
  const [arrayProfileField, setArrayProfileField] = useState(null);
  const [updateProfileField, setUpdateProfileField] = useState({
    title: "",
    type: "",
    options: [],
  });
  const [error, setError] = useState({
    message: "",
    name: "",
  });
  const [options, setOptions] = useState([]);
  const [arrayField, setArrayField] = useState({
    arrayType: "",
    placeholder: null,
    objectFields: [],
    noOfField: 0,
    placeholderBool: 0,
    value: [],
    userAddable: null,
  });

  const { profileFields, dynamicProfileFields } = useSelector(
    (state) => state.profile
  );
  const { networkProgressDialog } = useSelector((state) => state.loader);
  const { brandTheme } = useSelector((state) => state.brand);

  useEffect(() => {
    dispatch(loaderActions.startLoader());
    dispatch(actions.getProfilesFields());
    dispatch(
      actions.getDynamicProfilesFields({
        instituteId: location?.state?.instituteId,
        instituteTypeId: location?.state?.instituteTypeId,
      })
    );
  }, [dispatch]);

  const optionList = [
    {
      id: 1,
      title: "Update",
      // navigate: "/",
      icon: "edit-3",
      attribute: {
        "data-bs-toggle": "modal",
        "data-bs-target": "#myModalEditProfile",
      },
      onClick: (data) => {
        setDynamicProfileField(false);
        setUpdateProfileField(data);
        const options_ = data?.options ? structuredClone(data?.options) : [];
        setOptions([...options_]);
      },
    },
    {
      id: 2,
      title: "Delete",
      icon: "trash-2",
      onClick: async (data) => {
        const result = await SweetAlert({
          title: "Are you sure? ",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancel",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
          confirmButtonColor: brandTheme
            ? brandTheme?.themeColors?.primary
            : "#006b00",
        });
        if (result.isConfirmed) {
          dispatch(
            actions.deleteProfileField({
              id: profileFields.id,
              profileFieldId: data.id,
            })
          );
          Swal.fire({
            title: "Deleted!",
            text: "Your profile field has been deleted.",
            icon: "success",
            confirmButtonColor: brandTheme
              ? brandTheme?.themeColors?.primary
              : "#006b00",
          });
        }
      },
    },
  ];

  const optionListDynamicProfile = [
    {
      id: 1,
      title: "Update",
      // navigate: "/",
      icon: "edit-3",
      attribute: {
        "data-bs-toggle": "modal",
        "data-bs-target": "#myModalEditProfile",
      },
      onClick: (data) => {
        setDynamicProfileField(true);
        setUpdateProfileField(data);
        const copyData = structuredClone(data);
        setArrayProfileField(copyData);
        const options_ = data?.options ? structuredClone(data?.options) : [];
        setOptions([...options_]);
      },
    },
    {
      id: 2,
      title: "Delete",
      icon: "trash-2",
      onClick: async (data) => {
        const result = await SweetAlert({
          title: "Are you sure? ",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancel",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
          confirmButtonColor: brandTheme
            ? brandTheme?.themeColors?.primary
            : "#006b00",
        });
        if (result.isConfirmed) {
          dispatch(
            actions.deleteDynamicProfileField({
              id: dynamicProfileFields.id,
              profileFieldId: data.id,
            })
          );
          Swal.fire({
            title: "Deleted!",
            text: "Your profile field has been deleted.",
            icon: "success",
            confirmButtonColor: brandTheme
              ? brandTheme?.themeColors?.primary
              : "#006b00",
          });
        }
      },
    },
  ];

  const navigateStep = [
    {
      title:
        "AVAILABLE COURSE TYPES | " +
        (location?.state
          ? location?.state?.steps?.step_0
            ? location?.state?.steps?.step_0
            : ""
          : ""),
      active: false,
      onClick: () => {
        navigate("/courses", {
          state: { instituteId: location?.state?.instituteId },
        });
      },
    },

    {
      title: location?.state
        ? location?.state?.steps?.step_1
          ? location?.state?.steps?.step_1
          : ""
        : "",
      active: false,
      onClick: () => {
        navigate("/courses", {
          state: { instituteId: location?.state?.instituteId },
        });
      },
    },
    {
      title: "MORE DETAILS",
      active: true,
    },
  ];

  return (
    <>
      <Navbar />

      {/* This back button show when display size minimum 768px  */}
      {showProfileField === true && (
        <div
          className="container-fluid d-flex align-items-center text-secondary fw-bold pt-2 backBtnHide"
          onClick={() => setShowProfileField(false)}>
          <FeatherIcon icon="arrow-left" size={16} className="cursor-pointer" />
          <span className="ps-1 cursor-pointer">Back</span>
        </div>
      )}

      <div className="container-fluid">
        <div className="row user-profile-row">
          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-4 profile-card-div">
            <AddUserProfileCard
              setShowProfileField={setShowProfileField}
              setCreateNewProfile={setCreateNewProfile}
            />
          </div>

          {/* User-profile-card */}

          {showProfileField === false ? (
            <>
              {networkProgressDialog ? (
                <div id="cover-spin" className="LoaderSpin">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-8 profile-card-div my-4">
                  <NavigationStep steps={navigateStep} />
                  <div>
                    <div className="px-lg-4 px-md-3 px-xs-2 mb-5 poppins">
                      <h5>Common Profile Data</h5>
                      <hr style={{ color: "rgb(145 145 145)" }} />
                    </div>
                    <div className="d-grid user-profile-card-grid">
                      {profileFields?.profileFields?.length > 0 &&
                        profileFields?.profileFields?.map((item) => {
                          return (
                            <>
                              <UserProfileCard
                                profileField={item}
                                optionList={optionList}
                                key={item?.id}
                              />
                            </>
                          );
                        })}
                    </div>
                  </div>

                  <div>
                    <div className="px-lg-4 px-md-3 px-xs-2 my-5 poppins">
                      <h5 className="text-capitalize">
                        {location?.state
                          ? location?.state?.steps?.step_1
                            ? location?.state?.steps?.step_1?.toLowerCase()
                            : ""
                          : ""}{" "}
                        Specific Profile Data
                      </h5>
                      <hr style={{ color: "rgb(145 145 145)" }} />
                    </div>
                    <div className="d-grid user-profile-card-grid">
                      {dynamicProfileFields?.profileFields?.length > 0 &&
                        dynamicProfileFields?.profileFields?.map((item) => {
                          return (
                            <>
                              <UserProfileCard
                                profileField={item}
                                optionList={optionListDynamicProfile}
                                key={item?.id}
                              />
                            </>
                          );
                        })}
                    </div>
                    {(dynamicProfileFields?.profileFields?.length <= 0 ||
                      !dynamicProfileFields) && (
                      <div className="text-center poppins fw-bold">
                        <p className="text-secondary">
                          There are no profile fields available for{" "}
                          {location?.state
                            ? location?.state?.steps?.step_1
                              ? location?.state?.steps?.step_1?.toLowerCase()
                              : ""
                            : ""}
                          .
                        </p>
                        <p
                          className="text-success text-decoration-underline fs-5 cursor-pointer"
                          onClick={() => {
                            setCreateNewProfile(true);
                            setShowProfileField(true);
                          }}>
                          Assign a new profile field?
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-8 profile-card-div my-4">
                <div className="d-grid">
                  <div
                    className="d-flex align-items-center text-secondary fw-bold profile-backBtnHide"
                    onClick={() => setShowProfileField(false)}>
                    <FeatherIcon
                      icon="arrow-left"
                      size={16}
                      className="cursor-pointer"
                    />
                    <span className="ps-1 cursor-pointer">Back</span>
                  </div>
                  <CreateProfileField
                    steps={location?.state?.steps?.step_1}
                    setShowProfileField={setShowProfileField}
                    createNewProfile={createNewProfile}
                  />
                </div>
              </div>
            </>
          )}
        </div>

        <div
          className="modal fade"
          id="myModalEditProfile"
          data-bs-keyboard="false"
          data-bs-backdrop="static">
          <div className={`modal-dialog modal-dialog-centered`}>
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Edit Profile Field</h4>
                <button
                  type="button"
                  className="btn-close me-1"
                  data-bs-dismiss="modal"></button>
              </div>

              <div className="modal-body">
                <EditProfileField
                  updateProfileField={updateProfileField}
                  setUpdateProfileField={setUpdateProfileField}
                  options={options}
                  setArrayField={setArrayField}
                  arrayField={arrayField}
                />
                {error.message && (
                  <p
                    id="login_validation"
                    style={{ fontSize: "small" }}
                    className="text-danger mt-3">
                    {error.message}
                  </p>
                )}
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  data-bs-dismiss="modal"
                  ref={closeRef}
                  onClick={() => {
                    setUpdateProfileField({ ...arrayProfileField });
                  }}>
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-success border-color-primary background-primary button-primary"
                  // data-bs-dismiss="modal"
                  onClick={() => {
                    if (updateProfileField?.title !== "") {
                      if (updateProfileField?.type?.toLowerCase() === "array") {
                        delete arrayField.noOfField;
                        delete arrayField.placeholderBool;
                      }
                      if (!dynamicProfileField) {
                        dispatch(
                          actions.updateProfileField({
                            id: profileFields.id,
                            profileData:
                              updateProfileField?.type?.toLowerCase() ===
                              "array"
                                ? [
                                    {
                                      ...updateProfileField,
                                      isContainsMedia:
                                        arrayField?.objectFields?.find(
                                          (item) => item.type === "media"
                                        )
                                          ? true
                                          : false,
                                      ...arrayField,
                                    },
                                  ]
                                : [updateProfileField],
                          })
                        );
                        closeRef.current.click();
                        setError({ ...error, message: "" });
                      } else {
                        dispatch(
                          actions.updateDynamicProfileField({
                            id: dynamicProfileFields.id,
                            profileData:
                              updateProfileField?.type?.toLowerCase() ===
                              "array"
                                ? [
                                    {
                                      ...updateProfileField,
                                      isContainsMedia:
                                        arrayField?.objectFields?.find(
                                          (item) => item.type === "media"
                                        )
                                          ? true
                                          : false,
                                      ...arrayField,
                                    },
                                  ]
                                : [updateProfileField],
                          })
                        );
                        closeRef.current.click();
                        setError({ ...error, message: "" });
                      }
                    } else {
                      setError({
                        ...error,
                        message: "Please fill The field name!",
                      });
                    }
                  }}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
