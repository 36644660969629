// import React, { useState, useEffect } from "react";
// import { useSelector, useDispatch } from "react-redux";
// // import FeatherIcon from "feather-icons-react";
// import { Toast } from "utils/toast";
// import Navbar from "components/Navbar";
// import { TabView, TabPanel } from "primereact/tabview";
// import BrandTheme from "components/BrandTheme";
// import { actions } from "redux/Brand/action";
// import { deleteBlob, uploadFile } from "utils/uploadFile";
// import { Dropdown } from "primereact/dropdown";
// import { actions as playersAction } from "redux/Player/action";
// import { actions as loaderActions } from "redux/Loader/action";
// import jwt_decode from "jwt-decode";
// // Assuming you have actions for theme management
// // import { updateTheme } from 'redux/Theme/action';

// const Theme = () => {
//   const dispatch = useDispatch();
//   const { user } = useSelector((state) => state.user);
//   const { brandTheme, brand } = useSelector((state) => state.brand);
//   const token = localStorage.getItem("TOKEN");
//   let decoded;
//   if (token) {
//     decoded = jwt_decode(token);
//   }

//   const { networkProgressDialog } = useSelector((state) => state.loader);
//   const [editMode, setEditMode] = useState(true);
//   const [hasChanges, setHasChanges] = useState({ tab0: false, tab1: false });
//   const [brandName, setBrandName] = useState("");
//   const [headerImage, setHeaderImage] = useState(null);
//   const [selectedBrandId, setSelectedBrandId] = useState(null);

//   // useEffect(() => {
//   //   // Reset hasChanges when brandTheme is updated
//   //   setHasChanges(false);
//   // }, [brandTheme]);

//   const [activeTab, setActiveTab] = useState(
//     decoded?.designation === "SystemAdmin" ? 1 : 0
//   );
//   const [logos, setLogos] = useState({
//     large: null,
//     medium: null,
//     small: null,
//     favicon: null,
//   });

//   useEffect(() => {
//     if (decoded?.designation === "SystemAdmin") {
//       dispatch(actions.getBrand());
//     }
//   }, [dispatch]);

//   // const currentTheme = useSelector((state) => state.theme);
//   const [themeColors, setThemeColors] = useState({
//     primary: "#006B01",
//     secondary: "#6c757d",
//     hover: "#28a745",
//   });
//   const [selectedClubs, setSelectedClubs] = useState(null);
//   const { club, playerStatus: playerStatuses } = useSelector(
//     (state) => state.players
//   );

//   const [parentData, setParentData] = useState({
//     brandName: "",
//     logos: {
//       large: null,
//       medium: null,
//       small: null,
//       favicon: null,
//     },
//     themeColors: {
//       primary: "#006B01",
//       secondary: "#6c757d",
//       hover: "#28a745",
//     },
//     headerImage: "",
//   });
//   const clubs =
//     club &&
//     club.club?.length > 0 &&
//     club.club.map((club) => {
//       return { name: club.name, value: club.id };
//     });

//   useEffect(() => {
//     if (user?.brandId) {
//       dispatch(actions.getBrandTheme({ brandId: user?.brandId }));
//     }
//     dispatch(playersAction.getClub());
//   }, [dispatch, user?.brandId]);

//   useEffect(() => {
//     if (brandTheme) {
//       setBrandName(brandTheme?.brandName || "");
//       setLogos(brandTheme?.logos || {});

//       setThemeColors(
//         brandTheme?.themeColors &&
//           Object.keys(brandTheme?.themeColors)?.length > 0
//           ? brandTheme?.themeColors
//           : {
//               primary: "#006B01",
//               secondary: "#6c757d",
//               hover: "#28a745",
//             }
//       );
//       setHeaderImage(brandTheme?.headerImage || "");
//     } else {
//       setBrandName("");
//       setLogos({
//         large: null,
//         medium: null,
//         small: null,
//         favicon: null,
//       });
//       setThemeColors({
//         primary: "#006B01",
//         secondary: "#6c757d",
//         hover: "#28a745",
//       });
//       setHeaderImage("");
//     }
//   }, [brandTheme]);

//   // useEffect(() => {
//   //   // Load current theme from Redux store
//   //   if (currentTheme) {
//   //     setThemeColors(currentTheme);
//   //   }
//   // }, [currentTheme]);

//   const hexToRgb = (hex) => {
//     const bigint = parseInt(hex.slice(1), 16);
//     const r = (bigint >> 16) & 255;
//     const g = (bigint >> 8) & 255;
//     const b = bigint & 255;
//     return { r, g, b };
//   };

//   // Check if the color is too light
//   const isColorTooLight = (rgb) => {
//     const { r, g, b } = rgb;
//     // Calculate brightness
//     const brightness = (r * 299 + g * 587 + b * 114) / 1000;
//     return brightness > 240; // Adjust this threshold as needed
//   };

//   const handleColorChange = (e) => {
//     const { name, value } = e.target;

//     if (name === "primary" && isColorTooLight(hexToRgb(value))) {
//       Toast(
//         "error",
//         "White and very light colors are not allowed as primary colors."
//       );
//       return;
//     }

//     setThemeColors((prevColors) => ({
//       ...prevColors,
//       [name]: value,
//     }));
//     setHasChanges((prevChanges) => ({
//       ...prevChanges,
//       [`tab${activeTab}`]: true, // Set changes for the active tab
//     }));
//   };

//   // Function to upload a single image and get its URL
//   const uploadImageToBlob = async (file, prefix) => {
//     if (!file) return null;

//     const blobName = `${prefix}-${file.name}`;
//     const containerName =
//       process.env.REACT_APP_AZURE_THEME_STORAGE_CONTAINER_NAME;

//     try {
//       const url = await uploadFile(file, blobName, containerName, {
//         blobHTTPHeaders: { blobContentType: file.type },
//       });

//       // Extract only the image URL from the returned URL
//       const imageUrl = url.split("?")[0];
//       return imageUrl;
//     } catch (error) {
//       console.error(`Error uploading ${prefix} logo:`, error);
//       Toast("error", `Failed to upload ${prefix} logo`);
//       return null;
//     }
//   };

//   const deleteImageFromBlob = async (url) => {
//     if (!url) return;

//     const containerName =
//       process.env.REACT_APP_AZURE_THEME_STORAGE_CONTAINER_NAME;
//     const blobName = url.split("/").pop();

//     try {
//       await deleteBlob(blobName, containerName);
//     } catch (error) {
//       console.error(`Error deleting image:`, error);
//       Toast("error", `Failed to delete old image`);
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     let logoUrls = { ...logos };
//     let headerImageUrl = headerImage || brandTheme?.headerImage;
//     let hasChanges = false;

//     // Check if the Organization tab is active and editMode is true
//     if (activeTab === 1 && editMode && !selectedClubs) {
//       Toast("error", "Please select a club before saving.");
//       return; // Exit if no club is selected
//     }

//     // Check if any logo has been changed (is a File object)
//     const logoChanges = Object.entries(logos).filter(
//       ([size, logo]) => logo instanceof File
//     );

//     if (logoChanges.length > 0) {
//       hasChanges = true;
//       // Upload only changed logos
//       for (const [size, logo] of logoChanges) {
//         try {
//           // Delete old image if it exists
//           if (brandTheme?.logos && brandTheme?.logos[size]) {
//             await deleteImageFromBlob(brandTheme?.logos[size]);
//           }

//           // Upload new image
//           const newUrl = await uploadImageToBlob(logo, size);
//           if (newUrl !== null) {
//             logoUrls[size] = newUrl;
//           } else {
//             Toast("error", `Failed to upload ${size} logo`);
//             return; // Exit if any logo upload fails
//           }
//         } catch (error) {
//           console.error(`Error processing ${size} logo:`, error);
//           Toast("error", `Error processing ${size} logo`);
//           return; // Exit if there's an error
//         }
//       }
//     } else {
//       // If no new files, use existing URLs
//       logoUrls =
//         decoded?.designation === "SystemAdmin"
//           ? logoUrls || {}
//           : brandTheme?.logos || {};
//     }

//     if (headerImage instanceof File) {
//       hasChanges = true;
//       try {
//         if (brandTheme?.headerImage) {
//           await deleteImageFromBlob(brandTheme?.headerImage);
//         }

//         headerImageUrl = await uploadImageToBlob(headerImage, "header");

//         if (!headerImageUrl) {
//           Toast("error", "Failed to upload header image");
//           return;
//         }
//       } catch (error) {
//         console.error("Error processing header image:", error);
//         Toast("error", "Error processing header image");
//         return;
//       }
//     }

//     // Check if brand name or theme colors have changed
//     if (brandName !== brandTheme?.brandName) hasChanges = true;
//     if (JSON.stringify(themeColors) !== JSON.stringify(brandTheme?.themeColors))
//       hasChanges = true;

//     // If nothing has changed, show a message and return
//     if (!hasChanges) {
//       Toast("info", "No changes detected");
//       // setEditMode(false);
//       setHasChanges(false);
//       return;
//     }

//     const payload = {
//       brandId:
//         decoded?.designation === "SystemAdmin"
//           ? selectedBrandId
//           : brandTheme?.id,
//       brandName,
//       themeColors,
//       logos: logoUrls,
//       headerImage: logoUrls.header || "",
//       organizationId:
//         decoded?.designation === "SystemAdmin" ? null : selectedClubs || null,
//     };
//     delete payload.logos.header;

//     try {
//       dispatch(loaderActions.startLoader());
//       dispatch(actions.createBrandTheme(payload));
//       // setEditMode(false);
//       setHasChanges(false);
//     } catch (error) {
//       console.error("Error updating theme:", error);
//       Toast("error", "Failed to update theme");
//     }
//   };

//   const toggleEditMode = () => {
//     setEditMode(!editMode);
//   };

//   const handleTabChange = (e) => {
//     setActiveTab(e.index);

//     // Reset the hasChanges state for the newly active tab
//     setHasChanges((prevChanges) => ({
//       ...prevChanges,
//       [`tab${e.index}`]: false,
//     }));

//     if (e.index === 1) {
//       // Organization tab
//       // Reset all relevant data to null
//       setBrandName("");
//       setLogos({
//         large: null,
//         medium: null,
//         small: null,
//         favicon: null,
//       });
//       setThemeColors({
//         primary: "#006B01",
//         secondary: "#6c757d",
//         hover: "#28a745",
//       });
//       setHeaderImage("");
//       setSelectedClubs(null);
//     } else if (e.index === 0) {
//       dispatch(actions.getBrandTheme({ brandId: user?.brandId }));
//       setBrandName(brandTheme?.brandName);
//       setLogos(brandTheme?.logos);
//       setThemeColors(
//         brandTheme?.themeColors &&
//           Object.keys(brandTheme?.themeColors).length > 0
//           ? brandTheme?.themeColors
//           : {
//               primary: "#006B01",
//               secondary: "#6c757d",
//               hover: "#28a745",
//             }
//       );
//       setHeaderImage(brandTheme?.headerImage);
//       setSelectedClubs(null); // Reset selected clubs if needed
//     }
//   };

//   // useEffect(() => {
//   //   if (brandTheme) {
//   //     setParentData({
//   //       brandName: brandTheme.brandName || "",
//   //       logos: brandTheme.logos || {},
//   //       themeColors: brandTheme.themeColors || {},
//   //       headerImage: brandTheme.headerImage || "",
//   //     });
//   //   }
//   // }, [brandTheme]);

//   return (
//     <div>
//       <Navbar />
//       <div className="container py-5">
//         <div className="d-flex justify-content-between align-items-center mb-4">
//           <h3>Theme Management</h3>
//           {/* {((clubs?.length > 0 && activeTab === 1) || activeTab === 0) && (
//             <button
//               className="btn btn-primary"
//               onClick={toggleEditMode}
//               style={{
//                 background: brandTheme?.themeColors?.primary
//                   ? brandTheme?.themeColors?.primary
//                   : "#006b01",
//                 border: "none",
//               }}
//             >
//               {editMode ? "Cancel" : "Edit Theme"}
//             </button>
//           )} */}
//         </div>
//         <div className="mb-4">
//           <TabView activeIndex={activeTab} onTabChange={handleTabChange}>
//             {decoded?.designation !== "SystemAdmin" && (
//               <TabPanel
//                 header="Brand Theme"
//                 headerClassName={(index) =>
//                   index === 0 ? "bg-success text-white" : ""
//                 }
//               >
//                 <BrandTheme
//                   logos={logos}
//                   setLogos={setLogos}
//                   brandName={brandName}
//                   setBrandName={setBrandName}
//                   editMode={editMode}
//                   headerImage={headerImage}
//                   setHeaderImage={setHeaderImage}
//                   showBrandName={true}
//                   hasChanges={hasChanges}
//                   setHasChanges={setHasChanges}
//                   activeTab={activeTab}
//                 />
//               </TabPanel>
//             )}
//             <TabPanel
//               header={`${
//                 decoded?.designation === "SystemAdmin"
//                   ? "Brand"
//                   : "Organization"
//               }`}
//               headerClassName={(index) =>
//                 index === 1 ? "bg-success text-white" : ""
//               }
//             >
//               {clubs ? (
//                 <>
//                   <div
//                     className="my-3 sidebarOuterDiv"
//                     id="multiSelectedClubDiv"
//                   >
//                     {(clubs || brand?.length > 0) && (
//                       <Dropdown
//                         value={selectedClubs}
//                         options={
//                           decoded?.designation === "SystemAdmin"
//                             ? brand?.map((brand) => ({
//                                 name: brand?.brandName,
//                                 value: brand?.id,
//                               }))
//                             : clubs
//                         }
//                         onChange={(e) => {
//                           setSelectedClubs(e.value);
//                           setSelectedBrandId(e.value);

//                           if (e.value === undefined) {
//                             // Reset state to default values when no club is selected
//                             setBrandName("");
//                             setLogos({
//                               large: null,
//                               medium: null,
//                               small: null,
//                               favicon: null,
//                             });
//                             setThemeColors({
//                               primary: "#006B01",
//                               secondary: "#6c757d",
//                               hover: "#28a745",
//                             });
//                             setHeaderImage("");
//                           } else {
//                             if (decoded?.designation !== "SystemAdmin") {
//                               dispatch(
//                                 actions.getBrandTheme({
//                                   organizationId: e?.value,
//                                 })
//                               );
//                             }

//                             if (decoded?.designation === "SystemAdmin") {
//                               const brandData = brand?.filter(
//                                 (b) => b?.id === e?.value
//                               )[0]; // Access the first element

//                               if (brandData) {
//                                 // Check if brandData exists
//                                 setBrandName(brandData?.brandName || "");
//                                 setLogos(
//                                   brandData?.logos || {
//                                     large: null,
//                                     medium: null,
//                                     small: null,
//                                     favicon: null,
//                                   }
//                                 );
//                                 setThemeColors(
//                                   brandData?.themeColors || {
//                                     primary: "#006B01",
//                                     secondary: "#6c757d",
//                                     hover: "#28a745",
//                                   }
//                                 );
//                                 setHeaderImage(brandData?.headerImage || "");
//                               }
//                             }
//                           }
//                         }}
//                         optionLabel="name"
//                         placeholder={`Please select ${
//                           decoded?.designation === "SystemAdmin"
//                             ? "Brand"
//                             : "Club"
//                         }`}
//                         filter
//                         filterBy="name"
//                         className="dropdown-custom"
//                         showClear
//                       />
//                     )}
//                   </div>

//                   {selectedClubs && (
//                     <BrandTheme
//                       logos={logos}
//                       setLogos={setLogos}
//                       brandName={brandName}
//                       setBrandName={setBrandName}
//                       editMode={editMode}
//                       headerImage={headerImage}
//                       setHeaderImage={setHeaderImage}
//                       showBrandName={false}
//                       hasChanges={hasChanges}
//                       setHasChanges={setHasChanges}
//                       activeTab={activeTab}
//                     />
//                   )}
//                 </>
//               ) : (
//                 <>
//                   <div
//                     className="text-center d-flex justify-content-center align-items-center"
//                     style={{ height: "100%" }}
//                   >
//                     <h4>No clubs found</h4>
//                   </div>
//                 </>
//               )}
//             </TabPanel>
//           </TabView>
//         </div>

//         {((clubs?.length > 0 && activeTab === 1 && selectedClubs) ||
//           activeTab === 0) && (
//           <form onSubmit={handleSubmit}>
//             <div className="row">
//               {Object.entries(themeColors || {}).map(
//                 ([colorName, colorValue]) => (
//                   <div className="col-lg-3 col-md-4 mb-3" key={colorName}>
//                     <div className="form-group">
//                       <label className="form-label text-capitalize">
//                         {colorName}
//                       </label>
//                       <div className="d-flex align-items-center">
//                         {editMode ? (
//                           <>
//                             <input
//                               type="color"
//                               name={colorName}
//                               value={colorValue}
//                               onChange={handleColorChange}
//                               className="form-control me-2"
//                             />
//                             <input
//                               type="text"
//                               name={colorName}
//                               value={colorValue}
//                               onChange={handleColorChange}
//                               className="form-control"
//                             />
//                           </>
//                         ) : (
//                           <div
//                             className="color-preview"
//                             style={{
//                               backgroundColor: colorValue,
//                               width: "30px",
//                               height: "30px",
//                               marginRight: "10px",
//                             }}
//                           ></div>
//                         )}
//                         {!editMode && <span>{colorValue}</span>}
//                       </div>
//                     </div>
//                   </div>
//                 )
//               )}
//             </div>
//             {hasChanges[`tab${activeTab}`] && (
//               <button
//                 type="submit"
//                 className="btn btn-primary mt-3"
//                 style={{
//                   backgroundColor: brandTheme?.themeColors?.primary
//                     ? brandTheme?.themeColors?.primary
//                     : "#006b01",
//                   border: "none",
//                 }}
//               >
//                 {networkProgressDialog ? (
//                   <>
//                     <div className="loader w-100" id="loader">
//                       <div className="text-center d-flex">
//                         <div
//                           className="spinner-border"
//                           role="status"
//                           style={{ width: "20px", height: "20px" }}
//                         >
//                           <span className="visually-hidden">Loading...</span>
//                         </div>
//                       </div>
//                     </div>
//                   </>
//                 ) : (
//                   <>Save Theme</>
//                 )}
//               </button>
//             )}
//           </form>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Theme;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./theme.css";
import { Toast } from "utils/toast";
import Navbar from "components/Navbar";
import { TabView, TabPanel } from "primereact/tabview";
import BrandTheme from "components/BrandTheme";
import { actions } from "redux/Brand/action";
import { deleteBlob, uploadFile } from "utils/uploadFile";
import { Dropdown } from "primereact/dropdown";
import { actions as playersAction } from "redux/Player/action";
import { actions as loaderActions } from "redux/Loader/action";
import jwt_decode from "jwt-decode";

const Theme = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { brandTheme, brand } = useSelector((state) => state.brand);
  const token = localStorage.getItem("TOKEN");
  let decoded;
  if (token) {
    decoded = jwt_decode(token);
  }
  const { networkProgressDialog } = useSelector((state) => state.loader);
  const [editMode, setEditMode] = useState(true);
  const [hasChanges, setHasChanges] = useState({ tab0: false, tab1: false });
  const [brandName, setBrandName] = useState("");
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [headerImage, setHeaderImage] = useState(null);
  // const [clubHeaderImage, setClubHeaderImage] = useState(null);
  const [selectedBrandId, setSelectedBrandId] = useState(null);

  const [activeTab, setActiveTab] = useState(
    decoded?.designation === "SystemAdmin" ? 1 : 0
  );
  const [logos, setLogos] = useState({
    large: null,
    medium: null,
    small: null,
    favicon: null,
  });
  // const [clubLogos, setClubLogos] = useState({
  //   large: null,
  //   medium: null,
  //   small: null,
  //   favicon: null,
  // });

  useEffect(() => {
    if (decoded?.designation === "SystemAdmin") {
      dispatch(actions.getBrand());
    }
  }, [dispatch]);

  const [themeColors, setThemeColors] = useState({
    primary: "#006B01",
    // secondary: "#6c757d",
    // hover: "#28a745",
  });

  const [selectedClubs, setSelectedClubs] = useState(null);
  const [toastShown, setToastShown] = useState(false);
  const { club, playerStatus: playerStatuses } = useSelector(
    (state) => state.players
  );

  const clubs =
    club &&
    club.club?.length > 0 &&
    club.club.map((club) => {
      return { name: club.name, value: club.id };
    });

  useEffect(() => {
    if (user?.brandId) {
      // dispatch(actions.getBrandTheme({ brandId: user?.brandId, tab: 0 }));
      dispatch(actions.getBrandTheme({ brandId: user?.brandId }));
    }
    dispatch(playersAction.getClub());
  }, [dispatch, user?.brandId]);

  useEffect(() => {
    if (brandTheme) {
      setBrandName(brandTheme?.brandName || "");
      setLogos(brandTheme?.logos || {});

      setThemeColors(
        brandTheme?.themeColors &&
          Object.keys(brandTheme?.themeColors)?.length > 0
          ? brandTheme?.themeColors
          : {
              primary: "#006B01",
              // secondary: "#6c757d",
              // hover: "#28a745",
            }
      );
      setHeaderImage(brandTheme?.headerImage || "");
    } else {
      setBrandName("");
      setLogos({
        large: null,
        medium: null,
        small: null,
        favicon: null,
      });
      setThemeColors({
        primary: "#006B01",
        // secondary: "#6c757d",
        // hover: "#28a745",
      });
      setHeaderImage("");
    }
  }, [brandTheme]);

  const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
  };

  const randomNumber = (min, max) => {
    return Math.floor(Math?.random() * (max - min + 1)) + min;
  };

  const isColorTooLight = (rgb) => {
    const { r, g, b } = rgb;
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 240;
  };

  const handleColorChange = (e) => {
    const { name, value } = e.target;

    // if (name === "primary" && isColorTooLight(hexToRgb(value))) {
    //   Toast(
    //     "error",
    //     "White and very light colors are not allowed as primary colors."
    //   );
    //   return;
    // }

    if (name === "primary" && isColorTooLight(hexToRgb(value))) {
      if (!toastShown) {
        // Check if the toast has already been shown
        Toast(
          "error",
          "White and very light colors are not allowed as primary colors."
        );
        setToastShown(true); // Set the state to true after showing the toast
      }
      return;
    } else {
      setToastShown(false); // Reset the state if a valid color is selected
    }

    setThemeColors((prevColors) => ({
      ...prevColors,
      [name]: value,
    }));
    setHasChanges((prevChanges) => ({
      ...prevChanges,
      [`tab${activeTab}`]: true,
    }));
  };

  // Function to convert base64 to Blob
  const base64ToBlob = (base64, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  // Function to upload a single image and get its URL
  const uploadImageToBlob = async (file, prefix, brandId) => {
    if (!file) return null;

    let blob;
    if (typeof file === "string" && file.startsWith("data:")) {
      const [contentType, base64Data] = file.split(";base64,");
      blob = base64ToBlob(base64Data, contentType.split(":")[1]);
    } else {
      blob = file;
    }

    const blobName = `${brandId}/${prefix}-${
      blob.name || "image"
    }?${randomNumber(1, 1000)}`;

    const containerName =
      process.env.REACT_APP_AZURE_THEME_STORAGE_CONTAINER_NAME;

    try {
      const url = await uploadFile(blob, blobName, containerName, {
        blobHTTPHeaders: { blobContentType: blob.type },
      });

      const imageUrl = url.split("?")[0];

      return imageUrl;
    } catch (error) {
      console.error(`Error uploading ${prefix} logo:`, error);
      Toast("error", `Failed to upload ${prefix} logo`);
      return null;
    }
  };

  const deleteImageFromBlob = async (url) => {
    if (!url) return;

    const containerName =
      process.env.REACT_APP_AZURE_THEME_STORAGE_CONTAINER_NAME;
    const blobName = url.split("/").pop();

    try {
      await deleteBlob(blobName, containerName);
    } catch (error) {
      console.error(`Error deleting image:`, error);
      Toast("error", `Failed to delete old image`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let logoUrls = { ...logos };
    let headerImageUrl = headerImage || brandTheme?.headerImage;
    let hasChanges = false;

    if (activeTab === 1 && editMode && !selectedClubs) {
      Toast("error", "Please select a club before saving.");
      return;
    }

    const logoChanges = Object.entries(logos).filter(
      ([size, logo]) =>
        logo instanceof File ||
        (typeof logo === "string" && logo.startsWith("data:"))
    );

    if (logoChanges.length > 0) {
      hasChanges = true;
      for (const [size, logo] of logoChanges) {
        try {
          // if (brandTheme?.logos && brandTheme?.logos[size]) {
          //   await deleteImageFromBlob(brandTheme?.logos[size]);
          // }

          const newUrl = await uploadImageToBlob(logo, size, selectedBrandId);
          if (newUrl !== null) {
            logoUrls[size] = newUrl;
          } else {
            Toast("error", `Failed to upload ${size} logo`);
            return;
          }
        } catch (error) {
          console.error(`Error processing ${size} logo:`, error);
          Toast("error", `Error processing ${size} logo`);
          return;
        }
      }
    } else {
      logoUrls =
        decoded?.designation === "SystemAdmin"
          ? logoUrls || {}
          : brandTheme?.logos || {};
    }

    if (
      headerImage instanceof File ||
      (typeof headerImage === "string" && headerImage.startsWith("data:"))
    ) {
      hasChanges = true;
      try {
        // if (brandTheme?.headerImage) {
        //   await deleteImageFromBlob(brandTheme?.headerImage);
        // }

        const newHeaderImageUrl = await uploadImageToBlob(
          headerImage,
          "header",
          selectedBrandId
        );

        if (newHeaderImageUrl) {
          headerImageUrl = newHeaderImageUrl;
        } else {
          Toast("error", "Failed to upload header image");
          return;
        }
      } catch (error) {
        console.error("Error processing header image:", error);
        Toast("error", "Error processing header image");
        return;
      }
    }

    if (brandName !== brandTheme?.brandName) hasChanges = true;
    if (JSON.stringify(themeColors) !== JSON.stringify(brandTheme?.themeColors))
      hasChanges = true;

    if (!hasChanges) {
      Toast("info", "No changes detected");
      setHasChanges(false);
      return;
    }

    const payload = {
      brandId:
        decoded?.designation === "SystemAdmin"
          ? selectedBrandId
          : brandTheme?.id,
      brandName,
      themeColors,
      logos: logoUrls,
      headerImage: headerImageUrl,
      organizationId:
        decoded?.designation === "SystemAdmin" ? null : selectedClubs || null,
    };

    delete payload.logos.header;

    try {
      dispatch(loaderActions.startLoader());
      dispatch(actions.createBrandTheme(payload));
      setHasChanges(false);
    } catch (error) {
      console.error("Error updating theme:", error);
      Toast("error", "Failed to update theme");
    }
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleTabChange = (e) => {
    setActiveTab(e.index);

    setHasChanges((prevChanges) => ({
      ...prevChanges,
      [`tab${e.index}`]: false,
    }));

    if (e.index === 1) {
      setBrandName("");
      setLogos({
        large: null,
        medium: null,
        small: null,
        favicon: null,
      });
      setThemeColors({
        primary: "#006B01",
        // secondary: "#6c757d",
        // hover: "#28a745",
      });
      setHeaderImage("");
      setSelectedClubs(null);
    } else if (e.index === 0) {
      // dispatch(actions.getBrandTheme({ brandId: user?.brandId, tab: 0 }));
      dispatch(actions.getBrandTheme({ brandId: user?.brandId }));
      setBrandName(brandTheme?.brandName || "");
      setLogos(
        brandTheme?.logos || {
          large: null,
          medium: null,
          small: null,
          favicon: null,
        }
      );
      setThemeColors(
        brandTheme?.themeColors &&
          Object.keys(brandTheme?.themeColors).length > 0
          ? brandTheme?.themeColors
          : {
              primary: "#006B01",
              // secondary: "#6c757d",
              // hover: "#28a745",
            }
      );
      setHeaderImage(brandTheme?.headerImage || "");
      setSelectedClubs(null);
    }
  };

  const filteredOptions = (options) => {
    return options?.filter((option) => option?.name && option?.value);
  };

  // useEffect(() => {
  //   if (selectedClubs && clubTheme) {
  //     setClubLogos(clubTheme?.logos || {});
  //     setClubThemeColors(clubTheme?.themeColors || {});
  //     setClubHeaderImage(clubTheme?.headerImage || "");
  //   }
  // }, [clubTheme, dispatch]);

  return (
    <div>
      <Navbar />
      <div className="container py-5">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h3>Theme Management</h3>
        </div>
        <div className="mb-4">
          <TabView activeIndex={activeTab} onTabChange={handleTabChange}>
            {decoded?.designation !== "SystemAdmin" && (
              <TabPanel
                header="Brand Theme"
                headerClassName={(index) =>
                  index === 0 ? "bg-success text-white" : ""
                }
              >
                <BrandTheme
                  logos={logos}
                  setLogos={setLogos}
                  brandName={brandName}
                  setBrandName={setBrandName}
                  editMode={editMode}
                  headerImage={headerImage}
                  setHeaderImage={setHeaderImage}
                  showBrandName={true}
                  hasChanges={hasChanges}
                  setHasChanges={setHasChanges}
                  activeTab={activeTab}
                  selectedClubs={selectedClubs}
                />
              </TabPanel>
            )}
            <TabPanel
              header={`${
                decoded?.designation === "SystemAdmin"
                  ? "Brand"
                  : "Organisation"
              }`}
              headerClassName={(index) =>
                index === 1 ? "bg-success text-white" : ""
              }
            >
              {clubs ? (
                <>
                  <div className="my-3" id="multiSelectedClubDiv">
                    {(clubs || brand?.length > 0) && (
                      <Dropdown
                        value={selectedClubs}
                        // options={
                        //   decoded?.designation === "SystemAdmin"
                        //     ? brand?.map((brand) => ({
                        //         name: brand?.brandName,
                        //         value: brand?.id,
                        //       }))
                        //     : clubs
                        // }
                        options={
                          decoded?.designation === "SystemAdmin"
                            ? filteredOptions(
                                brand?.map((brand) => ({
                                  name: brand?.brandName,
                                  value: brand?.id,
                                }))
                              )
                            : filteredOptions(clubs)
                        }
                        onChange={(e) => {
                          setSelectedClubs(e.value);
                          setSelectedBrandId(e.value);

                          if (e.value === undefined) {
                            setUserName("");
                            setUserEmail("");
                            setBrandName("");
                            setLogos({
                              large: null,
                              medium: null,
                              small: null,
                              favicon: null,
                            });
                            setThemeColors({
                              primary: "#006B01",
                              // secondary: "#6c757d",
                              // hover: "#28a745",
                            });
                            setHeaderImage("");
                          } else {
                            if (decoded?.designation !== "SystemAdmin") {
                              dispatch(
                                actions.getBrandTheme({
                                  organizationId: e?.value,
                                  // tab: 1,
                                })
                              );

                              // setClubLogos(clubTheme?.logos || {});
                              // setClubThemeColors(clubTheme?.themeColors || {});
                              // setClubHeaderImage(clubTheme?.headerImage || "");
                            }

                            if (decoded?.designation === "SystemAdmin") {
                              const brandData = brand?.filter(
                                (b) => b?.id === e?.value
                              )[0];

                              if (brandData) {
                                setUserName(brandData?.name || "");
                                setUserEmail(brandData?.email || "");
                                setBrandName(brandData?.brandName || "");
                                setLogos(
                                  brandData?.logos || {
                                    large: null,
                                    medium: null,
                                    small: null,
                                    favicon: null,
                                  }
                                );
                                setThemeColors(
                                  brandData?.themeColors || {
                                    primary: "#006B01",
                                    // secondary: "#6c757d",
                                    // hover: "#28a745",
                                  }
                                );
                                setHeaderImage(brandData?.headerImage || "");
                              }
                            }
                          }
                        }}
                        optionLabel="name"
                        placeholder={`Please select ${
                          decoded?.designation === "SystemAdmin"
                            ? "Brand"
                            : "Club"
                        }`}
                        filter
                        filterBy="name"
                        className="dropdown-custom dropdown-custom-theme"
                        showClear
                      />
                    )}
                  </div>

                  {selectedClubs && (
                    <div className="mb-3">
                      {userName && (
                        <div className="mb-1">
                          <h6 className="mb-0">Name:</h6>{" "}
                          <p className="mb-0">{userName}</p>
                        </div>
                      )}
                      {userEmail && (
                        <div className="">
                          <h6 className="mb-0">Email:</h6>
                          <p className="mb-0">{userEmail}</p>
                        </div>
                      )}
                    </div>
                  )}

                  {selectedClubs && (
                    <BrandTheme
                      logos={logos}
                      setLogos={setLogos}
                      brandName={brandName}
                      setBrandName={setBrandName}
                      editMode={editMode}
                      headerImage={headerImage}
                      setHeaderImage={setHeaderImage}
                      showBrandName={false}
                      hasChanges={hasChanges}
                      setHasChanges={setHasChanges}
                      activeTab={activeTab}
                      selectedClubs={selectedClubs}
                    />
                  )}
                </>
              ) : (
                <>
                  <div
                    className="text-center d-flex justify-content-center align-items-center"
                    style={{ height: "100%" }}
                  >
                    <h4>No organisation found</h4>
                  </div>
                </>
              )}
            </TabPanel>
          </TabView>
        </div>

        {((clubs?.length > 0 && activeTab === 1 && selectedClubs) ||
          activeTab === 0) && (
          <form onSubmit={handleSubmit}>
            <div className="row">
              {Object.entries(themeColors || {}).map(
                ([colorName, colorValue]) => (
                  <div className="col-lg-3 col-md-4 mb-3" key={colorName}>
                    <div className="form-group">
                      <label className="form-label text-capitalize">
                        {colorName}
                      </label>
                      <div className="d-flex align-items-center">
                        {editMode ? (
                          <>
                            <input
                              type="color"
                              name={colorName}
                              value={colorValue}
                              onChange={handleColorChange}
                              className="form-control me-2"
                            />
                            <input
                              type="text"
                              name={colorName}
                              value={colorValue}
                              onChange={handleColorChange}
                              className="form-control"
                            />
                          </>
                        ) : (
                          <div
                            className="color-preview"
                            style={{
                              backgroundColor: colorValue,
                              width: "30px",
                              height: "30px",
                              marginRight: "10px",
                            }}
                          ></div>
                        )}
                        {!editMode && <span>{colorValue}</span>}
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
            {hasChanges[`tab${activeTab}`] && (
              <button
                type="submit"
                className="btn btn-primary mt-3 save-theme-btn"
                // style={{
                //   backgroundColor: brandTheme?.themeColors?.primary
                //     ? brandTheme?.themeColors?.primary
                //     : "#006b01",
                //   border: "none",
                // }}
              >
                {networkProgressDialog ? (
                  <>
                    <div className="loader w-100" id="loader">
                      <div className="text-center d-flex">
                        <div
                          className="spinner-border"
                          role="status"
                          style={{ width: "20px", height: "20px" }}
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>Save Theme</>
                )}
              </button>
            )}
          </form>
        )}
      </div>
    </div>
  );
};

export default Theme;
