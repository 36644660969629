import setToken from "utils/setToken";
import { actions } from "./action";

const initialState = {
  brandAdmin: null,
  brandTheme: null,
  // clubTheme: null,
  error: "",
};

const brandReducer = (state = initialState, action) => {
  const token = localStorage.getItem("TOKEN");
  if (token) {
    setToken(token);
  }

  switch (action.type) {
    case actions.REGISTER_BRAND_ADMIN_SUCCESS:
      return {
        ...state,
        brandAdmin: action.payload,
      };
    case actions.CATCH_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case actions.CREATE_BRAND_THEME_SUCCESS:
      return {
        ...state,
        brandTheme: action.payload,
      };
    case actions.GET_BRAND_THEME_SUCCESS:
      return {
        ...state,
        brandTheme: action.payload,
      };
    // if (action.payload.tab === 0) {
    //   return {
    //     ...state,
    //     brandTheme: action.payload.brand,
    //   };
    // } else {
    //   return {
    //     ...state,
    //     clubTheme: action.payload.brand,
    //   };
    // }

    case actions.GET_BRAND_SUCCESS:
      return {
        ...state,
        brand: action.payload,
      };

    default:
      return state;
  }
};

export default brandReducer;
