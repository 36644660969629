// import React, { useRef, useState, useEffect } from "react";
// import "./BrandTheme.css";
// import Input from "components/Input";
// import Icons from "assets/icons/Icons";

// const BrandTheme = ({
//   logos,
//   setLogos,
//   brandName,
//   setBrandName,
//   editMode,
//   headerImage,
//   setHeaderImage,
//   showBrandName,
//   hasChanges,
//   setHasChanges,
//   activeTab,
// }) => {
//   const [logoPreviews, setLogoPreviews] = useState({
//     large: null,
//     medium: null,
//     small: null,
//     favicon: null,
//   });

//   const fileInputRefs = {
//     large: useRef(null),
//     medium: useRef(null),
//     small: useRef(null),
//     favicon: useRef(null),
//     header: useRef(null),
//   };

//   const logoSizes = {
//     large: { width: 512, height: 512, label: "Logo" },
//     medium: { width: 256, height: 256, label: "Logo" },
//     small: { width: 128, height: 128, label: "Logo" },
//     favicon: { width: 64, height: 64, label: "Favicon" },
//     header: { width: 8000, height: 417, label: "Header Image" },
//   };

//   useEffect(() => {
//     // Update logo previews when logos change
//     if (logos) {
//       setLogoPreviews({
//         ...logoPreviews,
//         large: null,
//         medium: null,
//         small: null,
//         favicon: null,
//       });
//       Object.keys(logos).forEach((size) => {
//         if (logos[size]) {
//           if (logos[size] instanceof File) {
//             const reader = new FileReader();
//             reader.onloadend = () => {
//               setLogoPreviews((prev) => ({
//                 ...prev,
//                 [size]: reader.result,
//               }));
//             };
//             reader.readAsDataURL(logos[size]);
//           } else if (typeof logos[size] === "string") {
//             // If it's already a URL, use it directly
//             setLogoPreviews((prev) => ({
//               ...prev,
//               [size]: logos[size],
//             }));
//           }
//         } else {
//           // If logos[size] is null, set a default value or placeholder
//           setLogoPreviews((prev) => ({
//             ...prev,
//             [size]: null, // or you could set a default placeholder image URL here
//           }));
//         }
//       });
//     }
//   }, [logos]);

//   useEffect(() => {
//     // Update header image previews when headerImage changes
//     if (headerImage) {
//       setLogoPreviews((prev) => ({
//         ...prev,
//         header: headerImage,
//       }));
//     }
//   }, [headerImage]);

//   // const handleFileChange = (e, size) => {
//   //   if (!editMode) return;
//   //   const file = e.target.files[0];
//   //   if (file) {
//   //     const img = new Image();
//   //     img.onload = () => {
//   //       if (
//   //         img.width === logoSizes[size].width &&
//   //         img.height === logoSizes[size].height
//   //       ) {
//   //         setLogos((prev) => ({
//   //           ...prev,
//   //           [size]: file,
//   //         }));

//   //         if (size === "header") {
//   //           const reader = new FileReader();
//   //           reader.onloadend = () => {
//   //             setHeaderImage(reader.result);
//   //           };
//   //           reader.readAsDataURL(file);
//   //         } else {
//   //           const reader = new FileReader();
//   //           reader.onloadend = () => {
//   //             setLogoPreviews((prev) => ({
//   //               ...prev,
//   //               [size]: reader.result,
//   //             }));
//   //           };
//   //           reader.readAsDataURL(file);
//   //         }
//   //       } else {
//   //         alert(
//   //           `Please upload a ${logoSizes[size].width}x${logoSizes[size].height} image for ${logoSizes[size].label}.`
//   //         );
//   //       }
//   //     };
//   //     img.src = URL.createObjectURL(file);
//   //   }
//   // };

//   // const handleFileChange = (e, size) => {
//   //   if (!editMode) return;
//   //   const file = e.target.files[0];
//   //   if (file) {
//   //     const img = new Image();
//   //     img.onload = () => {
//   //       if (
//   //         img.width === logoSizes[size].width &&
//   //         img.height === logoSizes[size].height
//   //       ) {
//   //         setLogos((prev) => ({
//   //           ...prev,
//   //           [size]: file,
//   //         }));

//   //         const reader = new FileReader();
//   //         reader.onloadend = () => {
//   //           setLogoPreviews((prev) => ({
//   //             ...prev,
//   //             [size]: reader.result,
//   //           }));
//   //         };
//   //         reader.readAsDataURL(file);

//   //         // Automatically resize for other sizes if the uploaded image is 512x512
//   //         if (size === "large") {
//   //           const canvas = document.createElement("canvas");
//   //           const ctx = canvas.getContext("2d");

//   //           const resizeAndSetPreview = (newSize) => {
//   //             canvas.width = logoSizes[newSize].width;
//   //             canvas.height = logoSizes[newSize].height;
//   //             ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
//   //             const resizedDataUrl = canvas.toDataURL("image/png");

//   //             // Update both logoPreviews and logos state
//   //             setLogoPreviews((prev) => ({
//   //               ...prev,
//   //               [newSize]: resizedDataUrl,
//   //             }));
//   //             setLogos((prev) => ({
//   //               ...prev,
//   //               [newSize]: resizedDataUrl,
//   //             }));
//   //           };

//   //           resizeAndSetPreview("medium");
//   //           resizeAndSetPreview("small");
//   //           resizeAndSetPreview("favicon");
//   //         }
//   //         setHasChanges((prevChanges) => ({
//   //           ...prevChanges,
//   //           [`tab${activeTab}`]: true,
//   //         }));
//   //       } else {
//   //         alert(
//   //           `Please upload a ${logoSizes[size].width}x${logoSizes[size].height} image for ${logoSizes[size].label}.`
//   //         );
//   //       }
//   //     };
//   //     img.src = URL.createObjectURL(file);
//   //   }
//   // };

//   const handleFileChange = (e, size) => {
//     if (!editMode) return;
//     const file = e.target.files[0];
//     if (file) {
//       const img = new Image();
//       img.onload = () => {
//         if (
//           img.width === logoSizes[size].width &&
//           img.height === logoSizes[size].height
//         ) {
//           setLogos((prev) => ({
//             ...prev,
//             [size]: file,
//           }));

//           const reader = new FileReader();
//           reader.onloadend = () => {
//             setLogoPreviews((prev) => ({
//               ...prev,
//               [size]: reader.result,
//             }));
//             if (size === "header") {
//               setHeaderImage(reader.result); // Ensure headerImage is updated
//             }
//           };
//           reader.readAsDataURL(file);

//           // Automatically resize for other sizes if the uploaded image is 512x512
//           if (size === "large") {
//             const canvas = document.createElement("canvas");
//             const ctx = canvas.getContext("2d");

//             const resizeAndSetPreview = (newSize) => {
//               canvas.width = logoSizes[newSize].width;
//               canvas.height = logoSizes[newSize].height;
//               ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
//               const resizedDataUrl = canvas.toDataURL("image/png");

//               // Update both logoPreviews and logos state
//               setLogoPreviews((prev) => ({
//                 ...prev,
//                 [newSize]: resizedDataUrl,
//               }));
//               setLogos((prev) => ({
//                 ...prev,
//                 [newSize]: resizedDataUrl,
//               }));
//             };

//             resizeAndSetPreview("medium");
//             resizeAndSetPreview("small");
//             resizeAndSetPreview("favicon");
//           }
//           setHasChanges((prevChanges) => ({
//             ...prevChanges,
//             [`tab${activeTab}`]: true,
//           }));
//         } else {
//           alert(
//             `Please upload a ${logoSizes[size].width}x${logoSizes[size].height} image for ${logoSizes[size].label}.`
//           );
//         }
//       };
//       img.src = URL.createObjectURL(file);
//     }
//   };

//   const renderLogoInput = (size, dimensions, label) => (
//     <div className="mb-3">
//       <label htmlFor={`${size}Upload`} className="form-label">
//         {label} ({dimensions.width}x{dimensions.height})
//       </label>
//       <div className="d-flex align-items-center gap-5">
//         <div
//           className={`imgBox rounded p-2 me-3 ${
//             editMode ? "cursor-pointer" : ""
//           }`}
//           style={{ width: "100px", height: "100px" }}
//           onClick={() => editMode && fileInputRefs[size].current.click()}
//         >
//           {logoPreviews[size] ? (
//             <img
//               src={logoPreviews[size]}
//               alt={`${label} preview`}
//               className="img-fluid"
//               style={{
//                 maxWidth: "100%",
//                 maxHeight: "100%",
//                 objectFit: "contain",
//               }}
//             />
//           ) : (
//             <div className="d-flex justify-content-center align-items-center h-100">
//               {editMode ? <Icons name="upload" /> : <span>No image</span>}
//             </div>
//           )}
//         </div>
//         {editMode && (
//           <input
//             type="file"
//             className="d-none"
//             id={`${size}Upload`}
//             accept="image/*"
//             onChange={(e) => handleFileChange(e, size)}
//             ref={fileInputRefs[size]}
//           />
//         )}
//       </div>
//     </div>
//   );

//   const handleBrandNameChange = (e) => {
//     setBrandName(e.target.value);
//     setHasChanges((prevChanges) => ({
//       ...prevChanges,
//       [`tab${activeTab}`]: true,
//     }));
//   };

//   return (
//     <div className="brand-theme-container">
//       <form>
//         {showBrandName && (
//           <div className="mb-3">
//             <Input
//               type="text"
//               className="inputField mt-2 px-2"
//               id="brandName"
//               label="Brand Name"
//               name="name"
//               divStyle={{ fontSize: "18px" }}
//               value={brandName}
//               onChange={handleBrandNameChange}
//               readOnly={!editMode}
//             />
//           </div>
//         )}

//         <div className="d-flex flex-wrap gap-3">
//           {renderLogoInput("large", logoSizes.large, logoSizes.large.label)}
//           {renderLogoInput("medium", logoSizes.medium, logoSizes.medium.label)}
//           {renderLogoInput("small", logoSizes.small, logoSizes.small.label)}
//           {renderLogoInput(
//             "favicon",
//             logoSizes.favicon,
//             logoSizes.favicon.label
//           )}
//         </div>
//         <div className="mb-3">
//           <label htmlFor={`headerUpload`} className="form-label">
//             Header Image (8000x417)
//           </label>
//           <div className="d-flex align-items-center gap-5">
//             <div
//               className={`imgBox rounded p-2 me-3 ${
//                 editMode ? "cursor-pointer" : ""
//               }`}
//               style={headerImage ? {} : { width: "100px", height: "100px" }}
//               onClick={() =>
//                 editMode && fileInputRefs["header"].current.click()
//               }
//             >
//               {headerImage ? (
//                 // eslint-disable-next-line
//                 <img
//                   src={logoPreviews["header"]}
//                   alt="Header Image preview"
//                   className="img-fluid"
//                   style={{
//                     maxWidth: "100%",
//                     maxHeight: "100%",
//                     objectFit: "contain",
//                   }}
//                 />
//               ) : (
//                 <div className="d-flex justify-content-center align-items-center h-100">
//                   {editMode ? <Icons name="upload" /> : <span>No image</span>}
//                 </div>
//               )}
//             </div>
//             {editMode && (
//               <input
//                 type="file"
//                 className="d-none"
//                 id={`headerUpload`}
//                 accept="image/*"
//                 onChange={(e) => handleFileChange(e, "header")}
//                 ref={fileInputRefs["header"]}
//               />
//             )}
//           </div>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default BrandTheme;

import React, { useRef, useState, useEffect, useCallback } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "./BrandTheme.css";
import Input from "components/Input";
import Icons from "assets/icons/Icons";
import { Dialog } from "primereact/dialog";

const BrandTheme = ({
  logos,
  setLogos,
  brandName,
  setBrandName,
  editMode,
  headerImage,
  setHeaderImage,
  showBrandName,
  hasChanges,
  setHasChanges,
  activeTab,
  selectedClubs,
}) => {
  const [logoPreviews, setLogoPreviews] = useState({
    large: null,
    medium: null,
    small: null,
    favicon: null,
  });

  const fileInputRefs = {
    large: useRef(null),
    medium: useRef(null),
    small: useRef(null),
    favicon: useRef(null),
    header: useRef(null),
  };

  const logoSizes = {
    large: { width: 512, height: 512, label: "Logo" },
    medium: { width: 256, height: 256, label: "Logo" },
    small: { width: 128, height: 128, label: "Logo" },
    favicon: { width: 64, height: 64, label: "Favicon" },
    header: { width: 8000, height: 417, label: "Header Image" },
  };

  const [crop, setCrop] = useState({
    unit: "%",
    width: 30,
    aspect: 1 / 1,
    maxWidth: 100,
    maxHeight: 100,
    minWidth: 100,
    minHeight: 100,
  });

  const [croppedImageUrl, setCroppedImageUrl] = useState();
  const [imageToCrop, setImageToCrop] = useState(null);
  const [currentSize, setCurrentSize] = useState(null); // New state to track the current size
  const [openCropModal, setOpenCropModal] = useState(false);

  useEffect(() => {
    if (logos) {
      setLogoPreviews({
        ...logoPreviews,
        large: null,
        medium: null,
        small: null,
        favicon: null,
      });
      Object.keys(logos).forEach((size) => {
        if (logos[size]) {
          if (logos[size] instanceof File) {
            const reader = new FileReader();
            reader.onloadend = () => {
              setLogoPreviews((prev) => ({
                ...prev,
                [size]: reader.result,
              }));
            };
            reader.readAsDataURL(logos[size]);
          } else if (typeof logos[size] === "string") {
            setLogoPreviews((prev) => ({
              ...prev,
              [size]: logos[size],
            }));
          }
        } else {
          setLogoPreviews((prev) => ({
            ...prev,
            [size]: null,
          }));
        }
      });
    }
  }, [logos]);

  useEffect(() => {
    if (headerImage) {
      setLogoPreviews((prev) => ({
        ...prev,
        header: headerImage,
      }));
    }
  }, [headerImage]);

  // const handleFileChange = (e, size) => {
  //   if (!editMode) return;
  //   const file = e.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setImageToCrop(reader.result);
  //       setCurrentSize(size); // Set the current size
  //       setOpenCropModal(true);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  // const handleFileChange = (e, size) => {
  //   if (!editMode) return;
  //   const file = e.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setImageToCrop(reader.result); // Set the image to be cropped
  //       setCurrentSize(size); // Track the current size
  //       setOpenCropModal(true); // Open the crop modal
  //       // setCrop({
  //       //   maxWidth: size === "header" ? 8000 : null,
  //       //   maxHeight: size === "header" ? 417 : null,
  //       // });
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  const handleFileChange = (e, size) => {
    // this allows input to be able to choose the same file

    if (!editMode) return;
    const file = e.target.files[0];
    if (file) {
      const img = new Image();
      img.onload = () => {
        if (size === "header") {
          if (img.width !== 8000 || img.height !== 417) {
            alert("Please upload an 8000x417 image for the Header.");
            return;
          }
          const reader = new FileReader();
          reader.onloadend = () => {
            const headerImageUrl = reader.result;
            setHeaderImage(headerImageUrl);
            setLogoPreviews((prev) => ({
              ...prev,
              header: headerImageUrl,
            }));
            setHasChanges((prevChanges) => ({
              ...prevChanges,
              [`tab${activeTab}`]: true,
            }));
          };
          reader.readAsDataURL(file);
        } else {
          const reader = new FileReader();
          reader.onloadend = () => {
            setImageToCrop(reader.result); // Set the image to be cropped
            setCurrentSize(size); // Track the current size
            setOpenCropModal(true); // Open the crop modal
          };
          reader.readAsDataURL(file);
        }
      };
      img.src = URL.createObjectURL(file);
    }
  };

  const onCropComplete = async (crop) => {
    if (imageToCrop && crop.width && crop.height) {
      const image = new Image();
      image.src = imageToCrop;
      image.onload = async () => {
        const croppedImage = await getCroppedImg(
          image,
          crop,
          "brand-theme-" + new Date().getTime() + ".jpg"
        );

        // Set the cropped image URL
        setCroppedImageUrl(URL.createObjectURL(croppedImage));

        // Handle logos and header image separately
        if (currentSize === "header") {
          // Update header image state
          setHeaderImage(URL.createObjectURL(croppedImage));
          setLogoPreviews((prev) => ({
            ...prev,
            header: URL.createObjectURL(croppedImage),
          }));
        } else {
          // Automatically resize and set image for all logo sizes
          const resizeAndSetImages = (croppedImage) => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            Object.keys(logoSizes).forEach((key) => {
              if (key !== "header") {
                // Skip header for logo resizing
                const { width, height } = logoSizes[key];
                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(croppedImage, 0, 0, width, height);
                const resizedDataUrl = canvas.toDataURL("image/png");

                // Update both logoPreviews and logos state
                setLogoPreviews((prev) => ({
                  ...prev,
                  [key]: resizedDataUrl,
                }));
                setLogos((prev) => ({
                  ...prev,
                  [key]: resizedDataUrl,
                }));
              }
            });
          };

          const croppedImageElement = new Image();
          croppedImageElement.src = URL.createObjectURL(croppedImage);
          croppedImageElement.onload = () => {
            resizeAndSetImages(croppedImageElement);
            setHasChanges((prevChanges) => ({
              ...prevChanges,
              [`tab${activeTab}`]: true,
            }));
          };
        }
      };
    }
  };

  // const onCropComplete = async (crop) => {
  //   if (imageToCrop && crop.width && crop.height) {
  //     const image = new Image();
  //     image.src = imageToCrop;
  //     image.onload = async () => {
  //       const croppedImage = await getCroppedImg(
  //         image,
  //         crop,
  //         "brand-theme-" + new Date().getTime() + ".jpg"
  //       );

  //       setCroppedImageUrl(URL.createObjectURL(croppedImage));
  //     };
  //   }
  // };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (blob) {
          const fileType = blob.type.split("/")[1] || "png";
          const fileName =
            "brand-theme-" + new Date().getTime() + "." + fileType;
          const file = new File([blob], fileName, {
            type: blob.type,
          });
          resolve(file);
        }
      });
    });
  };

  const renderLogoInput = (size, dimensions, label) => (
    <div className="mb-3">
      <label htmlFor={`${size}Upload`} className="form-label">
        {/* {label} ({dimensions.width}x{dimensions.height}) */}
        {label}
      </label>
      <div className="d-flex align-items-center gap-5">
        <div
          className={`imgBox rounded p-2 me-3 ${
            editMode ? "cursor-pointer" : ""
          }`}
          style={{ width: "100px", height: "100px" }}
          onClick={() => editMode && fileInputRefs[size].current.click()}
        >
          {logoPreviews[size] ? (
            <img
              // key={Date.now()}
              key={`logo-${selectedClubs}`}
              src={
                logoPreviews[size]?.includes("blob.core.windows.net")
                  ? `${logoPreviews[size]}?${Date.now()}`
                  : logoPreviews[size]
              }
              alt={`${label} preview`}
              className="img-fluid"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
              }}
            />
          ) : (
            <div className="d-flex justify-content-center align-items-center h-100">
              {editMode ? <Icons name="upload" /> : <span>No image</span>}
            </div>
          )}
        </div>
        {editMode && (
          <input
            type="file"
            className="d-none"
            id={`${size}Upload`}
            accept="image/*"
            onChange={(e) => handleFileChange(e, size)}
            onClick={(event) => {
              // this allows input to be able to choose the same file
              event.target.value = null;
            }}
            ref={fileInputRefs[size]}
          />
        )}
      </div>
    </div>
  );

  const handleBrandNameChange = (e) => {
    setBrandName(e.target.value);
    setHasChanges((prevChanges) => ({
      ...prevChanges,
      [`tab${activeTab}`]: true,
    }));
  };

  const renderFooter = (name) => {
    return (
      <div>
        <button
          className="btn btn-secondary"
          onClick={() => setOpenCropModal(false)}
        >
          Cancel
        </button>
        <button
          className="btn btn-success"
          onClick={() => {
            if (croppedImageUrl) {
              const croppedImageElement = new Image();
              croppedImageElement.src = croppedImageUrl;
              croppedImageElement.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                if (currentSize === "header") {
                  // Update only the header image state
                  setHeaderImage(croppedImageUrl);
                  setLogoPreviews((prev) => ({
                    ...prev,
                    header: croppedImageUrl,
                  }));
                } else {
                  // Update only the logo images
                  const { width, height } = logoSizes[currentSize];
                  canvas.width = width;
                  canvas.height = height;
                  ctx.drawImage(croppedImageElement, 0, 0, width, height);
                  const resizedDataUrl = canvas.toDataURL("image/png");

                  // Update both logoPreviews and logos state for the current size
                  setLogoPreviews((prev) => ({
                    ...prev,
                    [currentSize]: resizedDataUrl,
                  }));
                  setLogos((prev) => ({
                    ...prev,
                    [currentSize]: resizedDataUrl,
                  }));
                }

                setHasChanges((prevChanges) => ({
                  ...prevChanges,
                  [`tab${activeTab}`]: true,
                }));

                // Ensure the modal closes after processing
                setOpenCropModal(false);
              };
            }
          }}
        >
          Save
        </button>
      </div>
    );
  };

  return (
    <div className="brand-theme-container">
      <form>
        {showBrandName && (
          <div className="mb-3">
            <Input
              type="text"
              className="inputField mt-2 px-2"
              id="brandName"
              label="Brand Name"
              name="name"
              divStyle={{ fontSize: "18px" }}
              value={brandName}
              onChange={handleBrandNameChange}
              readOnly={!editMode}
            />
          </div>
        )}

        <div className="d-flex flex-wrap gap-3">
          {renderLogoInput("large", logoSizes.large, logoSizes.large.label)}
          {/* {renderLogoInput("medium", logoSizes.medium, logoSizes.medium.label)}
          {renderLogoInput("small", logoSizes.small, logoSizes.small.label)}
          {renderLogoInput(
            "favicon",
            logoSizes.favicon,
            logoSizes.favicon.label
          )} */}
        </div>
        <div className="mb-3">
          <label htmlFor={`headerUpload`} className="form-label">
            Header Image (8000x417)
          </label>
          <div className="d-flex align-items-center gap-5">
            <div
              className={`imgBox rounded p-2 me-3 ${
                editMode ? "cursor-pointer" : ""
              }`}
              style={headerImage ? {} : { width: "100px", height: "100px" }}
              onClick={() =>
                editMode && fileInputRefs["header"].current.click()
              }
            >
              {headerImage ? (
                <img
                  key={`header-image-${selectedClubs}`}
                  src={
                    logoPreviews["header"]?.includes("blob.core.windows.net")
                      ? `${logoPreviews["header"]}?${Date.now()}`
                      : logoPreviews["header"]
                  }
                  alt="Header Image preview"
                  className="img-fluid"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <div className="d-flex justify-content-center align-items-center h-100">
                  {editMode ? <Icons name="upload" /> : <span>No image</span>}
                </div>
              )}
            </div>
            {editMode && (
              <input
                type="file"
                className="d-none"
                id={`headerUpload`}
                accept="image/*"
                onChange={(e) => handleFileChange(e, "header")}
                ref={fileInputRefs["header"]}
              />
            )}
          </div>
        </div>

        {imageToCrop && (
          <Dialog
            visible={openCropModal}
            onHide={() => setOpenCropModal(false)}
            footer={renderFooter("displayBasic")}
          >
            <ReactCrop
              src={imageToCrop}
              crop={crop}
              onChange={(newCrop) => setCrop(newCrop)}
              onComplete={onCropComplete}
            />
          </Dialog>
        )}
      </form>
    </div>
  );
};

export default BrandTheme;
