import Image from "components/Image";
import NavSidebar from "components/NavSidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import jwt_decode from "jwt-decode";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { actions } from "redux/User/action";
import {
  INSTITUTE_ID_ACADEMIC,
  INSTITUTE_ID_ACADEMIC_SPORT,
} from "utils/constants";
import "./Navbar.css";

function Navbar({ page }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const { user } = useSelector((state) => state.user);

  const token = localStorage.getItem("TOKEN");
  const [visible, setVisible] = useState(false);
  let decoded;
  if (token) {
    decoded = jwt_decode(token);
  }
  const LogOut = () => {
    dispatch(actions.userLogout(navigate));
    localStorage.removeItem("TOKEN");
  };

  const { brandTheme } = useSelector((state) => state.brand);

  // const headerImageUrl =
  //   brandTheme?.headerImage ||
  //   "https://magicalogical.co.uk/routes/routes_design_1/public/images/ControlCentreHeader.jpg";

  const headerImageUrl =
    user?.designation === "Routes Super Admin"
      ? "https://magicalogical.co.uk/routes/routes_design_1/public/images/ControlCentreHeader.jpg"
      : brandTheme?.headerImage ||
        "https://magicalogical.co.uk/routes/routes_design_1/public/images/ControlCentreHeader.jpg";

  return (
    <>
      <div id="controlHeader" className="fullWidth">
        <div className="header-image-wrapper">
          <Image
            id="headerImg"
            // src="https://magicalogical.co.uk/routes/routes_design_1/public/images/ControlCentreHeader.jpg"
            src={headerImageUrl}
            alt="routes"
          />
        </div>
        {page !== "login" && (
          <>
            <div
              id="logOut"
              // className="d-flex MainNavbar justify-content-center align-items-center"
              className={`d-flex ${
                user?.role === "admin" || decoded?.role === "admin"
                  ? "MainNavbar"
                  : "mainNavbarClubAdmin"
              }  justify-content-center align-items-center`}>
              {/* {(user?.role === "admin" || decoded?.role === "admin") && ( */}
              {
                <Link
                  to={`/`}
                  // className="text-white bebas allPlayersNav ms-4 d-flex homeIcon"
                  className={`text-white bebas allPlayersNav ms-4 d-flex mt-1 navbarLinkShow ${
                    location.pathname === "/" ? "homeIcon" : ""
                  }`}>
                  <FeatherIcon icon="home" size={20} className="mt-1 " />
                </Link>
              }

              {(user?.role === "admin" || decoded?.role === "admin") &&
                !params?.id && (
                  <Link
                    to="/players"
                    // className="text-white bebas allPlayersNav ms-4 active"
                    className={`text-white bebas allPlayersNav ms-4 navbarLinkShow
                      ${location?.pathname === "/players" ? "activeNavBar" : ""}
                    `}>
                    Users
                  </Link>
                )}

              {(user?.role === "admin" || decoded?.role === "admin") && (
                <Link
                  to="/courses"
                  // className="text-white bebas allPlayersNav ms-4"
                  className={`text-white bebas allPlayersNav ms-4 navbarLinkShow
                      ${location?.pathname === "/courses" ? "activeNavBar" : ""}
                    `}>
                  Organisations
                </Link>
              )}

              {(user?.role === "admin" || decoded?.role === "admin") && (
                <Link
                  to="/all-courses"
                  // className="text-white bebas allPlayersNav ms-4"
                  className={`text-white bebas allPlayersNav ms-4 navbarLinkShow
                      ${
                        location?.pathname === "/all-courses"
                          ? "activeNavBar"
                          : ""
                      }
                    `}>
                  Courses
                </Link>
              )}

              {(user?.role === "clubAdmin" ||
                decoded?.role === "clubAdmin" ||
                decoded?.role === "tutor" ||
                user?.role === "tutor") && (
                <Link
                  to="/players"
                  // className="text-white bebas allPlayersNav ms-4"
                  className={`text-white bebas allPlayersNav ms-4 
                      ${location?.pathname === "/players" ? "activeNavBar" : ""}
                    `}>
                  {user?.club?.instituteId === INSTITUTE_ID_ACADEMIC
                    ? "Learners"
                    : user?.club?.instituteId === INSTITUTE_ID_ACADEMIC_SPORT
                    ? "Players"
                    : "Users"}
                </Link>
              )}

              {(decoded?.role === "clubAdmin" ||
                user?.role === "clubAdmin") && (
                <Link
                  to="/club-admin"
                  // className="text-white bebas allPlayersNav ms-4"
                  className={`text-white bebas allPlayersNav ms-4 
                      ${
                        location?.pathname === "/club-admin"
                          ? "activeNavBar"
                          : ""
                      }
                    `}>
                  Mentors
                </Link>
              )}

              {(decoded?.role === "clubAdmin" ||
                user?.role === "clubAdmin") && (
                <Link
                  to="/organisation/common-user-profile"
                  // className="text-white bebas allPlayersNav ms-4"
                  className={`text-white bebas allPlayersNav ms-4 
                      ${
                        location?.pathname ===
                        "/organisation/common-user-profile"
                          ? "activeNavBar"
                          : ""
                      }
                    `}>
                  Profile Fields
                </Link>
              )}

              {/* {(decoded?.role === "clubAdmin" ||
                user?.role === "clubAdmin") && (
                <Link
                  to="/organisation/player-categories"
                  // className="text-white bebas allPlayersNav ms-4"
                  className={`text-white bebas allPlayersNav ms-4 
                      ${
                        location?.pathname === "/organisation/player-categories"
                          ? "activeNavBar"
                          : ""
                      }
                    `}>
                  {user?.club?.instituteId === INSTITUTE_ID_ACADEMIC
                    ? "Learner "
                    : user?.club?.instituteId === INSTITUTE_ID_ACADEMIC_SPORT
                    ? "Player "
                    : "User "}
                  Categories
                </Link>
              )} */}

              {(user?.role === "admin" || decoded?.role === "admin") &&
                params?.id && (
                  <Link
                    to={`${
                      user?.role === "admin" || decoded?.role === "admin"
                        ? "/club/info/" + params?.id
                        : "/club-admin"
                    }`}
                    // className="text-white bebas allPlayersNav ms-4"
                    className={`text-white bebas allPlayersNav ms-4 navbarLinkShow
                    ${
                      location?.pathname.includes("/club/info/")
                        ? "activeNavBar"
                        : ""
                    }
                  `}>
                    Mentors
                  </Link>
                )}

              {(user?.role === "admin" || decoded?.role === "admin") && (
                <div
                  onClick={() => setVisible(true)}
                  className="btn btn-outline-secondary ms-2  d-none NavbarMenuButton">
                  <FeatherIcon
                    icon="menu"
                    size={16}
                    className="navbarMenuIcon"
                  />
                </div>
              )}

              {(user?.role === "admin" || decoded?.role === "admin") && (
                <Link
                  to="/theme"
                  // className="text-white bebas allPlayersNav ms-4"
                  className={`text-white bebas allPlayersNav ms-4 navbarLinkShow
                      ${location?.pathname === "/theme" ? "activeNavBar" : ""}
                    `}>
                  Theme
                </Link>
              )}

              <NavSidebar visible={visible} setVisible={setVisible} />

              {/* <p id="logOut" onClick={LogOut}>
              Log Out
            </p> */}

              <div className="dropdownProfile ms-4">
                <div className="profileNav d-flex align-items-center justify-content-center">
                  <div className="profileDivNav rounded-circle">
                    <Image
                      src={
                        user && user?.image
                          ? user?.image
                          : "http://s3.amazonaws.com/37assets/svn/765-default-avatar.png"
                      }
                      alt="routes"
                      className="w-100 h-100 rounded-circle profileImg"
                    />
                  </div>
                  <div className="profileName ms-2">
                    <p className="profileName_p bebas">
                      {user && user?.name ? user?.name : ""}
                    </p>
                    <span className="profileNavDesignation alternate-gothic">
                      {user && user?.designation ? user?.designation : ""}
                    </span>
                  </div>
                </div>
                <div className="dropdown-content poppins">
                  <div>
                    <p className="fw-bold px-2">Signed as</p>
                    <span className="dropdownEmail px-2">
                      {user && user?.email ? user?.email : ""}
                    </span>
                  </div>

                  <hr className="divider mb-1" />

                  {(decoded?.role === "clubAdmin" ||
                    user?.role === "clubAdmin" ||
                    decoded?.role === "tutor" ||
                    user?.role === "tutor" ||
                    decoded?.role === "admin") && (
                    <div
                      onClick={() => navigate("/profile")}
                      className="d-flex align-items-center dropdown-nav-item p-2">
                      <FeatherIcon
                        icon="user"
                        size={15}
                        className="text-muted"
                      />
                      <p className="fw-bold ms-2">Profile </p>
                    </div>
                  )}

                  {decoded?.designation === "SystemAdmin" && (
                    <>
                      <hr className="divider my-1" />
                      <div
                        onClick={() => navigate("/register-admin")}
                        className="d-flex align-items-center dropdown-nav-item p-2">
                        <FeatherIcon
                          icon="user"
                          size={15}
                          className="text-muted"
                        />
                        <p className="fw-bold ms-2">Register Brand </p>
                      </div>
                    </>
                  )}

                  {(decoded?.role === "clubAdmin" ||
                    user?.role === "clubAdmin" ||
                    decoded?.role === "tutor" ||
                    user?.role === "tutor" ||
                    decoded?.role === "admin") && (
                    <hr className="divider mb-1 mt-1" />
                  )}

                  <div
                    onClick={LogOut}
                    className="d-flex align-items-center dropdown-nav-item p-2">
                    <FeatherIcon
                      icon="log-out"
                      size={15}
                      className="text-muted"
                    />
                    <p className="fw-bold ms-2">Log Out </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Navbar;
