import React from "react";
import Image from "components/Image";
import Heading from "components/Heading";
import plus1 from "assets/images/plus1.png";
import Dropdown from "components/Dropdown";
import default_club from "assets/images/default_club.png";
import AddClub from "components/AddClub";
import AddTutor from "components/AddTutor";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// import "./Card.css";
import jwt_decode from "jwt-decode";

const Card = (props) => {
  const {
    image,
    clubImage,
    name,
    clubName,
    playerStatus,
    position,
    designation,
    menuIcon,
    cardType,
    optionList,
    label,
    form,
    club,
    setClubDataUpdate,
    clubDataUpdate,
    tutor,
    tutorDataUpdate,
    setTutorDataUpdate,
    player,
  } = props;
  const navigate = useNavigate();

  function datediff(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }
  const { user } = useSelector((state) => state.user);
  let token = localStorage.getItem("TOKEN");

  let decoded;
  if (token) {
    decoded = jwt_decode(token);
  }

  return (
    <>
      {cardType === "addItemCard" && (
        <>
          <div className="px-lg-4 px-md-3 px-xs-2 pb-5 card_div">
            <div
              className="card border-0"
              data-bs-toggle="modal"
              data-bs-target="#myModalAdd">
              {/* <div className="card-body p-4 cardBodyAddCard">
              <Image src={plus1} className="img-fluid w-50 m-auto" />

              <div>
                <Heading
                  type="h5"
                  className="mb-0 text-green pt-1 poppins fw-bold text-truncate text-center text-dark"
                  label="Add New Club"
                />
              </div>
            </div> */}
              <div className="card-body p-4">
                <div className="text-black">
                  <div
                    className={`flex-shrink-0 d-flex img-wrapper align-items-center`}>
                    <Image
                      src={plus1}
                      alt=""
                      className="img-fluid w-100 club_image_card cardBodyAddCard"
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                  <div className="row w-100">
                    <Heading
                      type="h5"
                      className={`mb-0 txt-primary pt-1 poppins fw-bold text-truncate text-center text-dark`}
                      label={label}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="myModalAdd"
            data-bs-keyboard="false"
            data-bs-backdrop="static">
            <div
              className={`modal-dialog modal-dialog-centered ${
                form === "AddTutor" ? "modal-lg" : ""
              }`}>
              <div className="modal-content">
                {form === "AddTutor" ? <AddTutor /> : <AddClub />}
              </div>
            </div>
          </div>
        </>
      )}

      {cardType === "playerCard" && (
        <div className="px-lg-4 px-md-3 px-xs-2 pb-5 card_div">
          <div className={`card border-0`}>
            {optionList && (
              <div className="card-header bg-white border-0 pb-0 px-4">
                <div className={`d-flex justify-content-end`}>
                  {menuIcon && (
                    <Dropdown
                      icon="more-horizontal"
                      list={optionList}
                      player={player}
                      // setClubDataUpdate={setClubDataUpdate}
                      isArchive={player?.isArchived ? true : false}
                    />
                  )}
                </div>
              </div>
            )}

            <div
              className={`card-body p-4 ${optionList ? "pt-0" : ""}`}
              onClick={() => {
                if (decoded?.role === "admin") {
                  navigate(`/club/dashboard/${player?.club?.id}`, {
                    state: { player },
                  });
                } else {
                  navigate(`/`, {
                    state: { player },
                  });
                }
              }}>
              <div className="text-black">
                <div
                  className={`flex-shrink-0 d-flex img-wrapper align-items-center`}>
                  <Image
                    src={
                      image
                        ? `${image}`
                        : "http://s3.amazonaws.com/37assets/svn/765-default-avatar.png"
                    }
                    alt=""
                    className={`img-fluid w-100 profile-img`}
                    style={{ borderRadius: "10px" }}
                  />
                </div>
                {clubImage && (
                  <div className="d-flex justify-content-start club-wrapper">
                    <Image
                      src={clubImage}
                      alt={clubName}
                      style={{ width: "75px", height: "75px" }}
                      className="img-circle img-fluid rounded-circle border border-white border-5"
                    />
                    <p className="status ms-1 txt-primary poppins fw-bold">
                      {playerStatus ? playerStatus?.name : ""}
                    </p>
                  </div>
                )}
                <div className="row w-100">
                  <Heading
                    type="h5"
                    className={`mb-0 txt-primary pt-1 poppins fw-bold text-truncate`}
                    label={name ? name : ""}
                  />
                  {clubName && (
                    <p className="mb-1 fw-bolder poppins text-truncate">
                      {clubName && clubName}
                    </p>
                  )}
                  {position && (
                    <p className="poppins text-truncate">
                      {position ? position : ""}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {cardType === "tutorCard" && (
        <div className="px-lg-4 px-md-3 px-xs-2 pb-5 tutor_card_div">
          <div
            className={`card border-0 clubCard ${tutor?.isDelete ? "" : ""}`}>
            <div className="card-body p-0 pb-3">
              <div className="text-black">
                {datediff(new Date(tutor?.createdAt), new Date()) <= 2 && (
                  <div className="badgeDiv">
                    <span className="badge bg-success">New</span>
                  </div>
                )}
                {tutor?.role && (
                  <div
                    className="badgeDiv"
                    style={{
                      left:
                        datediff(new Date(tutor?.createdAt), new Date()) <= 2
                          ? "52.5px"
                          : "4.5px",
                    }}>
                    <span
                      className={`badge ${
                        tutor?.role === "clubAdmin" ? "bg-danger" : "bg-primary"
                      }`}>
                      {tutor?.role === "clubAdmin" ? "Club Admin" : "Tutor"}
                    </span>
                  </div>
                )}

                {menuIcon && (
                  <Dropdown
                    icon="more-vertical"
                    list={optionList}
                    className="tutorDropdown"
                    tutor={
                      tutorDataUpdate?.id === tutor?.id
                        ? tutorDataUpdate
                        : tutor
                    }
                    setTutorDataUpdate={setTutorDataUpdate}
                    isArchive={tutor?.isDelete}
                  />
                )}

                <div
                  className={`flex-shrink-0 d-flex align-items-center tutorImgDiv`}>
                  <Image
                    src={
                      image
                        ? tutorDataUpdate?.id === tutor?.id
                          ? tutorDataUpdate?.image
                            ? tutorDataUpdate?.image
                            : image
                          : image
                        : "http://s3.amazonaws.com/37assets/svn/765-default-avatar.png"
                    }
                    alt=""
                    className={`img-fluid w-100 h-100`}
                    style={{ borderRadius: "10px 10px 0 0" }}
                  />
                </div>
                <div className="w-100 mt-2">
                  <Heading
                    type="h5"
                    className={`mb-0 txt-primary pt-1 poppins fw-bold text-truncate text-center`}
                    // label={
                    //   name && tutorDataUpdate?.id === tutor?.id
                    //     ? tutorDataUpdate?.name
                    //       ? tutorDataUpdate?.name
                    //       : name
                    //     : name
                    //     ? name
                    //     : tutor?.email
                    // }
                    label={
                      // Check if tutorDataUpdate exists and matches the current tutor
                      tutorDataUpdate?.id === tutor?.id
                        ? // If tutorDataUpdate has a name, use it; otherwise, fallback to the original name
                          tutorDataUpdate?.name || name || tutor?.email
                        : // If no update, use the original name or fallback to email
                          name || tutor?.email
                    }
                  />
                  {/* {clubName && (
                    <p className="mb-1 fw-bolder poppins text-truncate">
                      {clubName && clubName}
                    </p>
                  )} */}
                  {
                    <p className="poppins text-truncate text-center">
                      {/* {designation
                        ? tutorDataUpdate?.id === tutor?.id
                          ? tutorDataUpdate?.designation
                            ? tutorDataUpdate?.designation
                            : designation
                          : designation
                        : ""} */}

                      {
                        // Check if tutorDataUpdate exists and matches the current tutor
                        tutorDataUpdate?.id === tutor?.id
                          ? // If tutorDataUpdate has a designation, use it; otherwise, fallback to the original designation
                            tutorDataUpdate?.designation || designation
                          : // If no update, use the original designation
                            designation || ""
                      }
                    </p>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {cardType === "clubCard" && (
        <div className="px-lg-4 px-md-3 px-xs-2 pb-5 card_div">
          <div
            className={`card border-0 clubCard ${!club?.isEnable ? "" : ""}`}>
            <div className="card-header bg-white border-0 pb-0">
              <div
                className={`d-flex ${
                  datediff(new Date(club?.createdAt), new Date()) <= 2
                    ? "justify-content-between"
                    : "justify-content-end"
                }`}>
                {datediff(new Date(club?.createdAt), new Date()) <= 2 && (
                  <div>
                    <span className="badge background-primary">New</span>
                  </div>
                )}
                {menuIcon && (
                  <Dropdown
                    icon="more-horizontal"
                    list={optionList}
                    club={
                      clubDataUpdate?.id === club?.id ? clubDataUpdate : club
                    }
                    setClubDataUpdate={setClubDataUpdate}
                    isArchive={!club?.isEnable ? true : false}
                  />
                )}
              </div>
            </div>
            <div
              className="card-body p-4 pt-0"
              onClick={() => {
                navigate(`/club/dashboard/${club?.id}`);
              }}>
              <div className="text-black">
                {/* <span className="badge bg-success">New</span>
                {menuIcon && (
                  <Dropdown
                    icon="more-horizontal"
                    list={optionList}
                    club={
                      clubDataUpdate?.id === club?.id ? clubDataUpdate : club
                    }
                    setClubDataUpdate={setClubDataUpdate}
                    isArchive={!club?.isEnable ? true : false}
                  />
                )} */}
                <div className={`flex-shrink-0 d-flex align-items-center`}>
                  <Image
                    src={
                      image
                        ? clubDataUpdate?.id === club?.id
                          ? clubDataUpdate?.image
                            ? clubDataUpdate?.image
                            : image
                          : image
                        : default_club
                    }
                    alt=""
                    className={`img-fluid w-100 club_image_card`}
                    style={{ borderRadius: "10px" }}
                  />
                </div>
                <div className="row w-100">
                  <Heading
                    type="h5"
                    className={`mb-0 pt-3 txt-primary poppins fw-bold text-truncate text-center`}
                    label={
                      name && clubDataUpdate?.id === club?.id
                        ? clubDataUpdate?.name
                          ? clubDataUpdate?.name
                          : name
                        : name
                    }
                  />
                  {clubName && (
                    <p className="mb-1 fw-bolder poppins text-truncate">
                      {clubName && clubName}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Card;
