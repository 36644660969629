import no_results from "assets/images/no-results.png";
import plus1 from "assets/images/plus1.png";
import CourseInfo from "components/CourseInfo";
import Heading from "components/Heading";
import Image from "components/Image";
import InstitutionType from "components/InstitutionType";
import MainControlButton from "components/MainControlButton";
import Navbar from "components/Navbar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import jwt_decode from "jwt-decode";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { actions } from "redux/Course/action";
import { actions as loaderActions } from "redux/Loader/action";
import "./CourseCardInfo.css";
import UnitCard from "./UnitCard";

function CourseCardInfo({ playerCentre, player, setPlayerCentre }) {
  const location = useLocation();
  const params = useParams();
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const dropdownRef = useRef();
  const token = localStorage.getItem("TOKEN");
  let decoded;
  if (token) {
    decoded = jwt_decode(token);
  }

  useEffect(() => {
    let handler = (e) => {
      if (menuRef && !menuRef.current?.contains(e.target)) {
        setDropdownValueUnitCard(false);
      }
    };
    document.addEventListener("mousedown", handler);
  }, []);

  // useEffect(() => {
  //   dispatch(loaderActions.startLoader());
  //   dispatch(actions.clearCourses());
  //   dispatch(
  //     actions.getCourses({
  //       instituteTypeId: params.courseId,
  //       userId: user?.id,
  //       createdBySuperAdmin:
  //         location?.state?.playerCenter === "courseCenter" ? true : false,
  //     })
  //   );
  // }, [dispatch, location?.state?.playerCenter, params.courseId, user]);

  // eslint-disable-next-line
  const { units, courses, course, institutionTypes, institutions } =
    useSelector((state) => state.course);
  const { networkProgressDialog } = useSelector((state) => state.loader);
  const [loaderSave, setLoaderSave] = useState(false);
  const [isShow, setIsShow] = useState(true);
  // eslint-disable-next-line
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedOptionEditable, setSelectedOptionEditable] = useState(null);
  const [
    selectedOptionInstitutionTypeEditable,
    setSelectedOptionInstitutionTypeEditable,
  ] = useState(null);
  const [
    selectedOptionQualificationTypeEditable,
    setSelectedOptionQualificationTypeEditable,
  ] = useState(null);
  const [selectedOptionGradingEditable, setSelectedOptionGradingEditable] =
    useState(null);
  const [subTitle1, setSubTitle1] = useState(location?.state?.step_0);
  const [subTitle, setSubTitle] = useState(location?.state?.step);
  const [isShowInputBox, setIsShowInputBox] = useState(true);
  const [duplicateShow, setDuplicateShow] = useState(true);
  const [duplicateAndEdit, setDuplicateAndEdit] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [dropdownValueUnitCard, setDropdownValueUnitCard] = useState({
    id: "",
    open: false,
  });
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionInstitutionType, setSelectedOptionInstitutionType] =
    useState(null);
  const [selectedOptionQualificationType, setSelectedOptionQualificationType] =
    useState(null);
  const [selectedOptionGrading, setSelectedOptionGrading] = useState(null);
  const [dropdownValue, setDropdownValue] = useState(false);
  const [dropdownValueInstitutionType, setDropdownValueInstitutionType] =
    useState(false);

  const [dropdownValueQualificationType, setDropdownValueQualificationType] =
    useState(false);
  const [dropdownValueGrading, setDropdownValueGrading] = useState(false);

  const navigate = useNavigate();
  const menuRef = useRef();

  let { clubs } = useSelector((state) => state.club);
  let clubData =
    params?.id &&
    clubs?.find((club) => {
      return params?.id === club?.id;
    });
  useEffect(
    () => {
      dispatch(loaderActions.startLoader());
      dispatch(actions.clearCourses());

      if (playerCentre !== "myCourseCenter") {
        if (playerCentre === "superCourseCenter") {
          dispatch(
            actions.getCourses({
              qualificationId: params.courseId,
              // userId: user.id,
              // createdBySuperAdmin:
              //   playerCentre === "superCourseCenter" ? true : false,
            })
          );
        } else {
          if (params?.id) {
            // dispatch(
            //   actions.getCourses({
            //     instituteTypeId: clubData?.instituteTypeId,
            //     instituteId: clubData?.instituteId,
            //     // userId: user.id,
            //     createdBySuperAdmin: true,
            //   })
            // );
            dispatch(
              actions.getCourses({
                instituteTypeId: clubData?.instituteTypeId,
                instituteId: clubData?.instituteId,
                showPublic: true,
              })
            );
            dispatch(
              actions.getInstitutionTypes({
                instituteId: clubData?.instituteId,
                closeLoader: true,
                showPublic: true,
              })
            );
          } else {
            dispatch(
              actions.getCourses({
                instituteTypeId: user?.club?.instituteTypeId,
                instituteId: user?.club?.instituteId,
                showPublic: true,
                // userId: user.id,
                // createdBySuperAdmin: true,
              })
            );
            dispatch(
              actions.getInstitutionTypes({
                instituteId: user?.club?.instituteId,
                closeLoader: true,
                showPublic: true,
              })
            );
          }
        }
      } else {
        if (params?.id) {
          dispatch(
            actions.getCourses({
              instituteId: clubData?.instituteId,
              instituteTypeId: clubData?.instituteTypeId,
              userId: user?.id,
              clubId: params?.id,
              myCourses: true,
            })
          );
        } else {
          dispatch(
            actions.getCourses({
              instituteId: user?.club?.instituteId,
              instituteTypeId: user?.club?.instituteTypeId,
              userId: user?.id,
              myCourses: true,
              clubId: user?.club?.id,
            })
          );
        }
      }
    },
    // eslint-disable-next-line
    [
      dispatch,
      clubData?.instituteId,
      clubData?.instituteTypeId,
      params.courseId,
      params?.id,
      playerCentre,
      user?.club?.instituteId,
      user?.club?.instituteTypeId,
      user?.id,
    ]
  );

  useEffect(() => {
    let existInstitutionType;
    let existInstitution;
    if (params?.id) {
      existInstitutionType = institutionTypes.find((data) => {
        return clubData?.instituteTypeId === data.id;
      });
      existInstitution = institutions.find((data) => {
        return clubData?.instituteId === data.id;
      });
    } else {
      existInstitutionType = institutionTypes.find((data) => {
        return user?.club?.instituteTypeId === data.id;
      });
      existInstitution = institutions.find((data) => {
        return user?.club?.instituteId === data.id;
      });
    }
    if (
      (playerCentre !== "myCourseCenter" && playerCentre === "courseCenter") ||
      params?.id
    ) {
      setSubTitle(existInstitutionType?.name);
      setSubTitle1(existInstitution?.name);
    }
  }, [
    institutionTypes,
    clubData?.instituteId,
    clubData?.instituteTypeId,
    institutions,
    params?.id,
    playerCentre,
    user?.club?.instituteId,
    user?.club?.instituteTypeId,
  ]);

  return (
    <>
      {location?.pathname.includes("/courses/course/") &&
        decoded?.role === "admin" && <Navbar />}
      <div
        className={`ExistingControlBtn ${
          location?.pathname.includes("/courses/course/") &&
          decoded?.role === "admin"
            ? "p-0 mt-3 px-3"
            : ""
        }`}
      >
        {!location?.pathname.includes("/courses/course/") &&
          decoded?.role !== "admin" &&
          decoded?.role === "clubAdmin" && (
            <MainControlButton
              playerCentre={playerCentre}
              player={player}
              setPlayerCentre={setPlayerCentre}
            />
          )}
        {(playerCentre === "myCourseCenter" ||
          playerCentre === "courseCenter") &&
          decoded?.role === "admin" && (
            <MainControlButton
              playerCentre={playerCentre}
              player={player}
              setPlayerCentre={setPlayerCentre}
            />
          )}
        {playerCentre !== "viewAllCourses" && (
          <div className="d-flex CardTitle course-cardInfo-title">
            <p
              className="CardExistingText "
              onClick={() => {
                if (playerCentre === "courseUnitCenter") {
                  setPlayerCentre("courseCenter");
                }
                if (playerCentre === "superCourseCenter") {
                  navigate("/courses");
                }
                if (playerCentre === "myCourseCenter") {
                  if (isShow === true) {
                    setIsShow(isShow);
                  } else {
                    setIsShow(!isShow);
                  }
                }
              }}
            >
              {playerCentre === "myCourseCenter"
                ? "ASSIGNED COURSES"
                : location?.state?.playerCenter === "courseCenter"
                ? `   ${
                    location.pathname.includes("/qualification-type")
                      ? "AVAILABLE COURSE TYPES"
                      : "AVAILABLE COURSES "
                  }   | ${subTitle1?.toUpperCase()}`
                : `${
                    location.pathname.includes("/qualification-type")
                      ? "AVAILABLE COURSE TYPES"
                      : "AVAILABLE COURSES"
                  } | ${subTitle1?.toUpperCase()}`}
            </p>
            {playerCentre !== "myCourseCenter" && (
              <>
                <FeatherIcon
                  className="RightIcon"
                  icon={`chevron-right`}
                  size={30}
                  strokeWidth={2}
                />

                {/* dynamic Title of courses */}
                <p
                  className={
                    playerCentre === "superCourseCenter"
                      ? "CardDynamicTitle"
                      : isShow
                      ? "CardStaticTitle"
                      : "CardDynamicTitle"
                  }
                  onClick={() => {
                    if (isShow === true) {
                      setIsShow(isShow);
                    } else {
                      setIsShow(!isShow);
                    }
                    setDuplicateShow(true);
                    setDropdownValueUnitCard(false);
                    playerCentre === "superCourseCenter" && navigate(-1);
                  }}
                >
                  {subTitle}
                </p>
              </>
            )}
            {playerCentre !== "myCourseCenter" && (
              <>
                {playerCentre === "superCourseCenter" && (
                  <FeatherIcon
                    className="RightIcon"
                    icon={`chevron-right`}
                    size={30}
                    strokeWidth={2}
                  />
                )}

                <p
                  // className="CardDynamicTitle"
                  className={!isShow ? "CardDynamicTitle" : "CardStaticTitle"}
                  onClick={() => {
                    if (isShow === true) {
                      setIsShow(isShow);
                    } else {
                      setIsShow(!isShow);
                    }
                    setDuplicateShow(true);
                    setDropdownValueUnitCard(false);
                    // navigate(-1);
                  }}
                >
                  {location?.state?.step1}
                </p>
              </>
            )}
            {!isShow && (
              <>
                <FeatherIcon
                  className="EditRightIcon"
                  icon={`chevron-right`}
                  size={30}
                  strokeWidth={2}
                />

                {!isShow && !duplicateShow ? (
                  <p className="CardStaticTitle">DUPLICATE & EDIT </p>
                ) : (
                  <p className="CardStaticTitle">EDIT EXISTING</p>
                )}
              </>
            )}
          </div>
        )}

        {isShow ? (
          networkProgressDialog ? (
            <div id="cover-spin" className="LoaderSpin">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : true ? (
            <div className="d-flex flex-wrap unitCard" ref={menuRef}>
              {!params?.id && decoded?.role === "admin" && (
                <div
                  className="cards-list"
                  onClick={() => {
                    // setInstituteType([
                    //   { id: uuid(), name: "", createNew: true },
                    //   ...instituteType,
                    // ]);
                    navigate("/courses/course/new", {
                      state: {
                        ...location?.state,
                        // instituteId: data?.instituteId,
                        // instituteTypeId: data?.instituteTypeId,
                        qualificationId: params?.courseId,
                      },
                    });
                  }}
                >
                  <div className="CourseCardInfo">
                    <div className="title-white">
                      <div
                        className={`flex-shrink-0 d-flex align-items-center`}
                      >
                        <Image
                          src={plus1}
                          alt=""
                          className="img-fluid w-100 club_image_card cardBodyAddCard"
                          style={{
                            borderRadius: "10px",
                            filter: "invert(1)",
                          }}
                        />
                      </div>
                      <div className="w-100">
                        <Heading
                          type="h5"
                          className={`mb-0 poppins text-truncate text-center text-white`}
                          label={"Add Course"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {courses &&
                courses?.length > 0 &&
                courses?.map((item) => {
                  return item?.clubId !==
                    (user?.club ? user?.club?.id : clubData?.id) ||
                    playerCentre !== "courseCenter" ? (
                    <UnitCard
                      key={item?.id}
                      setIsShow={setIsShow}
                      course={item}
                      setSelectedCourse={setSelectedCourse}
                      setDuplicateShow={setDuplicateShow}
                      setDuplicateAndEdit={setDuplicateAndEdit}
                      setDropdownValueUnitCard={setDropdownValueUnitCard}
                      dropdownValueUnitCard={dropdownValueUnitCard}
                      playerCentre={playerCentre}
                      setIsShowInputBox={setIsShowInputBox}
                    />
                  ) : (
                    ""
                  );
                })}

              {(playerCentre === "myCourseCenter" ||
                (playerCentre !== "courseCenter" &&
                  playerCentre !== "superCourseCenter")) &&
                courses?.length <= 0 && (
                  <div className="d-flex flex-column align-items-center no-Result-Found">
                    <img
                      className="noResultImg"
                      src={no_results}
                      alt="No Results Found"
                    />
                    <span className="noResultsFound poppins d-flex">
                      {/* There are no courses assigned.{" "} */}
                      {`There are no courses ${
                        playerCentre !== "myCourseCenter"
                          ? "available."
                          : "assigned."
                      } `}
                      {playerCentre === "myCourseCenter" && (
                        <p
                          className="assign-course-link"
                          onClick={() => setPlayerCentre("courseCenter")}
                        >
                          Assign a course?
                        </p>
                      )}
                    </span>
                  </div>
                )}
            </div>
          ) : (
            <></>
            // <div className="text-center mt-5">
            //   {" "}
            //   <p>There are no available courses.</p>{" "}
            // </div>
          )
        ) : networkProgressDialog && !loaderSave ? (
          <>
            <div id="cover-spin" className="LoaderSpin">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="row courseCardInfoRow">
              <div className="col-md-auto courseCardInfoCol">
                <InstitutionType
                  // CreateIntuitionType={CreateIntuitionType}
                  // setCourseInfo={setCourseInfo}
                  course={course}
                  editExistingCourse={true}
                  setSelectedOptionGradingEditable={
                    setSelectedOptionGradingEditable
                  }
                  setSelectedOptionQualificationTypeEditable={
                    setSelectedOptionQualificationTypeEditable
                  }
                  setSelectedOptionInstitutionTypeEditable={
                    setSelectedOptionInstitutionTypeEditable
                  }
                  setSelectedOptionEditable={setSelectedOptionEditable}
                  isCancel={isCancel}
                  setIsCancel={setIsCancel}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  selectedOptionInstitutionType={selectedOptionInstitutionType}
                  setSelectedOptionInstitutionType={
                    setSelectedOptionInstitutionType
                  }
                  selectedOptionQualificationType={
                    selectedOptionQualificationType
                  }
                  setSelectedOptionQualificationType={
                    setSelectedOptionQualificationType
                  }
                  selectedOptionGrading={selectedOptionGrading}
                  setSelectedOptionGrading={setSelectedOptionGrading}
                  dropdownValue={dropdownValue}
                  setDropdownValue={setDropdownValue}
                  dropdownValueInstitutionType={dropdownValueInstitutionType}
                  setDropdownValueInstitutionType={
                    setDropdownValueInstitutionType
                  }
                  dropdownValueQualificationType={
                    dropdownValueQualificationType
                  }
                  setDropdownValueQualificationType={
                    setDropdownValueQualificationType
                  }
                  dropdownValueGrading={dropdownValueGrading}
                  setDropdownValueGrading={setDropdownValueGrading}
                />
              </div>
              <div
                className="CourseInfo col col-md-2 col-lg-4 "
                ref={dropdownRef}
              >
                {
                  <CourseInfo
                    courseInfo={course}
                    // courseInfoCopy={courseInfoCopy}
                    editExistingCourse={true}
                    setIsShowInputBox={setIsShowInputBox}
                    isShowInputBox={isShowInputBox}
                    setIsShow={setIsShow}
                    setDuplicateShow={setDuplicateShow}
                    duplicateAndEdit={duplicateAndEdit}
                    duplicateShow={duplicateShow}
                    selectedOptionGradingEditable={
                      selectedOptionGradingEditable
                    }
                    selectedOptionQualificationTypeEditable={
                      selectedOptionQualificationTypeEditable
                    }
                    selectedOptionInstitutionTypeEditable={
                      selectedOptionInstitutionTypeEditable
                    }
                    selectedOptionEditable={selectedOptionEditable}
                    setLoaderSave={setLoaderSave}
                    setIsCancel={setIsCancel}
                  />
                }
              </div>
              {/* <div className="mt-5">
            <CourseInfo
              courseInfo={courseInfos}
              courseInfoCopy={courseInfoCopy}
              setIsShow={setIsShow}
              setDuplicateShow={setDuplicateShow}
              duplicateAndEdit={duplicateAndEdit}
            />
          </div> */}
            </div>

            {networkProgressDialog && loaderSave && (
              <div id="cover-spin" className="LoaderSpin">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default CourseCardInfo;
