import Button from "components/Button";
import GradingCategory from "components/GradingCategory";
import NewCategory from "components/NewCategory";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import jwt_decode from "jwt-decode";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { actions } from "redux/Course/action";
import { actions as loaderActions } from "redux/Loader/action";
import { Toast } from "utils/toast";
import { uuid } from "utils/uuid";
import "./InstitutionType.css";

const InstitutionType = ({
  CreateIntuitionType,
  setCourseInfo,
  course,
  editExistingCourse,
  setSelectedOptionGradingEditable,
  setSelectedOptionQualificationTypeEditable,
  setSelectedOptionInstitutionTypeEditable,
  setSelectedOptionEditable,
  isCancel,
  setIsCancel,
  selectedOption,
  setSelectedOption,
  selectedOptionInstitutionType,
  setSelectedOptionInstitutionType,
  selectedOptionQualificationType,
  setSelectedOptionQualificationType,
  selectedOptionGrading,
  setSelectedOptionGrading,
  dropdownValue,
  setDropdownValue,
  dropdownValueInstitutionType,
  setDropdownValueInstitutionType,
  dropdownValueQualificationType,
  setDropdownValueQualificationType,
  dropdownValueGrading,
  setDropdownValueGrading,
  superAddCourse,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  let token = localStorage.getItem("TOKEN");
  let decoded;
  if (token) {
    decoded = jwt_decode(token);
  }
  const { institutions, institutionTypes, qualifications, gradingStructure } =
    useSelector((state) => state.course);
  const { user } = useSelector((state) => state.user);

  const numberOf_Modules = [];
  for (let i = 1; i <= 30; i++) {
    numberOf_Modules.push({ name: i.toString(), value: i });
  }

  const numberOf_Units = [{ name: "No unit required", value: 0 }];
  for (let i = 1; i <= 20; i++) {
    numberOf_Units.push({ name: i.toString(), value: i });
  }
  // const [instituteOptions, setInstituteOptions] = useState([]);

  // const [instituteTypeOptions, setInstituteTypeOptions] = useState([]);
  // const [qualificationOptions, setQualificationOptions] = useState([]);

  let { clubs } = useSelector((state) => state.club);
  let clubData =
    params?.id &&
    clubs?.find((club) => {
      return params?.id === club?.id;
    });
  // const [selectedUnit, setSelectedUnit] = useState("");
  // const [selectedModule, setSelectedModule] = useState("");
  // eslint-disable-next-line
  const [shouldCreateNewDropdown, setShouldCreateNewDropdown] = useState(false);
  const [selectedDropdownEditable, setSelectedDropdownEditable] =
    useState(false);
  const [showIcon, setShowIcon] = useState(true);
  const [nextIndex, setNextIndex] = useState(0);
  const [nextIndexModule, setNextIndexModule] = useState(0);
  // eslint-disable-next-line
  const [arrOfModule, setArrOfModule] = useState([]);
  // eslint-disable-next-line
  const [arrOfUnits, setArrOfUnits] = useState([]);
  const [modulesArray, setModulesArray] = useState([]);
  const [disableCommitButton, setDisableCommitButton] = useState(false);
  const [morGradingStructure, setMoreGradingStructure] = useState(false);
  const [newCategory, setNewCategory] = useState(false);
  const [error, setError] = useState({
    message: "",
  });
  const courseTitleId = uuid();
  const [courseInformation, setCourseInformation] = useState([
    { id: courseTitleId, title: "Course Title", value: "", isShow: true },
  ]);
  const [add_Unit, setAdd_Unit] = useState([
    {
      id: uuid(),
      title: "How Many Units?",
      value: "",
    },
  ]);
  const [showCommitButton, setShowCommitButton] = useState(false);
  const [selectedNumberOfUnit, setSelectedNumberOfUnit] = useState(null);
  const [selectedNumberOfModule, setSelectedNumberOfModule] = useState(null);

  const arrayRange = (start, stop, step) => {
    return Array.from(
      { length: (stop - start) / step + 1 },
      (value, index) => start + index * step
    );
  };

  const CreateCourseInformation = (data, i) => {
    if (i === 0 && courseInformation?.length < 2) {
      const noOfUnitsId = uuid();
      setCourseInformation([
        ...courseInformation,
        {
          id: noOfUnitsId,
          title: "How Many Modules?",
          value: "",
          isShow: true,
        },
      ]);
    } else {
      let courseInfo = [];

      if (
        data.value &&
        data.title === "How Many Modules?" &&
        courseInformation?.length <= 2
      ) {
        const arr =
          data.title === "How Many Modules?" && arrayRange(1, data.value, 1);
        data.title === "How Many Modules?" && setArrOfUnits(arr);
        const arrayModules =
          arr.length > 0 &&
          arr.map((a) => {
            const moduleInput = {
              id: uuid(),
              title: `Module ${a} Name`,
              value: "",
              isShow: a === 1 ? true : false,
              units: [
                {
                  id: uuid(),
                  // unitId: moduleInput.id,
                  title: "How Many Units?",
                  value: "",
                  isShow: false,
                },
              ],
            };
            return moduleInput;
          });

        setModulesArray([...modulesArray, ...arrayModules]);
        // setModulesArray([...modulesArray, ...arrayModules]);

        setCourseInformation([...courseInformation, ...arrayModules]);
      } else {
        if (data.value && data.title === "How Many Modules?") {
          courseInfo = courseInformation.slice(0, 2);
          // setCourseInformation([...courseInfo]);
          const arr =
            data.title === "How Many Modules?" && arrayRange(1, data.value, 1);
          data.title === "How Many Modules?" && setArrOfUnits(arr);
          const arrayModules =
            arr.length > 0 &&
            arr.map((a) => {
              const moduleInput = {
                id: uuid(),
                title: `Module ${a} Name`,
                value: "",
                isShow: a === 1 ? true : false,
                units: [
                  {
                    id: uuid(),
                    // unitId: moduleInput.id,
                    title: "How Many Units?",
                    value: "",
                    isShow: false,
                  },
                ],
              };
              return moduleInput;
            });

          setModulesArray([...modulesArray, ...arrayModules]);
          // setModulesArray([...modulesArray, ...arrayModules]);

          setCourseInformation([...courseInfo, ...arrayModules]);
        } else {
          // !selectedUnit && setCourseInformation([...courseInformation, data]);
          let courseInfo = courseInformation.map((item) => {
            if (item.id === data.id) {
              if (item.units) {
                item.units[0].isShow = true;
              }
            }
            return item;
          });
          setCourseInformation(courseInfo);
        }
      }
    }
  };

  const CreateModuleInformation = (data, moduleId, UnitIndex) => {
    if (data.value === 0 && data.title === "How Many Units?") {
      let indexModule = 0;
      let flag = false;
      let courseInfo = courseInformation.map((data, i) => {
        if (data.id === moduleId) {
          if (UnitIndex === data.units.length) {
            indexModule = i;
            data.units = data.units.map((item) => {
              item.isShow = false;
              return item;
            });
            flag = true;
          } else {
            data.units[UnitIndex].isShow = true;
          }
        }

        return data;
      });

      if (flag && indexModule + 1 < courseInfo.length) {
        courseInfo[indexModule + 1].isShow = true;
      }

      if (indexModule + 1 >= courseInfo.length) {
        courseInfo = courseInfo.map((item) => {
          item.isShow = false;
          item.units =
            item?.units &&
            item?.units?.length > 0 &&
            item?.units?.map((unitItem) => {
              unitItem.isShow = false;
              return unitItem;
            });
          setShowCommitButton(true);
          return item;
        });
      }

      setCourseInformation(courseInfo);
      return;
    }
    if (data.value >= 0 && data.title === "How Many Units?") {
      const arr =
        data.title === "How Many Units?" && arrayRange(1, data.value, 1);
      data.title === "How Many Units?" && setArrOfModule(arr);

      // const indexData =
      //   data.title === "Add Module"
      //     ? arr.slice(nextIndexModule, nextIndexModule + 1)
      //     : arrOfModule.slice(nextIndexModule, nextIndexModule + 1);

      const NumberOfUnit = arr.map((a) => {
        const ModuleInput = {
          id: uuid(),
          moduleId: data.moduleId,
          title: `Unit ${a} Name`,
          value: "",
          isShow: a === 1 ? true : false,
        };
        return ModuleInput;
      });

      const courseInfo = courseInformation.map((data) => {
        if (data.id === moduleId) {
          data.units = [...data.units.slice(0, 1), ...NumberOfUnit];
        }
        return data;
      });
      setCourseInformation(courseInfo);
      setAdd_Unit([...add_Unit, ...NumberOfUnit]);
    } else {
      // setAdd_Unit([...add_Unit, data]);
      let indexModule = 0;
      let flag = false;
      let courseInfo = courseInformation.map((data, i) => {
        if (data.id === moduleId) {
          if (UnitIndex === data.units.length) {
            indexModule = i;
            data.units = data.units.map((item) => {
              item.isShow = false;
              return item;
            });
            flag = true;
          } else {
            data.units[UnitIndex].isShow = true;
          }
        }

        return data;
      });

      if (flag && indexModule + 1 < courseInfo.length) {
        courseInfo[indexModule + 1].isShow = true;
      }

      if (indexModule + 1 >= courseInfo.length) {
        courseInfo = courseInfo.map((item) => {
          item.isShow = false;
          item.units =
            item?.units &&
            item?.units?.length > 0 &&
            item?.units?.map((unitItem) => {
              unitItem.isShow = false;
              return unitItem;
            });
          setShowCommitButton(true);
          return item;
        });
      }

      setCourseInformation(courseInfo);
    }
  };

  const handleMainDropdownChange = (value) => {
    dispatch(actions.getInstitutionTypes({ instituteId: value.id }));
    setSelectedOption({ ...value, disabled: true });
    setSelectedOptionInstitutionType(null);
    setSelectedOptionQualificationType(null);
    setSelectedOptionGrading(null);
    // setInstituteTypeOptions([...institutionTypes]);
    setIsCancel && setIsCancel(false);
    // setShouldCreateNewDropdown(true);
    setSelectedDropdownEditable(true);
    setDropdownValue(!dropdownValue);
  };

  const createCourse = () => {
    // courseInformation.
    if (
      selectedOption &&
      selectedOptionInstitutionType &&
      selectedOptionGrading &&
      selectedOptionQualificationType
    ) {
      const courseInfo = {
        instituteId: selectedOption?.id,
        instituteTypeId: selectedOptionInstitutionType?.id,
        qualificationId: selectedOptionQualificationType?.id,
        gradeId: selectedOptionGrading?.id,
        userId: user?.id,
        clubId: params?.id ? clubData?.id : user?.club?.id,
        createdBySuperAdmin: decoded?.role === "admin" ? true : false,
        title: courseInformation[0].value,
        noOfModules: Number(courseInformation[1].value),
      };
      const courseInformationCopy = structuredClone(courseInformation);
      courseInformationCopy.splice(0, 2);
      courseInfo.module = courseInformationCopy.map((data) => {
        const moduleData = {
          name: data.value,
        };
        data.units.splice(0, 1);
        moduleData.unit = data.units.map((unit) => {
          return {
            name: unit.value,
          };
        });
        return moduleData;
      });
      setCourseInfo(courseInfo);
      dispatch(actions.createCourse(courseInfo, setDisableCommitButton));
    } else {
      Toast("error", "Please select proper information!");
    }
    // setDisableCommitButton(true);
  };

  useEffect(() => {
    const handleSize = () => {
      if (window.innerWidth < 1025) {
        setShowIcon(false);
      } else {
        setShowIcon(true);
      }
    };
    window.addEventListener("resize", handleSize);

    return () => window.removeEventListener("resize", handleSize);
  }, []);

  useEffect(() => {
    if (editExistingCourse) {
      dispatch(actions.getInstitutions());
      dispatch(
        actions.getInstitutionTypes({ instituteId: course?.instituteId })
      );
      dispatch(
        actions.getQualifications({
          instituteId: course?.instituteId,
          instituteTypeId: course?.instituteTypeId,
        })
      );
      dispatch(
        actions.getGrades({
          instituteId: course?.instituteId,
          qualificationId: course?.qualificationId,
        })
      );
    }
    dispatch(
      actions.getGrades({
        instituteId: course?.instituteId,
        qualificationId: course?.qualificationId,
      })
    );
  }, [course, isCancel, editExistingCourse, dispatch]);

  useEffect(() => {
    if ((editExistingCourse && !selectedDropdownEditable) || isCancel) {
      const existInstitution = institutions.find((data) => {
        return course?.instituteId === data.id;
      });
      const existInstitutionType = institutionTypes.find((data) => {
        return course?.instituteTypeId === data.id;
      });
      const existQualification = qualifications.find((data) => {
        return course?.qualificationId === data.id;
      });
      const existGrading = gradingStructure.allGrades.find((data) => {
        return course?.gradeId === data.id;
      });

      setSelectedOption({
        id: course?.instituteId,
        name: existInstitution?.name,
        disabled: true,
      });
      setSelectedOptionInstitutionType({
        id: course?.instituteTypeId,
        name: existInstitutionType?.name,
        disabled: true,
      });
      setSelectedOptionQualificationType({
        id: course?.qualificationId,
        name: existQualification?.name,
        disabled: true,
      });
      setSelectedOptionGrading({
        id: course?.gradeId,
        label: existGrading?.label,
        disabled: true,
      });
    }
  }, [
    course,
    institutionTypes,
    qualifications,
    gradingStructure,
    isCancel,
    editExistingCourse,
    institutions,
    selectedDropdownEditable,
    setSelectedOption,
    setSelectedOptionGrading,
    setSelectedOptionInstitutionType,
    setSelectedOptionQualificationType,
  ]);

  useEffect(() => {
    if (editExistingCourse) {
      setSelectedOptionEditable(selectedOption);
      setSelectedOptionGradingEditable(selectedOptionGrading);
      setSelectedOptionQualificationTypeEditable(
        selectedOptionQualificationType
      );
      setSelectedOptionInstitutionTypeEditable(selectedOptionInstitutionType);
    }
  }, [
    selectedOption,
    selectedOptionGrading,
    selectedOptionQualificationType,
    selectedOptionInstitutionType,
    isCancel,
    editExistingCourse,
    setSelectedOptionEditable,
    setSelectedOptionGradingEditable,
    setSelectedOptionInstitutionTypeEditable,
    setSelectedOptionQualificationTypeEditable,
  ]);

  useEffect(() => {
    if (
      (!editExistingCourse && decoded?.role !== "admin") ||
      (!editExistingCourse && decoded?.role === "admin" && params?.id)
    ) {
      if (params?.id) {
        dispatch(
          actions.getInstitutionTypes({ instituteId: clubData?.instituteId })
        );
        dispatch(
          actions.getQualifications({
            instituteId: clubData?.instituteId,
            instituteTypeId: clubData?.instituteTypeId,
          })
        );
      } else {
        // dispatch(actions.getInstitutions({ id: user?.club?.instituteId }));
        dispatch(
          actions.getInstitutionTypes({ instituteId: user?.club?.instituteId })
        );
        dispatch(
          actions.getQualifications({
            instituteId: user?.club?.instituteId,
            instituteTypeId: user?.club?.instituteTypeId,
          })
        );
      }
    }
  }, [
    user,
    clubData?.instituteId,
    clubData?.instituteTypeId,
    decoded?.role,
    dispatch,
    editExistingCourse,
    params?.id,
  ]);

  useEffect(() => {
    if (
      (!editExistingCourse && decoded?.role !== "admin") ||
      (!editExistingCourse && decoded?.role === "admin" && params?.id)
    ) {
      if (params?.id) {
        const existInstitution = institutions.find((data) => {
          return clubData?.instituteId === data.id;
        });
        const existInstitutionType = institutionTypes.find((data) => {
          return clubData?.instituteTypeId === data.id;
        });
        // const existQualification = qualifications.find((data) => {
        //   return course?.qualificationId === data.id;
        // });
        // const existGrading = gradingStructure.find((data) => {
        //   return course?.gradeId === data.id;
        // });

        setSelectedOption({
          id: clubData?.instituteId,
          name: existInstitution?.name,
          disabled: true,
        });

        setSelectedOptionInstitutionType({
          id: clubData?.instituteTypeId,
          name: existInstitutionType?.name,
          disabled: true,
        });
      } else {
        const existInstitution = institutions.find((data) => {
          return user?.club?.instituteId === data.id;
        });
        const existInstitutionType = institutionTypes.find((data) => {
          return user?.club?.instituteTypeId === data.id;
        });
        // const existQualification = qualifications.find((data) => {
        //   return course?.qualificationId === data.id;
        // });
        // const existGrading = gradingStructure.find((data) => {
        //   return course?.gradeId === data.id;
        // });

        setSelectedOption({
          id: user?.club?.instituteId,
          name: existInstitution?.name,
          disabled: true,
        });

        setSelectedOptionInstitutionType({
          id: user?.club?.instituteTypeId,
          name: existInstitutionType?.name,
          disabled: true,
        });
      }

      // setSelectedOptionQualificationType({
      //   id: course?.qualificationId,
      //   name: existQualification?.name,
      //   disabled: true,
      // });
      // setSelectedOptionGrading({
      //   id: course?.gradeId,
      //   label: existGrading?.label,
      //   disabled: true,
      // });
    }
  }, [
    user,
    clubData?.instituteId,
    clubData?.instituteTypeId,
    decoded?.role,
    editExistingCourse,
    institutionTypes,
    institutions,
    setSelectedOption,
    setSelectedOptionInstitutionType,
    params?.id,
  ]);

  const onSaveInstitutionType = (data) => {
    if (data.name) {
      dispatch(loaderActions.startLoader());
      dispatch(
        actions.createInstitutionType(
          {
            name: data.name,
            instituteId: selectedOption.id,
            userId: user.id,
          },
          setNewCategory
        )
      );
    } else {
      setError({
        message: "Please Enter Organisation Type",
      });
    }
  };

  const onSaveQualification = (data) => {
    if (data.name) {
      dispatch(loaderActions.startLoader());
      dispatch(
        actions.createQualification(
          {
            name: data.name,
            instituteId: selectedOption.id,
            instituteTypeId: selectedOptionInstitutionType.id,
            userId: user.id,
          },
          setNewCategory
        )
      );
    } else {
      setError({
        message: "Please Enter New course Type",
      });
    }
  };

  return (
    <>
      <div className="institutionTypesDropDown">
        {decoded?.role === "admin" && !params?.id && !superAddCourse && (
          <div id="controlButtons" className="DropDownControlBtn">
            <div className="dropdown ">
              <button
                type="button"
                // className="dropbtn controlBtn click instituteTypeBtn"
                className={`dropbtn controlBtn click instituteTypeBtn ${
                  selectedOption && selectedOption.disabled ? "" : ""
                }`}
                onClick={() => {
                  // !selectedOption
                  //   ? setDropdownValue(!dropdownValue)
                  //   : selectedOption?.disabled
                  //   ? setDropdownValue(false)
                  //   : setDropdownValue(!dropdownValue);
                  setDropdownValue(!dropdownValue);
                  setDropdownValueInstitutionType(false);
                  setDropdownValueQualificationType(false);
                  setDropdownValueGrading(false);
                }}
                value={selectedOption?.name}
                onChange={handleMainDropdownChange}
                disabled={shouldCreateNewDropdown}>
                {selectedOption
                  ? selectedOption?.name?.length > 17
                    ? selectedOption?.name?.slice(0, 15) + "..."
                    : selectedOption?.name
                  : "Organisation"}

                <FeatherIcon
                  icon={dropdownValue ? "chevron-up" : "chevron-down"}
                  size={20}
                />
              </button>
              <div
                className={`dropdown-content dropDownHeight ${
                  dropdownValue ? "active" : ""
                }`}>
                {institutions?.length > 0 &&
                  institutions.map((option) => (
                    <>
                      <button
                        key={option.id}
                        id="SubDropdown"
                        name="types"
                        // className="dropdownbtn controlBtn click"
                        className={`dropdownbtn controlBtn click ${
                          dropdownValue ? "active" : ""
                        }`}
                        onClick={() => {
                          // handleSelected();
                          handleMainDropdownChange(option);
                        }}>
                        {/* {option?.name} */}
                        {/* {option?.name?.length > 21
                        ? option?.name.slice(0, 19) + "..."
                        : option?.name} */}

                        {window.innerWidth <= 1024 && window.innerWidth >= 320
                          ? option?.name
                          : option?.name?.length > 21
                          ? option?.name?.slice(0, 19) + "..."
                          : option?.name}
                      </button>
                    </>
                  ))}
                {/* <div className="d-inline-flex">
                <button
                  id="SubDropdown"
                  name="types"
                  className={`dropdownbtn controlBtn click ${
                    dropdownValue ? "active" : ""
                  }`}
                  onClick={() => setNewCategory(true)}
                >
                  NEW CATEGORY
                </button>

                {newCategory && <NewCategory setNewCategory={setNewCategory} />}
              </div> */}
              </div>
            </div>

            {/* {selectedOption ? (
              <div className="InstitutionEdit">
                <p
                  className="EditTypes"
                  onClick={() => {
                    // dropDownEdit({ id: uuid() });
                    setSelectedOption({ ...selectedOption, disabled: false });
                  }}
                >
                  edit
                </p>
              </div>
            ) : null} */}
          </div>
        )}

        {selectedOption &&
          decoded?.role === "admin" &&
          !params?.id &&
          !superAddCourse && (
            <div id="controlButtons" className="DropDownControlBtn">
              <div className="dropdown ">
                <button
                  type="button"
                  // className="dropbtn controlBtn click instituteTypeBtn"
                  className={`dropbtn controlBtn click instituteTypeBtn ${
                    selectedOptionInstitutionType &&
                    selectedOptionInstitutionType.disabled
                      ? ""
                      : ""
                  }`}
                  onClick={() => {
                    setNewCategory(false);
                    // !selectedOptionInstitutionType
                    //   ? setDropdownValueInstitutionType(
                    //       !dropdownValueInstitutionType
                    //     )
                    //   : selectedOptionInstitutionType?.disabled
                    //   ? setDropdownValueInstitutionType(false)
                    //   : setDropdownValueInstitutionType(
                    //       !dropdownValueInstitutionType
                    //     );
                    setDropdownValueInstitutionType(
                      !dropdownValueInstitutionType
                    );
                    setDropdownValue(false);
                    setDropdownValueQualificationType(false);
                    setDropdownValueGrading(false);
                    setError({ message: "" });
                  }}
                  value={selectedOptionInstitutionType?.name}
                  // onChange={handleMainDropdownChange}
                  // disabled={shouldCreateNewDropdown}
                >
                  {selectedOptionInstitutionType
                    ? selectedOptionInstitutionType?.name?.length > 17
                      ? selectedOptionInstitutionType?.name?.slice(0, 15) +
                        "..."
                      : selectedOptionInstitutionType?.name
                    : "Organisation TYPES"}

                  <FeatherIcon
                    icon={
                      dropdownValueInstitutionType
                        ? "chevron-up"
                        : "chevron-down"
                    }
                    size={20}
                  />
                </button>
                <div
                  className={`dropdown-content dropDownHeight ${
                    dropdownValueInstitutionType ? "active" : ""
                  }`}>
                  {institutionTypes?.length > 0 &&
                    institutionTypes?.map((option) => (
                      <button
                        key={option.id}
                        id="SubDropdown"
                        name="types"
                        // className="dropdownbtn controlBtn click"
                        className={`dropdownbtn controlBtn click ${
                          dropdownValueInstitutionType ? "active" : ""
                        }`}
                        onClick={() => {
                          // handleSelected();
                          // handleMainDropdownChange(option);
                          dispatch(
                            actions.getQualifications({
                              instituteId: option.instituteId,
                              instituteTypeId: option.id,
                            })
                          );
                          setSelectedOptionInstitutionType({
                            ...option,
                            disabled: true,
                          });
                          setSelectedOptionQualificationType(null);
                          setSelectedOptionGrading(null);
                          // setQualificationOptions([...qualifications]);
                          setDropdownValueInstitutionType(
                            !dropdownValueInstitutionType
                          );
                          setSelectedDropdownEditable(true);
                          setIsCancel && setIsCancel(false);
                        }}>
                        {/* {option.name} */}
                        {/* {option?.name?.length > 21
                        ? option?.name?.slice(0, 19) + "..."
                        : option?.name} */}

                        {window.innerWidth <= 1024 && window.innerWidth >= 320
                          ? option?.name
                          : option?.name?.length > 21
                          ? option?.name?.slice(0, 19) + "..."
                          : option?.name}
                      </button>
                    ))}

                  {/* <button
                  id="SubDropdown"
                  name="types"
                  className={`dropdownbtn controlBtn click ${
                    dropdownValue ? "active" : ""
                  }`}
                >
                  NEW CATEGORY
                </button> */}
                  <div className="d-inline-flex institutionGrading">
                    <button
                      id="SubDropdown"
                      name="types"
                      className={`dropdownbtn controlBtn click ${
                        dropdownValue ? "active" : ""
                      }`}
                      onClick={() => setNewCategory(true)}
                      style={{ fontSize: "15px" }}>
                      NEW Organisation TYPES
                    </button>
                    {newCategory && (
                      <NewCategory
                        setNewCategory={setNewCategory}
                        onSave={onSaveInstitutionType}
                        error={error}
                        setError={setError}
                      />
                    )}
                  </div>
                </div>
              </div>

              {/* {selectedOptionInstitutionType ? (
                <div className="InstitutionEdit">
                  <p
                    className="EditTypes"
                    onClick={() => {
                      setSelectedOptionInstitutionType({
                        ...selectedOptionInstitutionType,
                        disabled: false,
                      });
                    }}
                  >
                    edit
                  </p>
                </div>
              ) : null} */}
            </div>
          )}

        {selectedOptionInstitutionType && !superAddCourse && (
          <div id="controlButtons" className="DropDownControlBtn">
            <div className="dropdown ">
              <button
                type="button"
                // className="dropbtn controlBtn click instituteTypeBtn"
                className={`dropbtn controlBtn click instituteTypeBtn ${
                  selectedOptionQualificationType &&
                  selectedOptionQualificationType.disabled
                    ? ""
                    : ""
                }`}
                onClick={() => {
                  setNewCategory(false);
                  // !selectedOptionQualificationType
                  //   ? setDropdownValueQualificationType(
                  //       !dropdownValueQualificationType
                  //     )
                  //   : selectedOptionQualificationType?.disabled
                  //   ? setDropdownValueQualificationType(false)
                  //   : setDropdownValueQualificationType(
                  //       !dropdownValueQualificationType
                  //     );
                  setDropdownValueQualificationType(
                    !dropdownValueQualificationType
                  );
                  setDropdownValueInstitutionType(false);
                  setDropdownValueGrading(false);
                  setDropdownValue(false);
                  setError({ message: "" });
                }}
                value={selectedOptionQualificationType?.name}
                // onChange={handleMainDropdownChange}
                // disabled={shouldCreateNewDropdown}
              >
                {selectedOptionQualificationType
                  ? selectedOptionQualificationType?.name?.length > 17
                    ? selectedOptionQualificationType?.name?.slice(0, 15) +
                      "..."
                    : selectedOptionQualificationType?.name
                  : "COURSE TYPES"}

                <FeatherIcon
                  icon={
                    dropdownValueQualificationType
                      ? "chevron-up"
                      : "chevron-down"
                  }
                  size={20}
                />
              </button>
              <div
                className={`dropdown-content dropDownHeight ${
                  dropdownValueQualificationType ? "active" : ""
                }`}>
                {qualifications?.length > 0 &&
                  qualifications?.map((option) => (
                    <button
                      key={option.id}
                      id="SubDropdown"
                      name="types"
                      className={`dropdownbtn controlBtn click`}
                      // className={`dropdownbtn controlBtn click ${
                      //   dropdownValueQualificationType ? "active" : ""
                      // }`}
                      onClick={() => {
                        // handleSelected();
                        // handleMainDropdownChange(option);
                        dispatch(
                          actions.getGrades({
                            instituteId: option?.instituteId,
                            qualificationId: option?.id,
                          })
                        );
                        setSelectedOptionQualificationType({
                          ...option,
                          disabled: true,
                        });
                        setSelectedOptionGrading(null);
                        // setGradingOptions(optionsGrading);
                        // setShouldCreateNewDropdown(true);
                        setDropdownValueQualificationType(
                          !dropdownValueQualificationType
                        );
                        setSelectedDropdownEditable(true);
                        setIsCancel && setIsCancel(false);
                      }}>
                      {/* {option?.name} */}
                      {window.innerWidth <= 1024 && window.innerWidth >= 320
                        ? option?.name
                        : option?.name?.length > 21
                        ? option?.name?.slice(0, 19) + "..."
                        : option?.name}
                      {/* {option?.name?.length > 21
                        ? option?.name?.slice(0, 19) + "..."
                        : option?.name} */}
                    </button>
                  ))}

                <div className="d-inline-flex institutionGrading">
                  <button
                    id="SubDropdown"
                    name="types"
                    className={`dropdownbtn controlBtn GradingStructure click ${
                      dropdownValue ? "active" : ""
                    }`}
                    onClick={() => setNewCategory(true)}>
                    NEW COURSE TYPES
                  </button>

                  {newCategory && (
                    <NewCategory
                      setNewCategory={setNewCategory}
                      onSave={onSaveQualification}
                      error={error}
                      setError={setError}
                    />
                  )}
                </div>
              </div>
            </div>

            {/* {selectedOptionQualificationType ? (
              <div className="InstitutionEdit">
                <p
                  className="EditTypes"
                  onClick={() => {
                    setSelectedOptionQualificationType({
                      ...selectedOptionQualificationType,
                      disabled: false,
                    });
                  }}
                >
                  edit
                </p>
              </div>
            ) : null} */}
          </div>
        )}

        {selectedOptionQualificationType && (
          <div id="controlButtons" className="DropDownControlBtn">
            <div className="dropdown ">
              <button
                type="button"
                // className="dropbtn controlBtn click instituteTypeBtn"
                className={`dropbtn controlBtn click instituteTypeBtn text-truncate ${
                  selectedOptionGrading && selectedOptionGrading.disabled
                    ? ""
                    : ""
                }`}
                onClick={() => {
                  setNewCategory(false);
                  // !selectedOptionGrading
                  //   ? setDropdownValueGrading(!dropdownValueGrading)
                  //   : selectedOptionGrading?.disabled
                  //   ? setDropdownValueGrading(false)
                  //   : setDropdownValueGrading(!dropdownValueGrading);
                  setDropdownValueGrading(!dropdownValueGrading);
                  setDropdownValueInstitutionType(false);
                  setDropdownValueQualificationType(false);
                  setDropdownValue(false);
                  setMoreGradingStructure(false);
                }}
                value={selectedOptionGrading?.label}
                onChange={handleMainDropdownChange}
                // disabled={shouldCreateNewDropdown}
              >
                {selectedOptionGrading
                  ? selectedOptionGrading?.label?.length > 17
                    ? selectedOptionGrading?.label?.slice(0, 15) + "..."
                    : selectedOptionGrading?.label
                  : "GRADING OPTIONS"}

                <FeatherIcon
                  icon={dropdownValueGrading ? "chevron-up" : "chevron-down"}
                  size={20}
                />
              </button>
              <div
                className={`dropdown-content  ${
                  dropdownValueGrading ? "active" : ""
                }`}>
                {gradingStructure?.suggestedGrades?.length > 0 &&
                  gradingStructure?.suggestedGrades?.map((option) => (
                    <button
                      key={option.id}
                      id="SubDropdown"
                      name="types"
                      className={`dropdownbtn controlBtn click ${
                        dropdownValueGrading ? "active" : ""
                      }`}
                      onClick={() => {
                        // handleSelected();
                        // handleMainDropdownChange(option);
                        setSelectedOptionGrading({ ...option, disabled: true });
                        // setShouldCreateNewDropdown(true);
                        setDropdownValueGrading(!dropdownValueGrading);
                        setSelectedDropdownEditable(true);
                        setIsCancel && setIsCancel(false);
                      }}>
                      {/* {option?.label} */}

                      {window.innerWidth <= 1024 && window.innerWidth >= 320
                        ? option?.label
                        : option?.label?.length > 21
                        ? option?.label?.slice(0, 19) + "..."
                        : option?.label}
                    </button>
                  ))}

                {(morGradingStructure ||
                  gradingStructure?.suggestedGrades?.length <= 0) &&
                  gradingStructure?.unSuggestedGrades?.length > 0 &&
                  gradingStructure?.unSuggestedGrades?.map((option) => (
                    <button
                      key={option.id}
                      id="SubDropdown"
                      name="types"
                      // className="dropdownbtn controlBtn click"
                      className={`dropdownbtn controlBtn click ${
                        dropdownValueGrading ? "active" : ""
                      }`}
                      onClick={() => {
                        // handleSelected();
                        // handleMainDropdownChange(option);
                        setSelectedOptionGrading({ ...option, disabled: true });
                        // setShouldCreateNewDropdown(true);
                        setDropdownValueGrading(!dropdownValueGrading);
                        setSelectedDropdownEditable(true);
                        setIsCancel && setIsCancel(false);
                      }}>
                      {/* {option?.label} */}

                      {window.innerWidth <= 1024 && window.innerWidth >= 320
                        ? option?.label
                        : option?.label?.length > 21
                        ? option?.label?.slice(0, 19) + "..."
                        : option?.label}
                    </button>
                  ))}

                <div className="d-inline-flex institutionGrading">
                  <button
                    // style={{ fontSize: "14px" }}
                    id="SubDropdown"
                    name="types"
                    className={`dropdownbtn controlBtn GradingStructure click ${
                      dropdownValue ? "active" : ""
                    }`}
                    onClick={() => {
                      if (selectedOptionGrading) {
                        setSelectedOptionGrading(!selectedOptionGrading);
                        setNewCategory(true);
                      } else {
                        setNewCategory(true);
                      }
                    }}>
                    NEW GRADING OPTIONS
                  </button>
                  {newCategory && (
                    <GradingCategory
                      // label={label}
                      showIcon={showIcon}
                      setNewCategory={setNewCategory}
                      error={error}
                      setError={setError}
                      qualificationId={selectedOptionQualificationType?.id}
                      instituteId={selectedOption.id}
                      // inputDisable={inputDisable}
                      // setInputDisable={setInputDisable}
                    />
                  )}
                </div>

                {gradingStructure?.suggestedGrades?.length > 0 && (
                  <button
                    // style={{ fontSize: "14px" }}
                    id="SubDropdown"
                    name="types"
                    className={`dropdownbtn controlBtn GradingStructure fw-bold click ${
                      dropdownValue ? "active" : ""
                    }`}
                    onClick={() => {
                      setMoreGradingStructure(!morGradingStructure);
                    }}>
                    {!morGradingStructure ? "More" : "Less"} Grading Options
                  </button>
                )}
              </div>
            </div>
            {/* {selectedOptionGrading ? (
              <div className="InstitutionEdit">
                <p
                  className="EditTypes"
                  onClick={() => {
                    setSelectedOptionGrading({
                      ...selectedOptionGrading,
                      disabled: false,
                    });
                  }}
                >
                  edit
                </p>
              </div>
            ) : null} */}
          </div>
        )}

        {selectedOptionGrading && !course ? (
          <>
            {courseInformation.map((item, index) => {
              // const isShow =
              //   isShowUnit &&
              //   isShowUnit.length > 0 &&
              //   isShowUnit.find((isShowUnitItem) => {
              //     return (
              //       isShowUnitItem.id === item.id &&
              //       isShowUnitItem.isShow === true
              //     );
              //   });
              return (
                item?.isShow && (
                  <>
                    <div className="AddCourse" key={item.id}>
                      <div className="CourseInfo">
                        <p className="courseTitle">{item.title}</p>
                        <div
                          className="GoButton"
                          // className={`GoButton ${item.value ? "disable" : ""}`}
                        >
                          <form className="d-flex fromCourseInput">
                            {item?.title === "How Many Modules?" ? (
                              <Dropdown
                                value={selectedNumberOfUnit}
                                onChange={(e) => {
                                  setSelectedNumberOfUnit(e.value);
                                  const course = courseInformation.find(
                                    (a) => a.id === item.id
                                  );
                                  const courseIndex =
                                    courseInformation.findIndex(
                                      (a) => a.id === item.id
                                    );

                                  courseInformation[courseIndex].value =
                                    e.value;
                                  setCourseInformation([...courseInformation]);
                                  setError({ message: "" });
                                  CreateCourseInformation(
                                    {
                                      id: course.id,
                                      title: course.title,
                                      value: course.value,
                                    },
                                    index
                                  );
                                }}
                                options={numberOf_Modules}
                                optionLabel="name"
                                placeholder="Select Number of Module"
                                className="w-full profile-fields-dropdown"
                              />
                            ) : (
                              <input
                                className="courseInput"
                                // className={`courseInput ${
                                //   item?.value ? "disable" : ""
                                // }`}
                                autoFocus
                                type="text"
                                // name="test"
                                value={item?.value}
                                onChange={(e) => {
                                  const courseIndex =
                                    courseInformation.findIndex(
                                      (a) => a.id === item.id
                                    );
                                  if (
                                    courseInformation[courseIndex].title ===
                                    "How Many Modules?"
                                  ) {
                                    e.target.value =
                                      e.target.value < 1 ? 0 : e.target.value;
                                    if (!Number(e.target.value)) {
                                      e.target.value = "";
                                    }
                                  }

                                  courseInformation[courseIndex].value =
                                    e.target.value;
                                  setCourseInformation([...courseInformation]);
                                  setError({ message: "" });
                                }}
                                // disabled={inputDisable}
                              />
                            )}

                            {item?.title !== "How Many Modules?" && (
                              <Button
                                // className="GoControlBtn click "
                                type={"submit"}
                                className={`GoControlBtn click`}
                                label="GO"
                                // disabled={
                                //   courseInformation.findIndex(
                                //     (a) => a.id === item.id
                                //   ) <
                                //     courseInformation.length - 1 &&
                                //   courseInformation.length <= 3
                                //     ? true
                                //     : false
                                // }
                                handleClick={(e) => {
                                  e.preventDefault();
                                  const course = courseInformation.find(
                                    (a) => a.id === item.id
                                  );
                                  // const GetUnitId = add_Unit.find((res) => res);
                                  if (course.value !== "") {
                                    if (index >= 0) {
                                      setNextIndex(nextIndex + 1);
                                    }

                                    CreateCourseInformation(
                                      {
                                        id: course.id,
                                        title: course.title,
                                        value: course.value,
                                      },
                                      index
                                    );
                                    setSelectedNumberOfModule(null);
                                    // setSelectedUnit(item.id);
                                  } else {
                                    setError({
                                      id: course.id,
                                      message: `Please enter ${course.title.toLocaleLowerCase()}`,
                                    });
                                  }
                                }}
                              />
                            )}
                          </form>
                        </div>
                        {error.id === item.id && error.message && (
                          <p
                            id="login_validation"
                            style={{ fontSize: "small" }}
                            className="text-danger mt-1">
                            {error.message}
                          </p>
                        )}
                      </div>
                      <div className="icons">
                        {
                          <>
                            {index === 2 && !showIcon && (
                              <FeatherIcon
                                className={"Chevron_Right"}
                                // icon="chevron-down"
                                icon={`chevron-down`}
                                size={35}
                                strokeWidth={1}
                              />
                            )}
                          </>
                        }
                      </div>

                      <div>
                        {item?.units?.map((data, index) => {
                          // const isShowModule =
                          //   isShowModules &&
                          //   isShowModules.length > 0 &&
                          //   isShowModules.find((isShowModuleItem) => {
                          //     return (
                          //       isShowModuleItem.id === data.id &&
                          //       isShowModuleItem.isShow === true
                          //     );
                          //   });
                          return (
                            data?.isShow && (
                              // selectedUnit === data.unitId &&
                              // item.id === data.unitId && (
                              <>
                                <div key={data.id}>
                                  <p className="ModuleTitle">{data.title}</p>
                                  <div
                                    className="GoButton"
                                    // className={`GoButton ${item.value ? "disable" : ""}`}
                                  >
                                    {index === 0 && showIcon && (
                                      <FeatherIcon
                                        className={"Chevron_Right"}
                                        icon={`chevron-right`}
                                        // icon="chevron-right"
                                        size={35}
                                        strokeWidth={1}
                                      />
                                    )}
                                    <form className="d-flex fromCourseInput">
                                      {data?.title === "How Many Units?" ? (
                                        <>
                                          <Dropdown
                                            value={selectedNumberOfModule}
                                            onChange={(e) => {
                                              setSelectedNumberOfModule(
                                                e.value
                                              );
                                              const Module = item?.units?.find(
                                                (a) => a.id === data.id
                                              );

                                              const ModuleIndex =
                                                item?.units?.findIndex(
                                                  (a) => a.id === data.id
                                                );

                                              CreateModuleInformation(
                                                {
                                                  id: data.id,
                                                  moduleId: data.moduleId,
                                                  title: Module.title,
                                                  value: e.value,
                                                },
                                                item.id,
                                                ModuleIndex + 1
                                              );
                                              setNextIndexModule(
                                                nextIndexModule + 1
                                              );
                                            }}
                                            options={numberOf_Units}
                                            optionLabel="name"
                                            placeholder="Select Number of Unit"
                                            className="w-full profile-fields-dropdown"
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <input
                                            // className={`courseInput ${
                                            //   data?.value ? "disable" : ""
                                            // } ${index === 0 ? "" : "ModuleInput"}`}
                                            className={`courseInput  ${
                                              index === 0 ? "" : "ModuleInput"
                                            }`}
                                            type="text"
                                            // name="test"
                                            autoFocus
                                            value={data?.value}
                                            onChange={(e) => {
                                              const ModuleIndex =
                                                item?.units?.findIndex(
                                                  (a) => a.id === data.id
                                                );

                                              if (
                                                item.units[ModuleIndex]
                                                  .title === "How Many Units?"
                                              ) {
                                                e.target.value =
                                                  e.target.value < 1
                                                    ? 0
                                                    : e.target.value;
                                                if (!Number(e.target.value)) {
                                                  e.target.value = "";
                                                }
                                              }

                                              item.units[ModuleIndex].value =
                                                e.target.value;
                                              setAdd_Unit([...item.units]);
                                              setError({ message: "" });
                                            }}
                                            // disabled={inputDisable}
                                          />
                                        </>
                                      )}

                                      {data?.title !== "How Many Units?" && (
                                        <Button
                                          className="GoControlBtn click"
                                          type={"submit"}
                                          label="GO"
                                          handleClick={(event) => {
                                            event.preventDefault();
                                            const Module = item?.units?.find(
                                              (a) => a.id === data.id
                                            );

                                            const ModuleIndex =
                                              item?.units?.findIndex(
                                                (a) => a.id === data.id
                                              );

                                            if (Module.value !== "") {
                                              CreateModuleInformation(
                                                {
                                                  id: data.id,
                                                  moduleId: data.moduleId,
                                                  title: Module.title,
                                                  value: Module.value,
                                                },
                                                item.id,
                                                ModuleIndex + 1
                                              );
                                              // setSelectedModule(data.id);
                                            } else {
                                              setError({
                                                id: Module.id,
                                                message: `Please enter ${Module.title.toLowerCase()}`,
                                              });
                                            }

                                            // setNextIndex(nextIndex - 1);
                                            setNextIndexModule(
                                              nextIndexModule + 1
                                            );
                                          }}
                                        />
                                      )}
                                    </form>
                                  </div>
                                  {error.id === data.id && error.message && (
                                    <p
                                      id="login_validation"
                                      style={{ fontSize: "small" }}
                                      className="text-danger mt-1 ml-6">
                                      {error.message}
                                    </p>
                                  )}
                                </div>
                              </>
                            )
                          );
                        })}
                      </div>
                    </div>
                  </>
                )
              );
            })}
          </>
        ) : (
          ""
        )}

        {showCommitButton && !disableCommitButton && (
          <Button
            type="button"
            className="controlBtn courseCommitBtn"
            label="COMMIT"
            handleClick={() => {
              createCourse();
            }}
            disabled={disableCommitButton}
          />
        )}
      </div>
    </>
  );
};

export default InstitutionType;
