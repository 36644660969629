import React, { useContext, useEffect } from "react";
import Card from "components/Card";
import Navbar from "components/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "redux/Club/action";
import { actions as tutorsAction } from "redux/Tutor/action";
import { actions as playersAction } from "../../redux/Player/action";
import FeatherIcon from "feather-icons-react";
import { useNavigate, useLocation } from "react-router-dom";
import { SweetAlert } from "utils/SweetAlert";
import Swal from "sweetalert2";
import { UserContext } from "components/contexts/User.context";

function Archived() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let { archivedClubs } = useSelector((state) => state.club);
  const { archivedTutors } = useSelector((state) => state.tutor);
  const { archivedPlayers, playerStatus: playerStatuses } = useSelector(
    (state) => state.players
  );
  const { brandTheme } = useSelector((state) => state.brand);
  const { user: userType } = useContext(UserContext);

  const optionList = [
    {
      title: "Unarchive",
      icon: "archive",
      onClick:
        location.pathname === "/clubs/archived"
          ? (id) => {
              dispatch(actions.updateClub({ isEnable: true, id: id }, false));
            }
          : location.pathname === "/tutors/archived"
          ? (email) => {
              const formData = new FormData();
              formData.append("email", email);
              formData.append("isDelete", false);
              dispatch(tutorsAction.updateTutor(formData, false));
            }
          : (email) => {
              dispatch(
                playersAction.updatePlayer(
                  { isArchived: false, email: email },
                  userType
                )
              );
            },
    },
    location.pathname !== "/clubs/archived" && {
      title: "Remove Permanent",
      icon: "trash-2",
      onClick:
        location.pathname === "/clubs/archived"
          ? (id) => {
              dispatch(actions.updateClub({ isEnable: true, id: id }, false));
            }
          : location.pathname === "/tutors/archived"
          ? async (email, id) => {
              if (email && !id) {
                const formData = new FormData();
                formData.append("email", email);
                formData.append("isDelete", false);
                dispatch(tutorsAction.updateTutor(formData, false));
              }
              if (id) {
                const result = await SweetAlert({
                  title: "Are you sure? ",
                  text: "You won't be able to revert this!",
                  icon: "warning",
                  showCancelButton: true,
                  cancelButtonText: "Cancel",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, delete it!",
                  confirmButtonColor: brandTheme
                    ? brandTheme?.themeColors?.primary
                    : "#006b00",
                });
                if (result.isConfirmed) {
                  dispatch(tutorsAction.deleteTutor(id));
                  Swal.fire({
                    title: "Deleted!",
                    text: "Tutor has been deleted.",
                    icon: "success",
                    confirmButtonColor: brandTheme
                      ? brandTheme?.themeColors?.primary
                      : "#006b00",
                  });
                }
              }
            }
          : location.pathname === "/players/archived"
          ? async (email, id) => {
              // if (email && !id) {
              //   const formData = new FormData();
              //   formData.append("email", email);
              //   formData.append("isDelete", false);
              //   dispatch(tutorsAction.updateTutor(formData, false));
              // }
              if (id) {
                const result = await SweetAlert({
                  title: "Are you sure? ",
                  text: "You won't be able to revert this!",
                  icon: "warning",
                  showCancelButton: true,
                  cancelButtonText: "Cancel",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, delete it!",
                  confirmButtonColor: brandTheme
                    ? brandTheme?.themeColors?.primary
                    : "#006b00",
                });
                if (result.isConfirmed) {
                  dispatch(playersAction.deletePlayer(id, userType));
                  Swal.fire({
                    title: "Deleted!",
                    text: `${userType} has been deleted.`,
                    icon: "success",
                    confirmButtonColor: brandTheme
                      ? brandTheme?.themeColors?.primary
                      : "#006b00",
                  });
                }
              }
            }
          : "",
    },
  ];

  useEffect(() => {
    // dispatch(
    //   playersAction.getAllPlayers({
    //     // clubId: user?.club?.id,
    //     keyword: "",
    //     skip: 0,
    //     body: { isArchived: true },
    //   })
    // );
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <div className="container-fluid mt-3">
        <div
          className="d-flex align-items-center text-secondary fw-bold"
          onClick={() =>
            location.pathname === "/clubs/archived"
              ? navigate("/")
              : location.pathname === "/players/archived"
              ? navigate("/players")
              : navigate("/club-admin")
          }>
          <FeatherIcon icon="arrow-left" size={16} className="cursor-pointer" />
          <span className="ps-1 cursor-pointer">Back</span>
        </div>
      </div>

      {location.pathname === "/clubs/archived" && (
        <div className="container-fluid py-5">
          {archivedClubs && archivedClubs?.length > 0 ? (
            <div
              className="d-flex h-100 flex-wrap justify-content-center"
              id="card">
              {archivedClubs &&
                archivedClubs?.length > 0 &&
                archivedClubs?.map((club) => {
                  return (
                    <Card
                      image={club.image}
                      name={club.name}
                      menuIcon={true}
                      optionList={optionList}
                      cardType="clubCard"
                      key={club.id}
                      club={club}
                    />
                  );
                })}
            </div>
          ) : (
            <p className="text-center">No data found</p>
          )}
        </div>
      )}

      {location.pathname === "/tutors/archived" && (
        <div className="container-fluid py-5">
          {archivedTutors && archivedTutors?.length > 0 ? (
            <div
              className="d-flex h-100 flex-wrap justify-content-center"
              id="card">
              {archivedTutors &&
                archivedTutors?.length > 0 &&
                archivedTutors?.map((tutor) => {
                  return (
                    <Card
                      image={tutor.image}
                      name={tutor.name}
                      designation={tutor.designation}
                      menuIcon={tutor}
                      optionList={optionList}
                      cardType="tutorCard"
                      key={tutor.id}
                      tutor={tutor}
                    />
                  );
                })}
            </div>
          ) : (
            <p className="text-center">No data found</p>
          )}
        </div>
      )}

      {location.pathname === "/players/archived" && (
        <div className="container-fluid py-5">
          {archivedPlayers && archivedPlayers?.length > 0 ? (
            <div
              className="d-flex h-100 flex-wrap justify-content-center"
              id="card">
              {archivedPlayers &&
                archivedPlayers?.length > 0 &&
                archivedPlayers?.map((player, i) => {
                  let dataStatus = player?.status?.filter((status) => {
                    return status?.current === true;
                  });
                  dataStatus =
                    playerStatuses &&
                    playerStatuses?.length > 0 &&
                    playerStatuses?.find((data) => {
                      return dataStatus[0]?.id === data?.id;
                    });
                  return (
                    <Card
                      image={player?.profileImage && player?.profileImage}
                      name={player?.name && player?.name}
                      clubName={player?.club?.name && player?.club?.name}
                      clubImage={player?.club?.image && player?.club?.image}
                      position={player?.position && player?.position}
                      playerStatus={player?.status && dataStatus}
                      key={i + 1}
                      cardType="playerCard"
                      optionList={optionList}
                      menuIcon={true}
                      player={player}
                    />
                  );
                })}
            </div>
          ) : (
            <p className="text-center">No data found</p>
          )}
        </div>
      )}
    </>
  );
}

export default Archived;
