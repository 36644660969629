import { all, fork, put, takeLatest } from "redux-saga/effects";
import { getQuery, postQuery } from "utils/api";
import { actions } from "./action";
import { Toast } from "utils/toast";
import { actions as loaderAction } from "redux/Loader/action";

export function* registerBrandAdmin() {
  yield takeLatest(
    actions.REGISTER_BRAND_ADMIN_REQUEST,
    function* ({ data, navigate }) {
      try {
        const response = yield postQuery(`brand/admin/register`, data);

        if (!response.data.error) {
          yield put({
            type: actions.REGISTER_BRAND_ADMIN_SUCCESS,
            payload: response.data.result,
          });
          Toast("success", "Admin registered successfully!");
          yield put({
            type: loaderAction.CLOSE_LOADER,
          });
        }
      } catch (e) {
        console.log(e);
        yield put({
          type: actions.CATCH_ERROR,
          payload: e.response?.data?.message || "An error occurred",
        });
        Toast("error", e.response?.data?.message);
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
      }
    }
  );
}

export function* createBrandTheme() {
  yield takeLatest(actions.CREATE_BRAND_THEME_REQUEST, function* ({ data }) {
    try {
      const response = yield postQuery(`brand/theme/create`, data);

      if (!response.data.error) {
        yield put({
          type: actions.CREATE_BRAND_THEME_SUCCESS,
          payload: response.data.result,
        });
        Toast("success", "Theme updated successfully!");
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
      } else {
        Toast("error", response.data.message);
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
      }
    } catch (e) {
      console.log(e);
      Toast("error", e.response?.data?.message);
      yield put({
        type: loaderAction.CLOSE_LOADER,
      });
    }
  });
}

export function* getBrandTheme() {
  yield takeLatest(actions.GET_BRAND_THEME_REQUEST, function* ({ brand }) {
    // const { brandId, tab } = brand;

    try {
      let queryParams = [];
      if (brand.brandId) {
        queryParams.push(`brandId=${brand.brandId}`);
      }
      if (brand.organizationId) {
        queryParams.push(`organizationId=${brand.organizationId}`);
      }
      const queryString = queryParams.join("&");
      const response = yield getQuery(
        `brand/theme${queryString ? `?${queryString}` : ""}`
      );

      if (!response.data.error) {
        yield put({
          type: actions.GET_BRAND_THEME_SUCCESS,
          payload: response.data.result,
          // payload: { brand: response.data.result, tab },
        });
      } else {
        Toast("error", response.data.message);
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
      }
    } catch (e) {
      console.log(e);
      yield put({
        type: actions.CATCH_ERROR,
        payload: e.response?.data?.message || "An error occurred",
      });
      yield put({
        type: actions.GET_BRAND_THEME_SUCCESS,
        payload: null,
      });
      Toast("error", e.response?.data?.message);
      yield put({
        type: loaderAction.CLOSE_LOADER,
      });
    }
  });
}

export function* getBrand() {
  yield takeLatest(actions.GET_BRAND_REQUEST, function* () {
    try {
      const response = yield getQuery(`brand`);
      if (!response.data.error) {
        yield put({
          type: actions.GET_BRAND_SUCCESS,
          payload: response.data.result,
        });
      }
    } catch (e) {
      console.log(e);
      yield put({
        type: actions.CATCH_ERROR,
        payload: e.response?.data?.message || "An error occurred",
      });
    }
  });
}

export default function* brandSaga() {
  yield all([
    fork(registerBrandAdmin),
    fork(createBrandTheme),
    fork(getBrandTheme),
    fork(getBrand),
  ]);
}
