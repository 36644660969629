import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "redux/Course/action";
import { actions as loaderActions } from "redux/Loader/action";
import jwt_decode from "jwt-decode";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./UnitCard.css";
import { useState } from "react";
import { useEffect } from "react";
import CardDropdown from "components/CardDropdown";
import defaultCourse from "assets/images/defaultCourse.png";

const UnitCard = ({
  setIsShow,
  course,
  setDuplicateAndEdit,
  setDuplicateShow,
  dropdownValueUnitCard,
  setDropdownValueUnitCard,
  playerCentre,
  setIsShowInputBox,
  CoursesTitle,
  isCreateCourse,
  setTitleColor,
}) => {
  const [addOrganizationBtnDisable, setAddOrganizationBtnDisable] =
    useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  let token = localStorage.getItem("TOKEN");
  let decoded;
  if (token) {
    decoded = jwt_decode(token);
  }

  const { user } = useSelector((state) => state.user);
  const { myCourses } = useSelector((state) => state.course);

  const { club } = useSelector((state) => state.players);

  useEffect(() => {
    const existingCourse = myCourses?.find(
      (data) => data.superCourseId === course?.id
    );
    if (existingCourse) {
      setAddOrganizationBtnDisable(true);
    }
    // eslint-disable-next-line
  }, [myCourses]);

  let optionList = [
    {
      id: 1,
      title: "Edit Existing ",
      icon: "edit-3",
      attribute: {
        "data-bs-toggle": "modal",
        "data-bs-target": "#myModalEditProfile",
      },
      onClick: () => {
        if (playerCentre !== "viewAllCourses") {
          dispatch(loaderActions.startLoader());
          dispatch(actions.getSingleCourse({ id: course.id }));
          setDuplicateAndEdit(false);
          setIsShow(false);
          setDropdownValueUnitCard({});
          setIsShowInputBox(true);
          setDuplicateShow(true);
        } else {
          navigate("/course/update/" + course.id, {
            state: { ...location.state },
          });
        }
      },
    },
    {
      id: 2,
      title: "Duplicate & Edit",
      icon: "copy",
      attribute: {
        "data-bs-toggle": "modal",
        "data-bs-target": "#myModalEditProfile",
      },
      onClick: () => {
        if (playerCentre !== "viewAllCourses") {
          dispatch(loaderActions.startLoader());
          dispatch(actions.getSingleCourse({ id: course.id }));
          setDuplicateAndEdit(true);
          setIsShow(false);
          setDuplicateShow(false);
          setDropdownValueUnitCard({});
        } else {
          navigate("/course/update/" + course.id, {
            state: {
              ...location.state,
              duplicateAndEdit: true,
            },
          });
        }
      },
    },
    {
      id: 3,
      title: "Add to my organisation",
      icon: "plus-circle",
      attribute: {
        "data-bs-toggle": "modal",
        "data-bs-target": "#myModalEditProfile",
      },
      onClick: () => {
        if (addOrganizationBtnDisable === false) {
          dispatch(
            actions.addToMyCourse({
              ...course,
              createdBySuperAdmin: false,
              clubId: params?.id ? params?.id : user?.club?.id,
              userId: user?.id,
              superCourseId: course?.id,
            })
          );
          // setIsShow(false);
          setDropdownValueUnitCard({});
          setAddOrganizationBtnDisable(true);
        }
      },
    },
  ];

  // when playCenter is a courseCenter then display add to my organization option.
  optionList =
    playerCentre !== "myCourseCenter" &&
    playerCentre !== "superCourseCenter" &&
    playerCentre !== "viewAllCourses"
      ? optionList
      : optionList?.slice(0, 2);

  // when playCenter is a (superCourseCenter,myCourseCenter,viewAllCourses) then display Edit Existing option.
  //  And when club admin login then display option
  optionList =
    (decoded?.role === "admin" &&
      (playerCentre === "superCourseCenter" ||
        playerCentre === "viewAllCourses")) ||
    playerCentre === "myCourseCenter"
      ? optionList
      : optionList?.slice(1, 3);

  return (
    <>
      <div className="cards-list">
        {/* <div className="CourseCardInfo"> */}
        <div
          className={`${
            playerCentre === "courseCenter"
              ? "course-card-center"
              : `CourseCardInfo`
          }`}
        >
          <div
            className={`d-flex ${
              playerCentre !== "myCourseCenter" &&
              playerCentre !== "superCourseCenter" &&
              playerCentre !== "viewAllCourses"
                ? "justify-content-between"
                : "justify-content-end"
            }  my-2 poppins`}
          >
            {/* Add button of Add to my organization */}
            {playerCentre !== "myCourseCenter" &&
              playerCentre !== "superCourseCenter" &&
              playerCentre !== "viewAllCourses" && (
                <button
                  className={`${
                    addOrganizationBtnDisable === false
                      ? "Btn"
                      : "alreadyAddedBtn"
                  }`}
                  disabled={addOrganizationBtnDisable === true ? true : false}
                  onClick={() => {
                    dispatch(
                      actions.addToMyCourse({
                        ...course,
                        createdBySuperAdmin: false,
                        clubId: params?.id ? params?.id : user?.club?.id,
                        userId: user?.id,
                        superCourseId: course?.id,
                      })
                    );
                    setDropdownValueUnitCard({});
                    setAddOrganizationBtnDisable(true);
                  }}
                >
                  <div className="sign">
                    <FeatherIcon
                      className={`${
                        addOrganizationBtnDisable === true ? "check-circle" : ""
                      }`}
                      icon={`${
                        addOrganizationBtnDisable === false
                          ? "plus-circle"
                          : "check-circle"
                      }`}
                      size={34}
                      strokeWidth={2}
                    />
                  </div>

                  <div className="text">
                    {addOrganizationBtnDisable === false
                      ? "Add to my organisation"
                      : "Already Assigned!"}
                  </div>
                </button>
              )}

            <div>
              <CardDropdown optionList={optionList} iconColor={"white"} />
            </div>
          </div>
          <div
            // className="mb-3"
            // className={playerCentre === "viewAllCourses" ? "mb-0" : "mb-3"}
            className={playerCentre === "viewAllCourses"}
            onClick={() => {
              if (
                (decoded?.role === "admin" &&
                  playerCentre === "superCourseCenter") ||
                playerCentre === "myCourseCenter"
              ) {
                dispatch(loaderActions.startLoader());
                dispatch(actions.getSingleCourse({ id: course.id }));
                setDuplicateAndEdit(false);
                setIsShow(false);
                setDropdownValueUnitCard({});
                setIsShowInputBox(true);
                setTitleColor && setTitleColor(true);
              } else {
                if (playerCentre === "viewAllCourses") {
                  navigate("/course/update/" + course.id, {
                    state: { ...location.state },
                  });
                }
              }
            }}
          >
            <div className="d-flex justify-content-center mb-3 imgBg">
              <img
                className="rounded-circle Card_img"
                src={course?.icon ? course?.icon : defaultCourse}
                alt=""
              />
            </div>
            <div
              className="my-2 d-flex justify-content-center "
              style={{
                maxHeight:
                  playerCentre !== "myCourseCenter" ? "76.38px" : "48px",
                minHeight:
                  playerCentre !== "myCourseCenter" ? "76.38px" : "48px",
              }}
            >
              <p
                className="Title_name text-center poppins mb-2 truncate-text "
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={
                  (course?.title ? course?.title : CoursesTitle) +
                  // (decoded?.role === "admin" &&
                  //   Boolean(course?.isPublic) === true &&
                  //   " (public) ")

                  (decoded?.role === "admin" &&
                    course?.isPublic === "true" &&
                    " (public) ")
                }
              >
                {course?.title ? course?.title : CoursesTitle}
                {/* {decoded?.role === "admin" &&
                  Boolean(course?.isPublic) === true && <span> (public)</span>} */}
                {decoded?.role === "admin" && course?.isPublic === "true" && (
                  <span> (public)</span>
                )}
              </p>

              {playerCentre === "courseCenter" &&
                club?.club?.map(
                  (clubName) =>
                    clubName?.id === course?.clubId && (
                      <div className="d-flex justify-content-center poppins mt-2 unit-card-badge">
                        <span className="badge bg-light text-dark">
                          {clubName?.name}
                        </span>
                      </div>
                    )
                )}

              {course?.instituteTypeName && course?.qualificationName ? (
                <>
                  {isCreateCourse === false && (
                    <div className="multi-label">
                      <div className="d-flex justify-content-center poppins mt-1">
                        <span className="badge organizationLabel help-tip">
                          {course?.instituteTypeName?.length > 11
                            ? course?.instituteTypeName?.slice(0, 11) + "..."
                            : course?.instituteTypeName}
                          <span>{course?.instituteTypeName}</span>
                        </span>
                        <span className="badge qualificationLabel help-tip ml-2">
                          {course?.qualificationName?.length > 11
                            ? course?.qualificationName?.slice(0, 11) + "..."
                            : course?.qualificationName}
                          <span>{course?.qualificationName}</span>
                        </span>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnitCard;
